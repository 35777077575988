<template>
  <div class="popup certification">
    <div class="paper type_fix">
      <div class="container">
        <div class="top">
          <h1>인증서 선택</h1>
          <button class="btn_close" @click="fnClose">닫기</button>
        </div>
        <div class="content">
          <h2 class="tit_ico">인증서를 선택해 주세요.</h2>
          <div class="area_certifi" v-if="$store.state.ui.view !== 'mobile'">
            <table class="tbl_certifi">
              <colgroup>
                <col width="15%">
                <col width="10%">
                <col width="45%">
                <col width="15%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>DISK</th>
                  <th>사용자</th>
                  <th>만료기간</th>
                  <th>발급자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,idx) in mappedCertList" :key="idx" :class="[item.focusClass]" @click="()=>fnSelectCert(item)">
                  <td>{{item.Type}}</td>
                  <td>{{item.Drive}}</td>
                  <td>{{item.User}}</td>
                  <td>{{item.ExpiryDate }}</td>
                  <td>{{item.Issuer }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 모바일 -->
          <div class="list_finance" v-else>
            <template v-if="mappedCertList !== null">
              <article class="item" v-for="(item,idx) in mappedCertList" :key="idx" :class="[item.onClass]" @click="()=>fnSelectCert(item)">
                <dl>
                  <dt>구분</dt>
                  <dd>{{item.Type}}</dd>
                </dl>
                <dl>
                  <dt>DISK</dt>
                  <dd>{{ item.Drive }}</dd>
                </dl>
                <dl>
                  <dt>사용자</dt>
                  <dd class="ellipsis">{{item.User}}</dd>
                </dl>
                <dl>
                  <dt>만료기간</dt>
                  <dd>{{item.ExpiryDate }}</dd>
                </dl>
                <dl>
                  <dt>발급자</dt>
                  <dd>{{item.Issuer }}</dd>
                </dl>
              </article>
            </template>
            <template v-else>
              <article class="item no_data">
                <p>선택가능한<br/>인증서가 없습니다.</p>
              </article>
            </template>
          </div>
        </div>
        <div class="bottom">
          <div class="inner">
            <h2 class="tit_ico">인증서 암호를 입력해 주세요.</h2>
            <Item :data="{ id: 1, type: 'password', placeholder: '인증서 암호는 대소문자를 구분합니다.', name: 'certification', }" :value.sync="password"/>
            <div class="btns coln">
              <button type="button" class="btn half gray" @click="fnClose">취소</button>
              <button type="button" class="btn half" @click="fnConfirm">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from "~/components/common/ItemForm.vue";

const certListUrl = "https://127.0.0.1:16566/?op=certList";
const certValidationUrl = 'https://127.0.0.1:16566/?op=execute';

const fetchInit = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
}
export default {
  components:{
    Item
  },
  data(){
    return {
      bizNo: null,
      selectedCert:null,
      certList:null,
      password:null,
      bizCheckOk:false,
      isMobile:false,
    }
  },
  computed:{
    mappedCertList(){
      const certList = this.certList;
      if ( certList ){
        return certList.map(cert => {
          const selectedRdn = ( this.selectedCert && this.selectedCert.certName == cert.certName && this.selectedCert.ExpiryDate == cert.ExpiryDate) ? true : false;
          return {
            ...cert,
            focusClass: selectedRdn ? 'focus' : '',
            onClass: selectedRdn ? 'on' : '',
          }
        })
      }
      return null;
    },
  },

  mounted(){
    this.bizNo = sessionStorage.getItem('bizNo')
    // 인증서 목록 불러오기
    // CooconiSASNX.getCertList(certList => {
    //   this.certList = certList;
    // });
    this.fnFetchCertList();
  },
  methods: {
    fnFetchCertList(){
      fetch(certListUrl, fetchInit)
          .then( (res) => res.json())
          .then(json => {
            const {errYn, errMsg, list} = json;
            // 인증서를 가져오지 못한 경우
            if( errYn == 'Y'){
              this.$fnAlert(errMsg);
            }
            else if ( !list || list.length==0){
              this.$fnAlert('표시할 수 있는 인증서가 없습니다.')
            }
            // 인증서를 가져온경우
            else{
              this.certList = list.map(item => {
                return {
                  Type: this._fnDistingCert(item.oid),
                  Drive: item.drive,
                  User: item.certName,
                  ExpiryDate: item.toDt,
                  Issuer: item.pub,
                  ...item,
                }
              });
            }
          })
          .catch(err => console.error(err))
    },
    fnClose(){
      this.$store.dispatch("ui/setPopupCert", false);
    },
    fnSelectCert(cert){
      this.selectedCert = cert;
    },
    fnConfirm(){
      if( !this.selectedCert ){
        alert('인증서를 선택해주세요')
      } else if ( !this.password) {
        alert('인증서 암호를 입력해주세요')
      } else {
        this.fnCertCheck();
      }
    },
    fnCertCheck(){
      const s_path = this.selectedCert.path;
      const payload = new Object();
      payload.orgCd = "common";
      payload.svcCd = "getCertInfo";
      payload.appCd = "InfotechApiDemo";
      payload.signPw = this.password;
      if (this.$checkMacOS() == true)
      {
        payload.signCert = s_path + "/signCert.der";
        payload.signPri = s_path + "/signPri.key";
      }else{
        payload.signCert = s_path + "\\signCert.der";
        payload.signPri = s_path + "\\signPri.key";
      }
      const _init = {
        ...fetchInit,
        body: JSON.stringify(payload),
      }
      fetch(certValidationUrl, _init)
        .then(res => res.json())
        .then( json => {
          const {errYn, errMsg, DER2PEM, KEY2PEM} = json;
          if( errYn == 'Y'){
            alert(errMsg);
          }
          else if ( errYn == 'N' ){
            this.$store.commit('certStore/UPDATE_CERT', {
              ...this.selectedCert,
              DER2PEM,
              KEY2PEM,
              password: this.password
            });
            this.fnClose();
          }
        })
        .catch(err => console.error(err));
    },

    _fnDistingCert(oid) {
      let divNm = "기타";
      const perArr = ['1.2.410.200005.1.1.1'
        , '1.2.410.200004.5.1.1.5'
        , '1.2.410.200004.5.2.1.2'
        , '1.2.410.200004.5.4.1.1'
        , '1.2.410.200012.1.1.1'
        , '1.2.410.200005.1.1.4'
        , '1.2.410.200012.1.1.101'
        , '1.2.410.200004.5.2.1.7.1'
        , '1.2.410.200004.5.4.1.101'
        , '1.2.410.200004.5.1.1.9.2'
        , '1.2.410.200004.5.2.1.7.3'
        , '1.2.410.200004.5.4.1.103'
        , '1.2.410.200012.1.1.105'
        , '1.2.410.200012.1.1.103'
        , '1.2.410.200004.5.1.1.9'
        , '1.2.410.200004.5.2.1.7.1'
        , '1.2.410.200004.5.4.1.101'
        , '1.2.410.200012.1.1.101'
        , '1.2.410.200004.5.1.1.9'
        , '1.2.410.200004.5.2.1.7.2'
        , '1.2.410.200004.5.4.1.102'
        , '1.2.410.200012.1.1.103'
        , '1.2.410.200004.5.4.1.104'
        , '1.2.410.200004.5.5.1.3.1'
        , '1.2.410.200004.5.5.1.4.1'
        , '1.2.410.200004.5.5.1.4.2'];
      const bizArr = ['1.2.410.200005.1.1.5'
        , '1.2.410.200004.5.1.1.7'
        , '1.2.410.200004.5.2.1.1'
        , '1.2.410.200004.5.4.1.2'
        , '1.2.410.200012.1.1.3'
        , '1.2.410.200005.1.1.2'
        , '1.2.410.200005.1.1.6.1'
        , '1.2.410.200004.5.1.1.12.908'
        , '1.2.410.200004.5.2.1.5001'
        , '1.2.410.200004.5.2.1.6.257'
        , '1.2.410.200005.1.1.6.8'
        , '1.2.410.200005.1.1.6.3'
        , '1.2.410.200005.1.1.6.5'
        , '1.2.410.200005.1.1.6.4'
        , '1.2.410.200005.1.1.7.1'
        , '1.2.410.200004.5.5.1.2'];
      if (perArr.includes(oid)) {
        divNm = "개인";
      } else if (bizArr.includes(oid)) {
        divNm = "법인";
      }
      return divNm;
    }
  },
  watch:{
    bizCheckOk(nV){
      if(nV === true){
        this.fnLogin();
      }
    }
  }
}

</script>
