<template>
  <div class="popup p_loading">
    <div class="bx_loading">
      <p><b>로딩 중...</b></p>
    </div>
  </div>
</template>
  
<script>

export default {
  data(){
    return {
    }
  },
  components:{
  },
  methods: {
  },
}

</script>
  