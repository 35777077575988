export default {
    computed: {
        mixDisplayHead(){
            let partnerService = this.$getPartnerInfo();
            let result = false
            if(partnerService){
                result = partnerService.path === '/' ? false : true;
            }

            if(this.$store.state.ui.view=='mobile'){
                result = true;
            }
            
            return result && !this._mixHasExclusivePath()
        },
        mixWrapPaperStyle(){
            if(this.$store.state.ui.view=='mobile'){
                return this.mixDisplayHead ?
                    {alignItems:'flex-start'}
                    : null;
            } else{
                return this.mixDisplayHead ?
                    {alignItems:'flex-start', paddingTop:'100px'}
                    : null;
            }
        }
    },
    methods: {
        _mixHasExclusivePath(){
            const path = this.$route.path
            const headExcludedPath = ['socialLogin', 'auth','WebToS2', 'WebToS3'];
            return headExcludedPath.find( keyword => path.indexOf(keyword)>=0)
        }
    },
}
