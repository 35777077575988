import * as basePaths from '../../constants/apiPathPrefix';
const path = 'customers'
function fetchCustomerCount(axios){
    return axios.get(`${basePaths.allOkMng}/${path}/count`)
        .then( res => res.data )
        .catch( err => {success:false})
}

function postAuth(axios, payload) {
    return axios.post(`${basePaths.allOkMng}/customers/auth`, payload)
        .then( res => res.data )
        .catch( err => { success:false } );
}

function postUpdatePassword(axios, payload) {
    return axios.post(`${basePaths.allOkMng}/customers/pwdChg`, payload)
        .then( res => res.data )
        .catch( err => err );
}

function postLogout(axios) {
    return axios.post(`${basePaths.allOkMng}/customers/logout`)
        .then( res => res )
        .catch( err => err );
}

export default {
    fetchCustomerCount,
    postAuth,
    postLogout,
    postUpdatePassword
}
