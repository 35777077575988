<template>
  <div class="combobox">
    <div class="combo_tit" @click="fnOpen" v-html="tit"></div>
    <div class="combo_cont">
      <div class="c_inner">
        <button class="btn_close" @click="fnClose">닫기</button>
        <div class="bx_combo">
          <div class="c_item"
            v-for="(item, i) in options"
            :key="i"
            :data-id="i"
            :class="{ select: item.select === true }"
            @click="e => fnSelectOption(e, item, num)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['combo', 'num', 'loading', 'value'],
  data() {
    return {
      defaultTit: this.combo.tit,
      tit: '',
      options: JSON.parse(JSON.stringify(this.combo.list)),
    };
  },
  watch: {
    'combo.list'(newList) {
      if (!this.options[0]) {
        this.listFound(newList);
      } else {
        let updateOptions = this.options[0].value !== newList[0]?.value;
        // console.log('updateOptions', updateOptions);w
        if (updateOptions) this.options = JSON.parse(JSON.stringify(newList));
        else{
          this.listFound(newList);
        }
      }
    },
  },
  mounted() {
    if(this.options){
      const foundItem = this.options.find(item => item.value === this.value)
      let selectOn = false;
      if(foundItem){
        this.tit = foundItem.name;
        this.selectOn = true;
      }
      if(selectOn) this.tit = this.defaultTit;
    }
    // //퍼블 검수용 임시 작업만 했습니다. 개발시 지워주세요.
    // document.addEventListener("click", (e) => {
    //   const comboList = document.getElementsByClassName("combobox");
    //   if(comboList.length > 0){
    //     const temp = e.target.className.split(" ");
    //     if (temp[0] == "combo_cont") {
    //       Array.prototype.forEach.call(comboList, item => item.classList.remove("on"));
    //     }
    //   }
    // });
  },
  methods: {
    listFound(newList){
      this.options = JSON.parse(JSON.stringify(newList));
      const foundItem = this.options.find(item => item.value === this.value)
      let selectOn = false;
      if(foundItem){
        this.tit = foundItem.name;
        this.selectOn = true;
      }
      if(selectOn) this.tit = this.defaultTit;
    },
    fnOpen(e) {
      if (!this.loading) {
        const target = e.target.parentNode;
        const onState = target.classList.contains('on') ? true : false;
        if (!onState) target.classList.add('on');
        else target.classList.remove('on');
      }
    },
    fnClose(e) {
      const target = e.target.parentNode.parentNode.parentNode;
      const onState = target.classList.contains('on') ? true : false;
      target.classList.remove('on');
    },
    fnSelectOption(e, item, num) {
      const payload = {
        ...item,
        num: num
      }
      const target = e.target.parentNode.parentNode.parentNode.parentNode;
      this.tit = e.target.innerText;
      target.classList.remove('on');
      const id = e.target.getAttribute('data-id');
      this.options.map((item, index) => {
        item.select = false;
        if (index == id) {
          item.select = true;
          this.tit = item.name;
          this.on = false;
        }
      });
      this.$emit('change', payload)
    },
  },
};
</script>
