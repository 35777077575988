<template>
  <div class="app sub">
    <nuxt />
  </div>
</template>

<script>
import Setting from '~/mixins/Setting';
export default {
  name: 'none2',
  mixins: [Setting],
};
</script>

<style></style>
