import consultingService from "../service/allOkLoan/consultingService";

export const state = () => ({
    companyInfo:{},
    researchForm:{},
    lookupResult:{
        customerId:null,
        lookupId:null,
    },
    query:{
        bizNo: null,
        pageNo: 1,
        pageSize: 10,
        searchCatgNo: 'TM0000000005',
        searchGrade: 'ALL'
    },

    fundParamData:{
        loanPrdcType:{ 보증형:'LPT01', 직접대출:'LPT02'},
        sortColumn: {
            LPT01: { 한도:'APLC_LMT', 보증료율:'GRNT_FEE_RATE',},
            LPT02: { 한도:'APLC_LMT', 금리:'LOAN_INTR_RATE'}
        }
    },
    fundParam:{
        loanPrdcType:null,
        LPT01: { sortColumn:'GRNT_FEE_RATE',sortOrdr:'ASC',},
        LPT02: { sortColumn:'LOAN_INTR_RATE',sortOrdr:'ASC',}
    },
    fundResult:{ LPT01:[], LPT02:[],},

    financeParamData:{
        loanFincPrdcType:{ 신용대출:'LFPT1', 담보대출:'LFPT2' },
        sortColumn: { 한도:'APLC_LMT', 금리:'LOAN_INTR_RATE'}
    },
    financeParam:{sortColumn:'APLC_LMT',loanFincPrdcType:'LFPT1',sortOrdr:'ASC'},
    financeResult:[],

    analyzeParamData:{
        rank:[
            {name:'전체', value:'ALL'},
            {name:'A등급', value:'A'},
            {name:'B등급', value:'B'},
            {name:'C등급', value:'C'},
        ]
    },
    analyzeParam:{
        pageNo: 1,
        pageSize: 10,
        searchGrade:'ALL',
    },
    analyzeResult: {
        totalCnt:0,
        patnAnlys:[],
    },
    analyzeRequest: [],

    patentParamData:{
        rank:[
            {name:'전체', value:'ALL'},
            {name:'추천', value:'R'},
            {name:'A등급', value:'A'},
            {name:'B등급', value:'B'},
            {name:'C등급', value:'C'},
        ]
    },
    patentResult:[],
    consultingReqResult:{},
});

export const mutations = {
    UPDATE_COMPANY_INFO(state, payload) {
        state.companyInfo = payload
    },
    UPDATE_RESEARCH_FORM(state, payload) {
      state.researchForm = payload
    },
    UPDATE_LOOKUP_RESULT(state, payload) {
        state.lookupResult = payload;
    },
    CLEAR(state){
        state.companyInfo= {...DEFAULT_COMPANY_INFO};
        state.userForm = {...DEFAULT_USER_FORM}
        state.analyzeRequest = [];
    },
    UPDATE_QUERY_BIZ(state, payload){
        state.query.bizNo = payload
    },
    UPDATE_QUERY(state, payload){
        Object.keys(payload).forEach(key => {
            state.query[key] = payload[key];
        });
    },
    UPDATE_PATENT_RESULT(state, payload){
        for(let p of payload){
            state.patentResult.push(p)
        }
    },
    RESET_PATENT_RESULT(state){
        state.patentResult = []
    },
    UPDATE_CONSULTANT_REQ_RESULT(state, payload){
        //console.log('UPDATE_CONSULTANT_REQ_RESULT', state, payload);
        state.consultingReqResult = payload;
    },

    UPDATE_FUND_PARAM(state, payload){
        const {loanPrdcType, sortColumn, sortOrdr} = payload;
        state.fundParam.loanPrdcType = loanPrdcType;
        if( sortColumn ) {
            state.fundParam[loanPrdcType].sortColumn = sortColumn;
            state.fundParam[loanPrdcType].sortOrdr = sortOrdr;
        }
    },
    UPDATE_FUND_RESULT(state, payload){
        const {loanPrdcType, list} = payload;
        state.fundResult[loanPrdcType] = list;
    },

    UPDATE_FINANCE_PARAM(state, payload){
        Object.keys(payload).forEach(key => {
            state.financeParam[key] = payload[key];
        });
    },
    UPDATE_FINANCE_RESULT(state, payload){
        state.financeResult = payload;
    },

    UPDATE_ANALYZE_PARAM(state, payload){
        Object.keys(payload).forEach(key => {
            state.analyzeParam[key] = payload[key];
        });
    },
    INIT_ANALYZE_RESULT ( state, payload ) {
        state.analyzeResult.totalCnt = payload.totalCnt;
        state.analyzeResult.patnAnlys = payload.patnAnlys;
    },
    RESET_ANALYZE_RESULT ( state ) {
        state.analyzeResult.totalCnt = 0;
        state.analyzeResult.patnAnlys = [];
    },
    UPDATE_ANALYZE_RESULT ( state, payload ) {
        state.analyzeResult.totalCnt = payload.totalCnt;
        state.analyzeResult.patnAnlys.push(...payload.patnAnlys)
    },
    UPDATE_ANALYZE_REQUEST ( state, payload ) {
        const { patnNo, checked } = payload;
        if( checked ) {
            const idx = state.analyzeRequest.indexOf(patnNo)
            if (idx == -1) state.analyzeRequest.push(patnNo);
        } else {
            const idx = state.analyzeRequest.indexOf(patnNo)
            if (idx > -1) state.analyzeRequest.splice(idx, 1)
        }
    },
}

export const actions = {

    /**
     *
     * 정책자금 조회
     *
     */
    fetchFund({state, commit}, codeMap) {
        const notFoundBizNo = true;
        const loanPrdcType = state.fundParam.loanPrdcType;
        const sortColumn = state.fundParam[loanPrdcType].sortColumn;
        const sortOrdr = state.fundParam[loanPrdcType].sortOrdr;

        let params = {
            bizNo : state.companyInfo.bizNo,
            empl : findOriginalValue(codeMap.ETC100, state.researchForm.empNumCd),
            hire : findOriginalValue(codeMap.ETC200, state.researchForm.hireCd),
            rprs : findOriginalValue(codeMap.ETC300, state.researchForm.prstAgeCd),
            tech : findOriginalValue(codeMap.ETC400, state.researchForm.techRcmCd),
            loanPrdcType,
            sortColumn,
            sortOrdr,
        };
        if ( notFoundBizNo ) {
            params.addrYn=mappingAddrYn(state.companyInfo.korAddr);
            params.crtfYn=isCertHolder(state.companyInfo);
            params.directYn=mappingDirectYn(state.companyInfo);
            params.etbDate=mappingEtbDate(state.companyInfo);
            params.idsCd=state.companyInfo.idscd;
            params.patnYn=state.companyInfo.patnCnt > 0 ? 'Y' : 'N';
        }
        return consultingService.fetchLoadPrdc(this.$axios, params)
            .then( result => {
                let list = [];
                if(result.success) {
                    list = result.contents;
                }
                commit('UPDATE_FUND_RESULT', {loanPrdcType, list});
                return result;
            })
    },

    /**
     * 특허분석 조회
     */
    fetchAnalyze({state, commit}, reset) {
        const params = {
            bizNo: state.companyInfo.bizNo,
            pageNo: state.analyzeParam.pageNo,
            pageSize: state.analyzeParam.pageSize,
            searchGrade: state.analyzeParam.searchGrade,
        }
        return consultingService.fetchAnalyze(this.$axios, params)
            .then( result => {
                let contents = {};
                if(result.success) {
                    contents = result.contents;
                }
                // 분석요청된 특허 체크 표시
                if ( state.analyzeRequest.length>0 && contents.totalCnt > 0 ){
                    //console.log(state.analyzeRequest);
                    const patnAnlys = contents.patnAnlys.map( patn => {
                        const checked = state.analyzeRequest.indexOf(patn.patnNo)>-1?true:false;
                        return { ...patn, checked}
                    });
                    contents.patnAnlys = patnAnlys;
                }
                // 스토어 저장
                if(reset){
                    commit('INIT_ANALYZE_RESULT', contents);
                }else{
                    if(contents.patnAnlys.length>0){
                        commit('UPDATE_ANALYZE_RESULT', contents);
                    }
                }
                commit('UPDATE_ANALYZE_PARAM',{pageNo: state.analyzeParam.pageNo+1})
                return result;
            });
    },

    /**
     *
     * 추천특허조회
     *
     */
    fetchRecommendPatents({state, commit}) {
        const params = {
            bizNo: state.companyInfo.bizNo,
            pageNo: state.query.pageNo,
            pageSize: state.query.pageSize,
            searchCatgNo: state.query.searchCatgNo,
            searchGrade: state.query.searchGrade,
        }
        return consultingService.fetchLoanPrdcRcmd(this.$axios, params)
            .then( result => {
                if ( result.success ) {
                    commit('UPDATE_PATENT_RESULT', result.contents.prdcs);
                    commit('UPDATE_QUERY',{pageNo: state.query.pageNo+1})
                } else {
                    commit('UPDATE_PATENT_RESULT', []);
                }
                return result;
            });
    },

    async saveConsultingRequest({state,commit}){
        const lookupId = state.lookupResult.lookupId;
        const analyzeRequestPatnNos = state.analyzeRequest;
        const payload = {
            ...state.companyInfo,
            ...state.researchForm,
            analyzeRequestPatnNos,
            agrTm01:true,
            agrTm02:true,
            agrTm03:true,
            agrTm04:true,
            agrTm05:true,
        }

        if (lookupId) {
            const result = await consultingService.saveConsultingReq(this.$axios, lookupId, payload);
            commit('UPDATE_CONSULTANT_REQ_RESULT', result);
            return result.success;
        }
        return false;
    },
}

export const getters = {
    productType(state) {
        return {
            tcb: isCertHolder2(state.companyInfo) || isTechHolder(state.companyInfo) || isAllowedSector(state.companyInfo),
            org: true,
            ip: isTechHolder(state.companyInfo),
        }
    }
}

function findOriginalValue(codeGrp, value){
    for(let i = 0; i < codeGrp.length ; i++ ){
        if(codeGrp[i].cd === value){
            return codeGrp[i].value01;
        }
    }
    return null;
}
function mappingAddrYn(korAddr){
    return korAddr.indexOf('경기도') > -1 ? 'Y' : 'N'
}

function mappingDirectYn(companyInfo){
    return companyInfo.notFoundBizNo ? 'Y' : 'N';
}

function mappingEtbDate(companyInfo){
    if(companyInfo.fndDt){
        return companyInfo.fndDt.replaceAll(".","");
    }else {
        return null;
    }
}

const isCertHolder = (companyInfo) => {
    return companyInfo.labYn || companyInfo.ventureYn || companyInfo.innobizYn || companyInfo.deptYn;
}

const isCertHolder2 = (companyInfo) => {
    return companyInfo.labYn || companyInfo.ventureYn || companyInfo.innobizYn;
}

const isTechHolder = (companyInfo) => {
    return companyInfo.patnCnt > 0;
}

const SECTOR_INIT = ['C', 'E', 'F', 'G', 'J', 'M', 'N', 'P', "R"]
const isAllowedSector = (companyInfo) => {
    return SECTOR_INIT.includes(companyInfo.idscd.substr(4));
}
