<template>
  <div class="app sub" oncontextmenu="return false" ondragstart="return false" onselectstart="return false">
    <client-only>
      <div class="app ifs service" >
        <Header/>
        <Menu class="nav_mobile"/>
      </div>
    </client-only>
  <!-- <div class="app sub"> -->
    <nuxt/>
    <transition name="fade">
      <Alert style="z-index: 300;" v-if="$store.state.ui.popupAlertData.state === true"/>
      <Basic v-if="$store.state.ui.popupBasicData.state === true"/>
      <Loading v-if="$store.state.ui.loadingState === true"/>
    </transition>
  </div>
</template>

<script>
import Header from "../components/ifs/layouts/Mng_Header";
import Setting from "~/mixins/Setting";
import optionalLayout from "../mixins/partner/optionalLayout";
import Alert from "~/components/common/Alert";
import Basic from "~/components/common/popup/Basic";
import Loading from "~/components/ui/popup/Loading";
import Menu from "../components/ifs/layouts/Menu";

export default {
  name: "layout",
  mixins: [Setting, optionalLayout],
  components: {
    Alert,
    Basic,
    Loading,
    Header,
    Menu
  },
};
</script>

<style>
  .service{
    min-height: 0vh !important;
    padding-top: 0px !important;
  }
  @media screen and (max-width: 770px){
    .service{
      margin-bottom:40px;
    }
  }
</style>
