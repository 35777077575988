export default {
    created() {
        if(process.client){
            const parterInfo = this.$getPartnerInfo();
            const view = this.$store.state.ui.view;
            const device = this.$store.state.ui.device;

            /*
             * 랜딩 업체 로고 설정
             */
            const isMobile = (view == 'mobile' || device =='mobile')

            // - 배경에 이미지 적용
            if(parterInfo){
                // - Mobile인 경우와 PC인 경우 이미지
                let imgPath = isMobile ? parterInfo.logoMobileImgPath : parterInfo.logoImgPath;

                this.mixLogoImg = imgPath;
                this.mixBackgroundStyle.backgroundImage =  `url(${imgPath})`;

                if(parterInfo.logoImgWrapperWidth){
                    this.mixBackgroundStyle.width=parterInfo.logoImgWrapperWidth+'px';
                }

                // - 이미지 사이즈에 대한 정의가 있는 경우 처리
                if(parterInfo.logoImgSize){
                    this.mixBackgroundStyle.backgroundSize =  parterInfo.logoImgSize;
                    this.mixBackgroundStyle.backgroundPositionX =  'left'
                    this.mixBackgroundStyle.backgroundPositionY =  'center'
                }
                if(isMobile){
                    if(parterInfo.logoImgMobileWrapperheight){
                        this.mixBackgroundStyle.height=parterInfo.logoImgMobileWrapperheight+'%';
                    }

                    if(parterInfo.logoImgMobileWrapperWidth&&parterInfo.logoMobileImgSize){
                        this.mixBackgroundStyle.width = parterInfo.logoImgMobileWrapperWidth+'%';
                        this.mixBackgroundStyle.backgroundSize =  "";
                    }else if(parterInfo.logoMobileImgSize){
                        this.mixBackgroundStyle.backgroundSize =  parterInfo.logoMobileImgSize;
                    }else if(parterInfo.logoImgMobileWrapperWidth){
                        this.mixBackgroundStyle.width = parterInfo.logoImgMobileWrapperWidth+'%';
                    }else{
                        this.mixBackgroundStyle.width =  '100%'
                    }
                }
            }
        }
    },
    data(){
        return{
            mixLogoImg:null,
            mixBackgroundStyle:{
                backgroundImage:'none',
                width:'auto',
            }
        }
    },
    methods:{
        mixFnCheckOn(keyword){
            return (this.$route.path.indexOf(keyword)>=0) ? true : false;
        }
    }
}
