import serviceMix from '../serviceMix'
import * as serviceTypes from '../../constants/loginMixNextServiceType';
import { ARTICLE_OF_INCORPORATION } from "../../constants/loginMixNextServiceType";

const mypagePath = {};
mypagePath[serviceTypes.TAX]='tax';
mypagePath[serviceTypes.INSURANCE]='insured';
mypagePath[serviceTypes.EMPLOY]='grant';

export default {
    mixins:[serviceMix],
    methods:{
        mixFnAddComp(){
            let path = '/services/inputBizNo';
            path += `?selectedService=${serviceTypes.LANDING_HOME}`; // 프로세스가 완료되면 랜딩 홈으로 리턴
            path += `&mode=addComp`; // 회원에게 회사만 추가함
            this.$store.commit('ui/UPDATE_STATE', {popupCompanySelect:false})
            this.$router.push(path)
        },
        mxiFnGoHome(){
            this.$store.dispatch('ui/setMenuState', false);
            this.$router.push(this.$goHome())
        },
        mixFnSupport(){
            this.$store.dispatch('ui/setMenuState', false);
            this._serviceChain(serviceTypes.BIZ_SUPPORT, false)
        },
        mixFnSupportIntro(){
            this.$store.dispatch('ui/setMenuState', false);
            // TODO - 현재는 메인비즈만 적용하나 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
            const partnerInfo = this.$getPartnerInfo();
            // if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                this.$router.push(`${partnerInfo.path}support/intro`)
            // } else{
            //     this.$router.push('/services/intro/support')
            // }
        },
        mixFnTax(){
            this.$store.dispatch('ui/setMenuState', false);
            this.fnSelectService(serviceTypes.TAX);
        },
        mixFnTaxIntro(){
            this.$store.dispatch('ui/setMenuState', false);
            const partnerInfo = this.$getPartnerInfo();
            // TODO - 현재는 메인비즈만 적용하나 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
            // if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                this.$router.push(`${partnerInfo.path}tax/intro`)
            // }else{
            //     this.$router.push('/services/intro/tax')
            // }
        },
        mixFnInsurance(){
            this.$store.dispatch('ui/setMenuState', false);
            this._serviceChain(serviceTypes.INSURANCE, true)
        },
        mixFnInsuranceIntro(){
            this.$store.dispatch('ui/setMenuState', false);
            const partnerInfo = this.$getPartnerInfo();
            // TODO - 현재는 메인비즈만 적용하나 - 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
            // if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                this.$router.push(`${partnerInfo.path}insurance/intro`)
            // }else{
            //     this.$router.push('/services/intro/insurance')
            // }
        },
        mixFnEmploy(){
            this.$store.dispatch('ui/setMenuState', false);
            this._serviceChain(serviceTypes.EMPLOY, true)
        },
        mixFnLoan(){
            this.$store.dispatch('ui/setMenuState', false);
            this._serviceChain(serviceTypes.LOAN, true)
        },
        mixFnLabor(){
            this.$store.dispatch('ui/setMenuState', false);
            this._serviceChain(serviceTypes.LABOR, true)
        },
        mixFnLaborIntro(){
            this.$store.dispatch('ui/setMenuState', false);
            const partnerInfo = this.$getPartnerInfo();
            // TODO - 현재는 메인비즈만 적용하나 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
            // if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                this.$router.push(`${partnerInfo.path}labor/intro`)
            // }else{
            //     this.$router.push('/services/intro/labor')
            // }
        },
        mixFnGoConsultant(){
            const link = this.$store.state.env.consultantAdminLink;
            if(link){
                const adminWindow = window.open(link, '_blank');
            }
        },
        mixDownTaxGuide(){
            // 세금환급 이용가이드
            window.open('/files/다된다 세금환급(경정청구) 이용매뉴얼.pdf', '_blank')
        },
        mixDownInsuranceGuide(){
            // 보험료절감 이용가이드
            window.open('/files/다된다 4대보험료 절감 이용매뉴얼.pdf', '_blank')
        },
        mixLaborMng(){
            const partnerInfo = this.$getPartnerInfo();
            window.open(`${partnerInfo.path}mng/labor/`, '_blank')
        },
        mixGoTop(){
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        mixGoWelfareFund(){
            this.$store.dispatch('ui/setMenuState', false);
            const partnerInfo = this.$getPartnerInfo();
            this.$router.push(`${partnerInfo.path}welfare/fund`)
        },
        mixGoArticleOfIncorp(){
            this.$store.dispatch('ui/setMenuState', false);
            const partnerInfo = this.$getPartnerInfo();
            this.$router.push(`${partnerInfo.path}articleOfIncorporation/`)
        },
        mixArticleOfIncorp(){
            this.$store.dispatch('ui/setMenuState', false);
            this._serviceChain(serviceTypes.ARTICLE_OF_INCORPORATION, false)
        },
        _serviceChain(serviceType, setCompInfo){
            // 로그인 체크
            const loginSuccess = this.__loginCheck(serviceType);
            if(loginSuccess){
                // 이미 서비스 상담 신청을 진행했는지 체크
                const serviceUsed = this.__serviceUsedCheck(serviceType);
                // 신청이 되어있지 않은 경우
                if(!serviceUsed){
                    // 회사 정보가 있는지 체크
                    const compSuccess = this.__companyInfoCheck(serviceType);
                    // 회사 정보가 있는 경우
                    if(compSuccess) {
                        if(setCompInfo){
                            this.__companyInfoSetting();
                        }
                        this.fnSelectService(serviceType);
                    }
                    // 회사 정보가 없는 경우
                    // ...
                    else{
                        //alert('서비스를 진행하기 위한 회사정보가 없습니다')
                    }
                }
                // 이미 신청이 되어있는 경우
                else{
                    this.$fnAlert(
                        '서비스를 진행한 이력이 있습니다.<br/> 마이페이지에서 확인하실 수 있어요.', {name:'확인', link:'/mypage/'+mypagePath[serviceType]}
                    );
                }
            }
        },
        __serviceUsedCheck(serviceType){
            let used = false;
            const myComp = this.$store.getters["userStore/getMyComp"];
            // if(this.$store.state.userStore.myCompList && this.$store.state.userStore.myCompList.length>0){
            if(myComp){
                if( serviceTypes.TAX == serviceType ){
                    used = myComp.tax;
                }
                else if( serviceTypes.EMPLOY == serviceType ){
                    used = myComp.employ;
                }
                else if( serviceTypes.INSURANCE == serviceType ) {
                    used = myComp.insurance;
                }
            }
            if(used){
                // const comp = this.$store.state.userStore.myCompList[0];
                // sessionStorage.setItem('compNm', comp.compNm);
                this.$setBizNo(myComp.bizNo);
            }
            return used;
        },
        __loginCheck(selectedService){
            if(!this.$isLogin()){
                this.$router.push(`/services/socialLogin?addBizInfo=y&selectedService=${selectedService}`);
                return false;
            }
            return true;
        },
        __companyInfoCheck(selectedService){
            if( this.$store.state.userStore.myCompList && this.$store.state.userStore.myCompList.length>0){
                return true;
            }else{
                this.$fnAlert('사업자등록이 필요합니다', {
                    name:'확인',
                    link:`/services/inputBizNo?addBizInfo=y&selectedService=${selectedService}`,
                });
                return false;
            }
        },
        __companyInfoSetting(){
            const myComp = this.$store.getters["userStore/getMyComp"];
            sessionStorage.setItem('bizNo', myComp.bizNo);
            sessionStorage.setItem('compNm', myComp.compNm);
            this.$store.commit('cmnCompanyStore/UPDATE_BIZNO', myComp.bizNo)
            this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO',myComp);
        },
        // __partnerInfoSetting(){
        //     this.$savePartnerInfo(partnerService.infoYou);
        // }
    }
}
