<template>
  <!-- type: alert(경고창), confirm(선택창) -->
  <div class="popup" v-if="data !== null" :class=" data.type !== undefined ? data.type : 'basic'">
    <div class="p_inner">
      <button class="btn_close" @click="closePopup">닫기</button>
      <div class="tit" v-html="data.title"></div>
      <div class="content">
        <div class="msg" v-html="data.msg"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: null,
    };
  },
  components: {
  },
  mounted() {
    this.data = this.$store.state.ui.popupBasicData;
    //라우터 전환될 때 이벤트
    $nuxt.$router.beforeEach((to, from, next) => {
      this.closePopup();
      next();
    });
  },
  methods: {
    closePopup(){
      this.$store.dispatch('ui/setPopupBasicData', {
        state:false,
        type: null,
        title: null,
        msg: null,
      });
    },
  },
};
</script>
