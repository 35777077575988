import axios from 'axios';
import { generateQueryParameter } from '../../utils/HttpUtils';

const getToken = async (socialType, code, redirectUri, user) => {
  const params = {
    code,
    redirectUri,
    user: user ?? null
  }
  return axios.get(`/apiAuth/${socialType}/callback?${generateQueryParameter(params)}`).then(res => res.data);
};

const refreshToken = async refreshToken => {
  return axios.get(`/apiAuth/token/refresh?refreshToken=${refreshToken}`).then(res => res.data);
};

const logout = async userNo => {
  return axios.get(`/apiAuth/logout?userNo=${userNo}`).then(res => {
    res.data});
};

export default {
  getToken,
  refreshToken,
  logout,
};
