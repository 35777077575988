import userService from '../service/userService';
import userAllOkService from '~/service/allOk/userService';

const CONTRACT_INFO = {
  bizNo: null,
  compNm: null,
  extData:{}
}

export const state = () => ({
  success: null,
  selectedMyCompBizNo:null,
  myCompList: [],
  myCompDetail:{},
  myValuationedCompMap:{},
  valuationedBizNo:null,
  patnCnt:null,
  patnValue:null,
  compValue:null,
  userData: {},
  customerId: null,
  contractInfo:{
    ...CONTRACT_INFO
  }
});

export const mutations = {
  UPDATE_SELECTED_MY_COMP_BIZ_NO(state, bizNo){
    state.selectedMyCompBizNo = bizNo;
  },
  UPDATE_MY_COMPLIST(state, payload){
    state.myCompList = payload;
  },
  UPDATE_MY_VALUATIONED_COMP_MAP(state, payload){
    Object.keys(payload).forEach(key => {
      state.myValuationedCompMap[key] = payload[key];
    });
  },
  UPDATE_CONTRACTINFO(state, payload){
    Object.keys(payload).forEach(key => {
      state.contractInfo[key] = payload[key];
    });
  },
  UPDATE_CUSTOMERID(state, payload){
    state.customerId = payload

  },
  UPDATE_SUCCESS(state, payload){
    state.success = payload;
  },
  UPDATE_MYINFO(state, payload){
    state.userData = payload;
  },
  UPDATE_COMPANY_VALUE(state, payload){
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
  CLEAR(state){
    state.success = false;
    state.myCompList = [];
    state.valuationedBizNo = null;
    state.patnCnt = null;
    state.patnValue = null;
    state.compValue = null;
    state.myValuationedCompMap = {};
    state.selectedMyCompBizNo = null;
    state.contractInfo = {...CONTRACT_INFO};
  }
};

export const actions = {
  fetchMe({state, commit}){
    const userId = this.$auth_token_userNo(process.client);
    if(userId){
      return userService.fetchMe(this.$axios, userId )
          .then( res => {
            if(res.success){
              commit('UPDATE_SUCCESS', res.success);
              commit('UPDATE_MYINFO', res.contents);
              commit('UPDATE_MY_COMPLIST', res.contents.compList);
            }
            return res
          })
    }
    return {
      success:false,
    };
  },
  fetchMyLoan({state, commit}, bizNo){
    return userAllOkService.checkBizNo(this.$axios, bizNo)
      .then( res => {
        if(res.success){
          commit('UPDATE_CUSTOMERID', res.contents.customerId)
          return res.contents.customerId
        }
      })
  }
};

export const getters = {
  getMyComp(state){
    const selectedBizNo = state.selectedMyCompBizNo
    if(selectedBizNo
        && state.myCompList
        && state.myCompList.length>0){
      return state.myCompList.find(myComp => myComp.bizNo == selectedBizNo )
    }
    return null;
  }
}
