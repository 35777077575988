import * as storageKey from "../constants/storageKey";
import tokenSessionStoragePlugin from "./tokenSessionStoragePlugin";

export default (context, inject) => {

    inject('auth_token_update', (token)=>{
        tokenSessionStoragePlugin.update(process.client, token)
    });

    inject('auth_token_userNo', ()=>{
       return tokenSessionStoragePlugin.getUserNo(process.client);
    });

    inject('auth_MyBizNo', ()=>{
        if (process.client) {
            return sessionStorage.getItem(storageKey.selectedMyCompBizNo);
        }
    });

    inject('auth_SaveBizNo', (bizNo) => {
        if (process.client) {
            sessionStorage.setItem(storageKey.selectedMyCompBizNo, bizNo);
            context.store.commit('userStore/UPDATE_SELECTED_MY_COMP_BIZ_NO', bizNo);
            //
        }
    });

    inject('auth_RestoreBizNo', ()=>{
       if(process.client){
           if(sessionStorage.getItem(storageKey.selectedMyCompBizNo)){
               context.store.commit('userStore/UPDATE_SELECTED_MY_COMP_BIZ_NO', sessionStorage.getItem(storageKey.selectedMyCompBizNo));
           }
       }
    });

    inject('auth_MyComp', ()=> {
       if(process.client){
           return context.store.state.userStore.myCompList
               .find( myComp => myComp.bizNo == sessionStorage.getItem(storageKey.selectedMyCompBizNo));
       }
    });

}
