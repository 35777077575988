const getTaxCredit = async (axios, bizNo, taxation) => {
    return await axios.get(`/apiMngAo/customer/tax/hometax/tax-credit/${bizNo}/none${taxation ? `?taxation=${taxation}` : ''}`)
        .then( res => res.data );
}

const postConsulting = (axios, bizNo) => {
    return axios.post(`/apiMngAo/customer/tax/consulting`, {bizNo})
            .then( res => res.data);
}

const fetchTaxCreditList = (axios, bizNo) => {
    return axios.get(`/apiMngAo/customer/tax/hometax/tax-credit/${bizNo}`)
        .then(res=>res.data)
}

const fetchSimpleCalculationResult = (axios, bizNo) => {
    return axios.get(`/apiMngAo/customer/tax/${bizNo}/simple-calculation-result`).then(res => res.data);
  };

export default {
    getTaxCredit,
    postConsulting,
    fetchTaxCreditList,
    fetchSimpleCalculationResult
}
