<template>
  <div class="popup">
    <div class="p_inner" style="width:auto; max-width: none">
      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/popup/mainbiz231222-2.jpg" usemap="#buttonMap" />
      <div style="display: flex; justify-content: space-between">
        <button class="gray" @click="oneDayStop">오늘하루 그만보기</button>
        <button class="mint" @click="closePopup">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>

import Popular from "../../../../pages/services/support/popular";
export default {
  data(){
    return {
    }
  },
  components:{
    Popular
  },
  methods: {
    closePopup(){
      // this.$emit('popupState');
      this.$popupCtrlP_stopThisSession('popupMainBiz');
      this.$store.dispatch('ui/togglePopup', 'popupMainBiz');
    },
    oneDayStop(){
      this.$popupCtrlP_stopReq('popupMainBiz');
      this.closePopup();
    }
  },
}

</script>
<style scoped v-if="true">
  /*@import "//s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/css/style.css?v=230808_1";*/
.popup button{
  padding: 15px 0px;
  text-align: center;
  color: white;
}
.popup button.gray{
  background-color: #777777;
  flex-grow: 1;
  width: 50%;
}
.popup button.mint{
  background-color: #0C7573;
  flex-grow: 1;
  width: 50%;
}
</style>
