const estimate = (axios, payload) => {
  axios.post('/apiMngAo/customer/insurance/estimate', payload)
      .then(res=>{
        if(res.data.success){
          this.$fnSaveLog(this.$axios, `고용보험_근로자고용정보현황조회_저장 성공`);
          this.$store.commit("servicesStore/UPDATE_MATCHING", res.data.contents);
          return {
            success:true,
          };
        } else {
          if(res.data.error.cd === 'AUTH JWT-00'){
            this.$fnSaveErrorLog(this.$axios, '근로자 고용정보 현황을 저장중에 실패: '+res.data.error.cd+' : '+res.data.error.msg);
            return {
              success:false,
              errorType:'tokenExpired'
            }
          }else{
            this.$fnSaveErrorLog(this.$axios,'근로자 고용정보 현황을 저장중에 실패: '+res.data.error.cd+' : '+res.data.error.msg);
            return {
              success:false,
              errorType:'serverError'
            }
          }
        }
      })
      .catch(err => {
        this.$fnSaveErrorLog(this.$axios, '근로자 고용정보 현황을 저장중에 실패 (AXIOS) '+err.message);
        return {
          success:false,
          errorType:'serverError'
        }
      })
}

const postConsulting = async (axios, payload) => {
  return await axios.post('/apiMngAo/customer/insurance/consulting', payload).then( res => res.data);
}

const getResult = async (axios, bizNo) => {
  return await axios.get(`/apiMngAo/customer/insurance/estimate/${bizNo}`).then( res => res.data);
}

const getResultNone = async (axios, bizNo) => {
  return await axios.get(`/apiMngAo/customer/insurance/estimate/${bizNo}/none`).then( res => res.data);
}

export default {
  postConsulting,
  getResult,
  getResultNone
}
