<template>
  <div class="app ifs">
    <div class="popup p_biznum">
      <div class="p_inner">
        <button class="btn_close" @click="closePopup">닫기</button>
        <h3>사업자번호를 입력해주세요.</h3>
        <div class="bx_search">
          <input :value="bizNo" disabled type="text" class="ipt" placeholder="숫자만 입력해주세요."/>
        </div>
        <button type="button" class="btn" :class="{gray: !bizNo}" :disabled="!bizNo" @click="fnSearch">조회</button>
      </div>
    </div>
  </div>
</template>

<script>
import companyMix from "../../../mixins/companyMix";
export default {
  mixins:[companyMix],
  data(){
    return{
      bizNo:null
    }
  },
  beforeMount() {
    const bizNo = this.$store.getters["userStore/getMyComp"].bizNo;
    this.bizNo = bizNo;
    this.$store.dispatch('cmnCompanyStore/fetchCompanyInfo', bizNo);
  },
  methods: {
    async fnSearch(){
      this.closePopup();
      // 조회된 사업자가 없는 경우
      if(!this.$store.state.cmnCompanyStore.companyInfo.bizNo){
        this.$fnAlert('조회된 사업자 정보가 없습니다.');
      }
      // 사업자가 정상 조회된 경우
      else{
        await this.$router.push('/mypage/myInfo/confirm')
      }
    },
    closePopup(){
      this.$store.dispatch('ui/closeBizNumPopup2');
    },
  },
}

</script>
<style scoped v-if="true">
  @import "//s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/css/style.css?v=230808_1";
</style>
