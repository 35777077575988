import {generateQueryParameter} from '~/utils/HttpUtils';

const employAvailTypes = async (axios, payload, accessToken) => {
  const idsCd = generateQueryParameter(payload)
  const url = `/apiMngAo/employ/availTypes?${idsCd}`
  return axios.get(url)
    .then( res => res.data);
}

// const fetchQuestions = async (axios) => {
//   return axios.get('/apiMngAo/employ/questions', {headers: {
//     Authorization: `Bearer ${accessToken}`
//   }})
//     .then( res => res.data);
// }

const employMatching = async (axios, payload) => {
  return axios.post('/apiMngAo/employ/matching', payload)
    .then( res => res.data);
}

const employMatchingData = async (axios, payload) => {
  return axios.get(`/apiMngAo/employ/matching/${payload}`)
    .then( res => res.data);
}

const employMatchingData2 = async (axios, payload) => {
  return axios.get(`/apiMngAo/employ/matching/${payload}/none`)
    .then( res => res.data);
}

const employConsulting = async (axios, bizNo) => {
  return axios.post(`/apiMngAo/employ/${bizNo}/consulting`)
  .then( res => res.data);
}

export default {
  employAvailTypes,
  employMatching,
  employMatchingData,
  employConsulting,
  employMatchingData2
}
