import employService from "../service/allOkMng/employService";

export const state = () => ({
    employAvailTypes: null,
    matchingResult:null,
    employSuccess:false,
});

export const mutations = {
    UPDATE_EMPLOY_AVAILTYPES(state, payload){
        state.employAvailTypes = payload;
    },
    UPDATE_MATCHING(state, payload){
        state.matchingResult = payload;
    },
    UPDATE_EMPLOY_SUCCESS(state, payload){
        state.employSuccess = payload;
    },
    CLEAR(state){
        state.employAvailTypes=null;
    },
};

export const actions = {
    fetchEmployAvailTypes({state, commit}, payload) {
        return employService.employAvailTypes(this.$axios, payload)
            .then(result => {
                if (result.success) {
                    commit('UPDATE_EMPLOY_AVAILTYPES', result.contents);
                }
                return result;
            });
    },
    fetchEmployMatching({state, commit}, payload) {
        return employService.employMatching(this.$axios, payload)
            .then(result => {
                if (result.success) {
                    commit('UPDATE_MATCHING', result.contents);
                }
                return result;
            });
    },
    async fetchEmpolyResult({state, commit}, bizNo) {
        const result = await employService.employMatchingData(this.$axios, bizNo);
        if(result.success) {
            commit('UPDATE_MATCHING', result.contents);
            commit('UPDATE_EMPLOY_SUCCESS', result.success);
        }else {
            commit('UPDATE_MATCHING', null);
            commit('UPDATE_EMPLOY_SUCCESS', false)
        }
        return result
    },
    async fetchEmpolyResult2({state, commit}, bizNo) {
        const result = await employService.employMatchingData2(this.$axios, bizNo);
        if(result.success) {
            commit('UPDATE_MATCHING', result.contents);
            commit('UPDATE_EMPLOY_SUCCESS', result.success);
        }
        return result
    }
}
