import * as partnerInfos from "~/constants/partnerInfo";
import {unicornranch} from "../../constants/partnerInfo";
export default {
    created() {
        // Header Logo
        if(process.client){
            this.fnSetFooter()
        }
    },
    data(){
        return{
            mixFooter:{}
        }
    },
    watch: {
        '$route'() {
            this.fnSetFooter()
        }
    },

    methods: {
        fnSetFooter() {
            if(this.$route.path === '/services/intro/tax') {
                this.mixFooter = partnerInfos.taxIntroFooter;
            } else {
                const parterInfo = this.$getPartnerInfo();
                if(parterInfo){
                    this.mixFooter = parterInfo.footer;
                }else{
                    this.mixFooter = partnerInfos.unicornranch.footer;
                }

            }
        }
    },
}
