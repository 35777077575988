import userLoanService from '../service/allOk/userService';
import productService from '../service/commonAllOk/productService';

const DEFAULT_COMPANY_INFO = {
  bizNo: null,
  prstNm: null,
  compNm: '',
  compTel: null,
  fndDt: null,
  korAddr: null,
  idscd: null,
  sector: null,
  patnCnt: null,
  patnValue: null,
  techRate: null,
};

const DEFAULT_USER_FORM = {
  clientEmail: null,
  clientTel: null,
  prstAgeCd: null,
  empNumCd: null,
  hireCd: null,
  techRcmCd: null,
  agrTm01: null,
  agrTm02: null,
}

const DEFAULT_SIGNUP_FORM = {
  agrTm01: false,
  agrTm02: false,
  agrTm03: false
}

export const state = () => ({
  signUpForm:{...DEFAULT_SIGNUP_FORM},
  companyInfo: {...DEFAULT_COMPANY_INFO},
  userForm: {...DEFAULT_USER_FORM},
  query:{
    bizNo: null,
    pageNo: 1,
    pageSize: 10,
    searchCatgNo: 'TM0000000005',
    searchGrade: 'ALL'
  },
  lookupResult:{},
  fundParamData:{
    loanPrdcType:{ 보증형:'LPT01', 직접대출:'LPT02'},
    sortColumn: {
      LPT01: { 한도:'APLC_LMT', 보증료율:'GRNT_FEE_RATE',},
      LPT02: { 한도:'APLC_LMT', 금리:'LOAN_INTR_RATE'}
    }
  },
  fundParam:{
    loanPrdcType:null,
    LPT01: { sortColumn:'GRNT_FEE_RATE',sortOrdr:'ASC',},
    LPT02: { sortColumn:'LOAN_INTR_RATE',sortOrdr:'ASC',}
  },
  fundResult:{ LPT01:[], LPT02:[],},
  analyzeParamData:{
    rank:[
      {name:'전체', value:'ALL'},
      {name:'A등급', value:'A'},
      {name:'B등급', value:'B'},
      {name:'C등급', value:'C'},
    ]
  },
  analyzeParam:{
    pageNo: 1,
    pageSize: 10,
    searchGrade:'ALL',
  },
  analyzeResult: {
    totalCnt:0,
    patnAnlys:[],
  },

  patentParamData:{
    rank:[
      {name:'전체', value:'ALL'},
      {name:'추천', value:'R'},
      {name:'A등급', value:'A'},
      {name:'B등급', value:'B'},
      {name:'C등급', value:'C'},
    ]
  },
  patentResult:[],
  consultingReqResult:{},
  bizNo:null,
  certList:null,
  selectedCert:null,
  selectBtn: null,
  customerLookup: null,
});

export const mutations = {
  UPDATE_TAB(state, payload){
    state.tab = payload
  },
  UPDATE_BIZNO(state, payload){
    state.bizNo = payload
  },
  UPDATE_LOOKUP_RESULT(state, payload) {
    Object.keys(payload).forEach(key => {
      state.lookupResult[key] = payload[key];
    });
  },
  CLEAR_INFO(state){
    state.companyInfo = {...DEFAULT_COMPANY_INFO};
    state.customerLookup = null;
  },
  UPDATE_COMPANY_INFO(state, payload){
    Object.keys(payload).forEach(key => {
      state.companyInfo[key] = payload[key];
    });
  },
  UPDATE_USER_FORM(state, payload){
    Object.keys(payload).forEach(key => {
      state.userForm[key] = payload[key];
    });
  },
  UPDATE_SIGNUP_FORM(state, payload){
    Object.keys(payload).forEach(key => {
      state.signUpForm[key] = payload[key];
    });
  },
  CLEAR(state){
    state.analyzeRequest = [];
    state.selectBtn = null;
  },
  UPDATE_QUERY_BIZ(state, payload){
    state.query.bizNo = payload
  },
  UPDATE_QUERY(state, payload){
    Object.keys(payload).forEach(key => {
      state.query[key] = payload[key];
    });
  },
  UPDATE_PATENT_RESULT(state, payload){
    for(let p of payload){
      state.patentResult.push(p)
    }
  },
  RESET_PATENT_RESULT(state){
    state.patentResult = []
  },
  UPDATE_CONSULTANT_REQ_RESULT(state, payload){
    state.consultingReqResult = payload;
  },

  UPDATE_FUND_PARAM(state, payload){
    const {loanPrdcType, sortColumn, sortOrdr} = payload;
    state.fundParam.loanPrdcType = loanPrdcType;
    if( sortColumn ) {
      state.fundParam[loanPrdcType].sortColumn = sortColumn;
      state.fundParam[loanPrdcType].sortOrdr = sortOrdr;
    }
  },
  UPDATE_FUND_RESULT(state, payload){
    const {loanPrdcType, list} = payload;
    state.fundResult[loanPrdcType] = list;
  },

  UPDATE_ANALYZE_PARAM(state, payload){
    Object.keys(payload).forEach(key => {
      state.analyzeParam[key] = payload[key];
    });
  },
  INIT_ANALYZE_RESULT ( state, payload ) {
    state.analyzeResult.totalCnt = payload.totalCnt;
    state.analyzeResult.patnAnlys = payload.patnAnlys;
  },
  RESET_ANALYZE_RESULT ( state ) {
    state.analyzeResult.totalCnt = 0;
    state.analyzeResult.patnAnlys = [];
  },
  UPDATE_ANALYZE_RESULT ( state, payload ) {
    state.analyzeResult.totalCnt = payload.totalCnt;
    state.analyzeResult.patnAnlys.push(...payload.patnAnlys)
  },
  UPDATE_SELECTED_CERT(state, payload){
    state.selectedCert = payload;
  },
  UPDATE_CERTLIST(state, payload){
    state.certList = payload;
  },
  UPDATE_SELECTBTN(state, payload){
    state.selectBtn = payload
  },
  UPDATE_CUSTOMER_LOOKUP(state, payload){
    state.customerLookup = payload
  }
};

export const actions = {
  async fetchCustomerLookup({state, commit}) {
    const bizNo = state.companyInfo.bizNo;
    if(!bizNo){
      return { result: { error : { cd : 'NO_BIZ_DATA', msg : '조회 할 사업자 번호가 없습니다.' } } }
    }
    const result = await userLoanService.fetchCustomerLookup(this.$axios, bizNo);
    if (result.success) {
      commit('UPDATE_CUSTOMER_LOOKUP', result.contents);
    }
    return result;
  },

  /**
   *
   * 정책자금 조회
   *
   */
  fetchFund({state, commit}, codeMap) {
    const notFoundBizNo = state.companyInfo.notFoundBizNo;
    const loanPrdcType = state.fundParam.loanPrdcType;
    const sortColumn = state.fundParam[loanPrdcType].sortColumn;
    const sortOrdr = state.fundParam[loanPrdcType].sortOrdr;

    let params = {
      bizNo : state.companyInfo.bizNo,
      empl : findOriginalValue(codeMap.ETC100, state.userForm.empNumCd),
      hire : findOriginalValue(codeMap.ETC200, state.userForm.hireCd),
      rprs : findOriginalValue(codeMap.ETC300, state.userForm.prstAgeCd),
      tech : findOriginalValue(codeMap.ETC400, state.userForm.techRcmCd),
      loanPrdcType,
      sortColumn,
      sortOrdr,
    };
    if ( notFoundBizNo ) {
      params.addrYn=mappingAddrYn(state.companyInfo.korAddr);
      params.crtfYn=isCertHolder(state.companyInfo);
      params.directYn=mappingDirectYn(state.companyInfo);
      params.etbDate=mappingEtbDate(state.companyInfo);
      params.idsCd=state.companyInfo.idscd;
      params.patnYn=state.companyInfo.patnCnt > 0 ? 'Y' : 'N';
    }
    return productService.fetchLoadPrdc(this.$axios, params)
      .then( result => {
        let list = [];
        if(result.success) {
          list = result.contents;
        }
        commit('UPDATE_FUND_RESULT', {loanPrdcType, list});
        return result;
      })
  },
  // fetchFund( _, params){
  //   return productService.fetchLoadPrdc(this.$axios, params)
  //   .then( result => {
  //     return result;
  //   })
  // },

  /**
   * 특허분석 조회
   */
  fetchAnalyze({state, commit}, reset) {
    const params = {
      bizNo: state.companyInfo.bizNo,
      pageNo: state.analyzeParam.pageNo,
      pageSize: state.analyzeParam.pageSize,
      searchGrade: state.analyzeParam.searchGrade,
    }
    return productService.fetchAnalyze(this.$axios, params)
      .then( result => {
        let contents = {};
        if(result.success) {
          contents = result.contents;
        }
        if(reset){
          commit('INIT_ANALYZE_RESULT', contents);
        }else{
          if(contents.patnAnlys.length>0){
            commit('UPDATE_ANALYZE_RESULT', contents);
          }
        }
        commit('UPDATE_ANALYZE_PARAM',{pageNo: state.analyzeParam.pageNo+1})
        return result;
      });
  },

  /**
   *
   * 추천특허조회
   *
   */
   fetchRecommendPatents({state, commit}) {
    const params = {
      bizNo: state.companyInfo.bizNo,
      pageNo: state.query.pageNo,
      pageSize: state.query.pageSize,
      searchCatgNo: state.query.searchCatgNo,
      searchGrade: state.query.searchGrade,
    }
    return productService.fetchLoanPrdcRcmd(this.$axios, params)
      .then( result => {
        if ( result.success ) {
          commit('UPDATE_PATENT_RESULT', result.contents.prdcs);
          commit('UPDATE_QUERY',{pageNo: state.query.pageNo+1})
        } else {
          commit('UPDATE_PATENT_RESULT', []);
        }
        return result;
      });
  },
};

export const getters = {
  productType(state) {
    return {
      tcb: isCertHolder2(state.companyInfo) || isTechHolder(state.companyInfo) || isAllowedSector(state.companyInfo),
      org: true,
      ip: isTechHolder(state.companyInfo),
    }
  }
}

function mappingAddrYn(korAddr){
  return korAddr.indexOf('경기도') > -1 ? 'Y' : 'N'
}

function mappingDirectYn(companyInfo){
  return companyInfo.notFoundBizNo ? 'Y' : 'N';
}

function mappingEtbDate(companyInfo){
  if(companyInfo.fndDt){
    return companyInfo.fndDt.replaceAll(".","");
  }else {
    return null;
  }
}

function findOriginalValue(codeGrp, value){
  for(let i = 0; i < codeGrp.length ; i++ ){
    if(codeGrp[i].cd === value){
      return codeGrp[i].value01;
    }
  }
  return null;
}

const isCertHolder = (companyInfo) => {
  return companyInfo.labYn || companyInfo.ventureYn || companyInfo.innobizYn || companyInfo.deptYn;
}

const isCertHolder2 = (companyInfo) => {
  return companyInfo.labYn || companyInfo.ventureYn || companyInfo.innobizYn;
}

const isTechHolder = (companyInfo) => {
  return companyInfo.patnCnt > 0;
}

const SECTOR_INIT = ['C', 'E', 'F', 'G', 'J', 'M', 'N', 'P', "R"]
const isAllowedSector = (companyInfo) => {
  if(companyInfo.idscd)
    return SECTOR_INIT.includes(companyInfo.idscd.substr(4));
}
