<template>
  <div>
    <!--// Fixed Layer, 768px ~ : PC + Tablet //-->
    <div class="idx-over-768">
      <div class="top-menu-layer xl-flex-col xl-center" style="display: flex; position: fixed; top: 60px; width: 100%; height: 171px; background: #f5f5f5; box-shadow: 0 0 0 0px #ff2800 inset">
        <!--// 타이틀 //-->
        <div class="xl-wrapper" style="max-width: 1280px; background: #ffffff">
          <!--// 1. all media, 768px ~ : PC + Tablet //-->
          <div class="idx-over-768 xl-margin">
            <div class="blc-title xl-flex-col xl-center">
              <div class="blc-title-h2box xl-flex-row xl-left">
                <!--// 타이틀 //-->
                <div class="blc-title-h2 xl-flex-col xl-bottom">{{ data.제목 }}</div>
              </div>
              <div class="_v_Padding_" style="height: 8px"></div>
              <div class="blc-title-pbox xl-flex-row xl-left">
                <!--// 설명 //-->
                <div class="blc-title-p xl-flex-row xl-left"><b>인기지원사업</b>&nbsp;내용을 확인해보세요</div>
              </div>
            </div>
          </div>
        </div>

        <!--// 바로가기 //-->
        <div class="xl-wrapper" style="max-width: 1280px; background: #ffffff">
          <!--// 1. all media, 768px ~ : PC + Tablet [PC 1-600, 2-1310, 3-1590, 4-1760, 5-1950, 6-2150] //-->
          <div class="idx-over-768 xl-margin">
            <div class="bp-direction xl-flex-col xl-top" id="_bpDirection_x768">
              <div class="_v_Padding_" style="height: 0px"></div>
              <div class="bp-direction-c1">
                <div class="bp-direction-c1-wrapper xl-flex-row xl-left">
                  <div @click="fnGoScrollPosition(1)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C11_x900">사업개요</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(2)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C12_x900">지원분야 및 대상</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(3)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C13_x900">신청절차</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(4)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C14_x900">신청서류</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(5)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C15_x900">신청기간</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(6)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C16_x900">주관기관</div>
                </div>
              </div>
            </div>
          </div>
          <!--// all media over 900px //-->
        </div>

        <div class="_v_Padding_" style="height: 10px; width: 100%; max-width: 1280px; background: #ffffff"></div>
      </div>
    </div>

    <!--// Fixed Layer, idx-320-767 : Mobile //-->
    <div class="idx-320-767">
      <div class="top-menu-layer xl-flex-col xl-center" style="display: flex; position: fixed; top: 60px; width: 100%; height: 201px; background: #f5f5f5; box-shadow: 0 0 0 0px #ff2800 inset">
        <!--// 타이틀 //-->
        <div class="xl-wrapper" style="max-width: 1280px; background: #ffffff">
          <!--// 2. all media, idx-320-767 : Mobile //-->
          <div class="idx-320-767 xl-margin-mobile">
            <div class="bp-title xl-flex-col xl-center">
              <div class="bp-title-h2box xl-flex-row xl-left">
                <!--// 타이틀 //-->
                <div class="bp-title-h2 xl-flex-row xl-left">{{ data.제목 }}</div>
              </div>
              <div class="_v_Padding_" style="height: 8px"></div>
              <div class="blc-title-pbox xl-flex-row xl-left">
                <!--// 설명 //-->
                <div class="blc-title-p xl-flex-row xl-left"><b>인기지원사업</b>&nbsp;내용을 확인해보세요</div>
              </div>
            </div>
          </div>
        </div>

        <!--// 바로가기 //-->
        <div class="xl-wrapper" style="max-width: 1280px; background: #ffffff">
          <!--// 2. all media, idx-320-767 : Mobile //-->
          <div class="idx-320-767 xl-margin-mobile">
            <div class="bp-direction xl-flex-col xl-top" id="_bpDirection_x450">
              <div class="_v_Padding_" style="height: 0px"></div>
              <div class="bp-direction-c1" id="_directeMenuBox">
                <div class="bp-direction-c1-wrapper xl-flex-row xl-left">
                  <div @click="fnGoScrollPosition(11)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C11_x320">사업개요</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(12)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C12_x320">지원분야 및 대상</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(13)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C13_x320">신청절차</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(14)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C14_x320">신청서류</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(15)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C15_x320">신청기간</div>
                  <div class="_h_Padding_" style="width: 10px"></div>

                  <div @click="fnGoScrollPosition(16)" class="bp-direction-c1x xl-flex-row xl-center" id="_bpDirection_C16_x320">주관기관</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="_v_Padding_" style="height: 10px; width: 100%; max-width: 1280px; background: #ffffff"></div>
      </div>
    </div>

    <!--// BODY Layer //-->
    <div class="bp-body-layer" id="_bodyLayer">
      <div class="bp-contents" id="_blContents">
        <div class="blc-center xl-flex-col xl-top">
          <div class="bp-wrapper xl-flex-col xl-top">
            <div class="idx-over-768">
              <div class="_v_Padding_" style="height: 161px; width: 100%; max-width: 1280px; background: #ffffff"></div>
            </div>

            <div class="idx-320-767">
              <div class="_v_Padding_" style="height: 201px; width: 100%; max-width: 1280px; background: #ffffff"></div>
            </div>

            <!--// 주요알림 //-->
            <div class="xl-wrapper">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-info-box xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>

                  <!--// 주요알림 라인 //-->
                  <div class="xl-h-line"></div>

                  <div class="bp-info xl-flex-row xl-left">
                    <div class="_h_Padding_" style="width: 15px"></div>

                    <!--// 아이콘 //-->
                    <div class="bp-info-icon xl-flex-row xl-center">
                      <div class="bpi-icon xl-flex-col xl-top">
                        <div class="bpi-icon-top xl-flex-col xl-bottom">
                          <img class="bpi-img" src="/supportBiz/img/location.png" />
                        </div>
                        <div class="bpi-icon-text xl-flex-col xl-center">{{ data.지역 }}</div>
                      </div>
                      <div class="bpi-icon xl-flex-col xl-top">
                        <div class="bpi-icon-top xl-flex-col xl-bottom">
                          <img class="bpi-img" src="/supportBiz/img/budget.png" />
                        </div>
                        <div class="bpi-icon-text xl-flex-col xl-center">{{ data.금액 }}</div>
                      </div>
                      <div class="bpi-icon xl-flex-col xl-top">
                        <div class="bpi-icon-top xl-flex-col xl-bottom">
                          <img class="bpi-img" src="/supportBiz/img/sector.png" />
                        </div>
                        <div class="bpi-icon-text xl-flex-col xl-center">{{ data.지원분야 }}</div>
                      </div>
                    </div>

                    <!--// 텍스트 //-->
                    <div class="bp-info-text xl-flex-col xl-center">
                      <div class="bpi-text-box xl-flex-row xl-left">
                        <div class="bpi-text-title xl-flex-row xl-center">신청기간</div>
                        <div class="bpi-text-line"></div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                        <div class="bpi-text-note xl-flex-row xl-left">{{ data.신청기간1 }}</div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                      </div>
                      <div class="_v_Padding_" style="height: 5px"></div>
                      <div class="bpi-text-box xl-flex-row xl-left">
                        <div class="bpi-text-title xl-flex-row xl-center">신청서류</div>
                        <div class="bpi-text-line"></div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                        <div class="bpi-text-note xl-flex-row xl-left">{{ data.신청서류1 }}</div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                      </div>
                      <div class="_v_Padding_" style="height: 5px"></div>
                      <div class="bpi-text-box xl-flex-row xl-left">
                        <div class="bpi-text-title xl-flex-row xl-center">주관기관</div>
                        <div class="bpi-text-line"></div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                        <div class="bpi-text-note xl-flex-row xl-left">{{ data.주관기관 }}</div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                      </div>
                    </div>

                    <div class="_h_Padding_" style="width: 15px"></div>
                  </div>

                  <div class="xl-h-line"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-info-box-mobile xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 20px"></div>

                  <!--// 제목상단 라인 //-->
                  <div class="xl-h-line"></div>

                  <div class="bp-info xl-flex-col xl-top">
                    <!--// 아이콘 //-->
                    <div class="bp-info-icon-mobile xl-flex-row xl-center">
                      <div class="bpi-icon xl-flex-col xl-top">
                        <div class="bpi-icon-top xl-flex-col xl-bottom">
                          <img class="bpi-img" src="/supportBiz/img/location.png" />
                        </div>
                        <div class="bpi-icon-text xl-flex-col xl-center">{{ data.지역 }}</div>
                      </div>
                      <div class="bpi-icon xl-flex-col xl-top">
                        <div class="bpi-icon-top xl-flex-col xl-bottom">
                          <img class="bpi-img" src="/supportBiz/img/budget.png" />
                        </div>
                        <div class="bpi-icon-text xl-flex-col xl-center">{{ data.금액 }}</div>
                      </div>
                      <div class="bpi-icon xl-flex-col xl-top">
                        <div class="bpi-icon-top xl-flex-col xl-bottom">
                          <img class="bpi-img" src="/supportBiz/img/sector.png" />
                        </div>
                        <div class="bpi-icon-text xl-flex-col xl-center">{{ data.지원분야 }}</div>
                      </div>
                    </div>

                    <!--// 텍스트 //-->
                    <div class="bp-info-text-mobile xl-flex-col xl-center">
                      <div class="bpi-text-box xl-flex-row xl-left">
                        <div class="bpi-text-title xl-flex-row xl-center">신청기간</div>
                        <div class="bpi-text-line"></div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                        <div class="bpi-text-note xl-flex-row xl-left">{{ data.신청기간1 }}</div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                      </div>
                      <div class="_v_Padding_" style="height: 5px"></div>
                      <div class="bpi-text-box xl-flex-row xl-left">
                        <div class="bpi-text-title xl-flex-row xl-center">신청서류</div>
                        <div class="bpi-text-line"></div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                        <div class="bpi-text-note xl-flex-row xl-left">{{ data.신청서류1 }}</div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                      </div>
                      <div class="_v_Padding_" style="height: 5px"></div>
                      <div class="bpi-text-box xl-flex-row xl-left">
                        <div class="bpi-text-title xl-flex-row xl-center">주관기관</div>
                        <div class="bpi-text-line"></div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                        <div class="bpi-text-note xl-flex-row xl-left">{{ data.주관기관 }}</div>
                        <div class="_h_Padding_" style="width: 15px"></div>
                      </div>
                    </div>

                    <div class="_v_Padding_" style="height: 20px"></div>
                  </div>

                  <div class="xl-h-line"></div>
                </div>
              </div>
            </div>

            <!--// 바로상담 신청하기 //-->
            <div class="xl-wrapper">
              <!--// 1. all media, all size //-->
              <div class="xl-margin">
                <div class="bdc-golist-box xl-flex-row xl-center">
                  <a class="bpp-click" @click.prevent="fnClickConsult" href="">
                    <div class="_goList xl-fill01-btn bp-request xl-flex-row xl-center">바로상담 신청하기</div>
                  </a>
                </div>
              </div>
            </div>

            <!--// 배너 1-1 //-->
            <div class="bp-banner-box xl-flex-col xl-center">
              <div class="_v_Padding_" style="height: 20px"></div>
              <img class="bp-banner" :src="data['배너1-1']" />
            </div>

            <!--// 사업개요 //-->
            <div class="xl-wrapper" id="_part01">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>사업개요</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.사업개요"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>사업개요</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.사업개요"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>
            </div>

            <!--// 배너 1-2 //-->
            <div class="bp-banner-box xl-flex-col xl-center">
              <div class="_v_Padding_" style="height: 50px"></div>
              <img class="bp-banner" :src="data['배너1-2']" />
            </div>

            <!--// 지원분야 및 대상 //-->
            <div class="xl-wrapper" id="_part02">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>지원분야 및 대상</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.지원분야및대상"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>지원분야 및 대상</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.지원분야및대상"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>
            </div>

            <!--// 신청절차 //-->
            <div class="xl-wrapper" id="_part03">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>신청절차</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.신청절차"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>신청절차</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.신청절차"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>
            </div>

            <!--// 신청서류 //-->
            <div class="xl-wrapper" id="_part04">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>신청서류</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.신청서류2"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>신청서류</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.신청서류2"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>
            </div>

            <!--// 신청기간 //-->
            <div class="xl-wrapper" id="_part05">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>신청기간</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.신청기간2"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>신청기간</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre" v-html="data.신청기간2"></div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>
            </div>

            <!--// 주관기관 //-->
            <div class="xl-wrapper" id="_part06">
              <!--// 1. all media, 768px ~ : PC + Tablet //-->
              <div class="idx-over-768 xl-margin">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>주관기관</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre">{{ data.주관기관 }}</div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>

              <!--// 2. all media, idx-320-767 : Mobile //-->
              <div class="idx-320-767 xl-margin-mobile">
                <div class="bp-part bp-part01 xl-flex-col xl-top">
                  <div class="_v_Padding_" style="height: 35px"></div>
                  <div class="bp-part-header2 xl-flex-row xl-center">
                    <div class="bpp-tab2 xl-flex-row xl-left">
                      <div class="_h_Padding_" style="width: 30px"></div>
                      <div style="width: 20px; height: 20px">
                        <svg
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          xml:space="preserve">
                          <path
                            style="fill: #ffda79"
                            d="M326.306,511.99H18.731C8.386,511.99,0,503.603,0,493.259V18.741C0,8.396,8.386,0.01,18.731,0.01h474.518c10.345,0,18.731,8.386,18.731,18.731v307.575c0,4.967-1.973,9.731-5.487,13.245L339.551,506.504C336.039,510.016,331.275,511.99,326.306,511.99z" />
                          <g>
                            <path
                              style="fill: #ffd155"
                              d="M493.249,0.01H255.99v511.98h70.316c4.969,0,9.733-1.973,13.245-5.486l166.942-166.943c3.513-3.513,5.487-8.277,5.487-13.245V18.741C511.98,8.396,503.594,0.01,493.249,0.01z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,157.045H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,148.659,404.304,157.045,393.959,157.045z" />
                            <path
                              style="fill: #ffd155"
                              d="M393.959,256.578H118.021c-10.344,0-18.731-8.386-18.731-18.731c0-10.344,8.386-18.731,18.731-18.731H393.96c10.344,0,18.731,8.386,18.731,18.731C412.691,248.192,404.304,256.578,393.959,256.578z" />
                          </g>
                          <g>
                            <path
                              style="fill: #ffc72d"
                              d="M326.304,511.99c-10.312,0-18.727-8.37-18.727-18.731V326.316c0-10.345,8.386-18.731,18.731-18.731h166.942c16.621,0,25.037,20.183,13.244,31.976L339.551,506.504C335.969,510.087,331.177,511.99,326.304,511.99z" />
                            <path style="fill: #ffc72d" d="M393.959,119.583H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,127.97,404.304,119.583,393.959,119.583z" />
                            <path style="fill: #ffc72d" d="M393.959,219.116H255.99v37.462h137.969c10.345,0,18.731-8.386,18.731-18.731C412.69,227.503,404.304,219.116,393.959,219.116z" />
                          </g>
                        </svg>
                      </div>
                      <div class="_h_Padding_" style="width: 10px"></div>
                      <div>주관기관</div>
                    </div>
                    <div class="bpp-tab2-blank"></div>
                  </div>
                  <div class="bp-part-contents2 xl-flex-col xl-top">
                    <div class="_v_Padding_" style="height: 35px"></div>
                    <div class="bpp-contents-pre">{{ data.주관기관 }}</div>
                    <div class="_v_Padding_" style="height: 35px"></div>
                  </div>
                  <div class="bppc-line2"></div>
                </div>
              </div>
            </div>

            <!--// 배너 1-3 //-->
            <div class="bp-banner-box xl-flex-col xl-center">
              <div class="_v_Padding_" style="height: 50px"></div>
              <img class="bp-banner" :src="data['배너1-3']" />
            </div>

            <div class="_v_Padding_" style="height: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serviceMix from "../../../mixins/partner/serviceMix";
export default {
  layout: 'ifs2',
  mixins:[serviceMix],
  computed: {
    isMobile() {
      return this.$store.state.ui.view == 'mobile';
    },
  },
  data() {
    return {
      data: {},
      ixPart01: null,
      ixPart02: null,
      ixPart03: null,
      ixPart04: null,
      ixPart05: null,
      ixPart06: null,
    };
  },

  methods: {
    fnClickConsult() {
      const { pId } = this.$route.query;
      if(pId==='p01'||pId==='p02'||pId==='p03' || pId==='p04'){
        this.mixFnLoan();
      }
      else if(pId==='p05'){
        this.mixFnTax();
      }
    },
    fnEventScrollPosition() {
      let bodyScroll = document.getElementById('_bodyLayer');

      let part01 = document.getElementById('_part01');
      let part02 = document.getElementById('_part02');
      let part03 = document.getElementById('_part03');
      let part04 = document.getElementById('_part04');
      let part05 = document.getElementById('_part05');
      let part06 = document.getElementById('_part06');

      let blPart01 = part01.getBoundingClientRect().top + part01.scrollHeight - 100 - 231;
      let blPart02 = part02.getBoundingClientRect().top + part02.scrollHeight - 100 - 231;
      let blPart03 = part03.getBoundingClientRect().top + part03.scrollHeight - 100 - 231;
      let blPart04 = part04.getBoundingClientRect().top + part04.scrollHeight - 100 - 231;
      let blPart05 = part05.getBoundingClientRect().top + part05.scrollHeight - 100 - 231;
      let blPart06 = part06.getBoundingClientRect().top + part06.scrollHeight - 100 - 231;

      this.ixPart01 = part01.getBoundingClientRect().top - 215;
      this.ixPart02 = part02.getBoundingClientRect().top - 215;
      this.ixPart03 = part03.getBoundingClientRect().top - 215;
      this.ixPart04 = part04.getBoundingClientRect().top - 215;
      this.ixPart05 = part05.getBoundingClientRect().top - 215;
      this.ixPart06 = part06.getBoundingClientRect().top - 215;

      //const elem = document.getElementById("_logConsole_");
      //elem.innerText = blPart01;

      bodyScroll.addEventListener('scroll', () => {
        let bsScrollTop = bodyScroll.scrollTop;
        let ixDirecteMenuBox = document.getElementById('_directeMenuBox');

        //elem.innerText = bsScrollTop;

        let C11_x900 = document.getElementById('_bpDirection_C11_x900');
        let C12_x900 = document.getElementById('_bpDirection_C12_x900');
        let C13_x900 = document.getElementById('_bpDirection_C13_x900');
        let C14_x900 = document.getElementById('_bpDirection_C14_x900');
        let C15_x900 = document.getElementById('_bpDirection_C15_x900');
        let C16_x900 = document.getElementById('_bpDirection_C16_x900');

        if (600 <= bsScrollTop && bsScrollTop < blPart01) {
          C11_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart01 <= bsScrollTop && bsScrollTop < blPart02) {
          C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart02 <= bsScrollTop && bsScrollTop < blPart03) {
          C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart03 <= bsScrollTop && bsScrollTop < blPart04) {
          C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart04 <= bsScrollTop && bsScrollTop < blPart05) {
          C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart05 <= bsScrollTop && bsScrollTop < blPart06) {
          C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        } else {
          C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        }

        let C11_x320 = document.getElementById('_bpDirection_C11_x320');
        let C12_x320 = document.getElementById('_bpDirection_C12_x320');
        let C13_x320 = document.getElementById('_bpDirection_C13_x320');
        let C14_x320 = document.getElementById('_bpDirection_C14_x320');
        let C15_x320 = document.getElementById('_bpDirection_C15_x320');
        let C16_x320 = document.getElementById('_bpDirection_C16_x320');

        if (740 <= bsScrollTop && bsScrollTop < blPart01) {
          ixDirecteMenuBox.scrollTo(0, 0);
          C11_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart01 <= bsScrollTop && bsScrollTop < blPart02) {
          ixDirecteMenuBox.scrollTo(50, 0);
          C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart02 <= bsScrollTop && bsScrollTop < blPart03) {
          ixDirecteMenuBox.scrollTo(100, 0);
          C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart03 <= bsScrollTop && bsScrollTop < blPart04) {
          ixDirecteMenuBox.scrollTo(150, 0);
          C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart04 <= bsScrollTop && bsScrollTop < blPart05) {
          ixDirecteMenuBox.scrollTo(200, 0);
          C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
          C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        } else if (blPart05 <= bsScrollTop && bsScrollTop < blPart06) {
          ixDirecteMenuBox.scrollTo(250, 0);
          C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        } else {
          ixDirecteMenuBox.scrollTo(0, 0);
          C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
          C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        }
      });
    },
    fnGoScrollPosition(idx) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      //PC 1-600, 2-1310, 3-1590, 4-1760, 5-1950, 6-2150
      let bodyScroll = document.getElementById('_bodyLayer');

      let C11_x900 = document.getElementById('_bpDirection_C11_x900');
      let C12_x900 = document.getElementById('_bpDirection_C12_x900');
      let C13_x900 = document.getElementById('_bpDirection_C13_x900');
      let C14_x900 = document.getElementById('_bpDirection_C14_x900');
      let C15_x900 = document.getElementById('_bpDirection_C15_x900');
      let C16_x900 = document.getElementById('_bpDirection_C16_x900');
      let C11_x320 = document.getElementById('_bpDirection_C11_x320');
      let C12_x320 = document.getElementById('_bpDirection_C12_x320');
      let C13_x320 = document.getElementById('_bpDirection_C13_x320');
      let C14_x320 = document.getElementById('_bpDirection_C14_x320');
      let C15_x320 = document.getElementById('_bpDirection_C15_x320');
      let C16_x320 = document.getElementById('_bpDirection_C16_x320');

      //const elem = document.getElementById("_logConsole_");
      //elem.innerText = ixPart01 + "/" + ixPart02 + "/" + ixPart03 + "/" + ixPart04 + "/" + ixPart05 + "/" + ixPart06;

      if (idx == 1) {
        bodyScroll.scrollTop = this.ixPart01;
        C11_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 2) {
        bodyScroll.scrollTop = this.ixPart02;
        C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 3) {
        bodyScroll.scrollTop = this.ixPart03;
        C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 4) {
        bodyScroll.scrollTop = this.ixPart04;
        C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 5) {
        bodyScroll.scrollTop = this.ixPart05;
        C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C16_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 6) {
        bodyScroll.scrollTop = this.ixPart06;
        C11_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x900.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x900.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
      } else if (idx == 11) {
        bodyScroll.scrollTop = this.ixPart01 - 20;
        C11_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 12) {
        bodyScroll.scrollTop = this.ixPart02 - 20;
        C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 13) {
        bodyScroll.scrollTop = this.ixPart03 - 20;
        C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 14) {
        bodyScroll.scrollTop = this.ixPart04 - 20;
        C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 15) {
        bodyScroll.scrollTop = this.ixPart05 - 20;
        C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
        C16_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
      } else if (idx == 16) {
        bodyScroll.scrollTop = this.ixPart06 - 20;
        C11_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C12_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C13_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C14_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C15_x320.classList.replace('bp-direction-c1o', 'bp-direction-c1x');
        C16_x320.classList.replace('bp-direction-c1x', 'bp-direction-c1o');
      }
    },
  },

  mounted() {
    const { pId } = this.$route.query;
    this.$axios
      .$get(`/supportBiz/json/${pId}.json`)
      .then(data => {
        this.data = data;
        setTimeout(() => {
          this.fnEventScrollPosition();
          const p01Click1 = document.querySelectorAll('.p01_click_1');
          if (p01Click1.length > 0) {
            p01Click1[0].addEventListener('click', () => {
              window.open('/supportBiz/doc/(첨부1)융자제외대상 업종_중소벤처기업부 소관 중소기업 정책자금.pdf');
            });
            p01Click1[1].addEventListener('click', () => {
              window.open('/supportBiz/doc/(첨부1)융자제외대상 업종_중소벤처기업부 소관 중소기업 정책자금.pdf');
            });
          }
          const p01Click2 = document.querySelectorAll('.p01_click_2');
          if (p01Click2.length > 0) {
            p01Click2[0].addEventListener('click', () => {
              window.open('/supportBiz/doc/(첨부2)업종별 융자제한 부채비율_중소벤처기업부 소관 중소기업 정책자금.pdf');
            });
            p01Click2[1].addEventListener('click', () => {
              window.open('/supportBiz/doc/(첨부2)업종별 융자제한 부채비율_중소벤처기업부 소관 중소기업 정책자금.pdf');
            });
          }
        }, 50);
      })
      .catch(err => {
        console.error(err);
      });
  },
};
</script>

<style></style>
