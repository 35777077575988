<template>
  <div class="wrap_paper p_404">
    <div class="content">
      <img :src="$store.state.ui.url + '/img/common/img_404.jpg?v=01'" class="img" alt="404에러 이미지" />
      <h1>{{error.statusCode}}죄송합니다. <br/>현재 찾을수 없는 페이지를 요청 하셨습니다.</h1>
      <p>
        존재하지 않는 주소를 입력하셨거나, <br/>
        요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다. <br/>
        궁금한 점이 있으시면 언제든 고객센터를 통해 <br/>
        문의해 주시기 바랍니다. 감사합니다.
      </p>
      <nuxt-link :to="$goHome()" class="btn">홈으로</nuxt-link>
    </div>
  </div>
</template>

<script>

export default {
  layout:"none",
  props: ['error'],
};
</script>
