import {ALL_OK_LOAN} from '../../constants/apiServicePath';
import { generateQueryParameter } from '~/utils/HttpUtils';

function saveLookupForm(axios, form){//사용
    return axios.post(`${ALL_OK_LOAN}/customer/lookup`, form)
        .then(result => result.data)
}

function fetchLoadPrdc(axios, form){//사용
    return axios.get('/apiCmnAo/loan-prdc/match'+'?'+generateQueryParameter(form))
        .then(result => result.data);
}

function fetchLoanPrdcRcmd(axios, form){//사용
    return axios.get('/apiCmnAo/loan-prdc/rcmd'+'?'+generateQueryParameter(form))
        .then(result => result.data);
}

function fetchFinance(axios, form){//사용
    return axios.get('/apiCmnAo/loan-prdc/finc/match'+'?'+generateQueryParameter(form))
        .then(result => result.data);
}

function fetchAnalyze(axios, form) {//사용
    return axios.get('/apiCmnAo/loan-prdc/patn'+'?'+generateQueryParameter(form))
        .then(result => result.data);
}

function saveConsultingReq(axios, lookupId, payload){
    return axios.post(`${ALL_OK_LOAN}/customer/lookup/${lookupId}/consulting`, payload)
        .then(res => res.data)
}

export default {
    saveLookupForm,
    saveConsultingReq,
    fetchLoadPrdc,
    fetchLoanPrdcRcmd,
    fetchFinance,
    fetchAnalyze,
}
