<template>
  <nav :class="{'open': this.$store.state.ui.menuState == true}" style="padding-top: 45px;">
    <ul class="menu">
      <li v-if='menuDisplay.tax' class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='세금환급'}" @click="()=>{
          this.selectedDep1 = '세금환급'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/tax')
        }">세금환급</button>
      </li>
      <li v-if='menuDisplay.insurance' class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='4대보험절감'}" @click="()=>{
          this.selectedDep1 = '4대보험절감'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/insured')
        }">4대보험절감</button>
      </li>
<!--      <li v-if='menuDisplay.labor' class="dep1">-->
<!--        <button class="dep1_menu" :class="{on: selectedDep1 =='고용지원금'}" @click="()=>{-->
<!--          this.selectedDep1 = '고용지원금'-->
<!--          this.$store.dispatch('ui/setMenuState', false);-->
<!--          this.$router.push('/mypage/grant')-->
<!--        }">고용지원금</button>-->
<!--      </li>-->
<!--      <li v-if="menuDisplay.loan" class="dep1">-->
<!--        <button class="dep1_menu" :class="{on: selectedDep1 =='기업대출'}" @click="()=>{-->
<!--          this.selectedDep1 = '기업대출'-->
<!--          this.$store.dispatch('ui/setMenuState', false);-->
<!--          this.$router.push('/mypage/loan')-->
<!--          }">기업대출</button>-->
<!--      </li>-->
      <li v-if="laborMenuUseAble && menuDisplay.labor" class="dep1" >
        <button class="dep1_menu" :class="{on: selectedDep1 =='온라인노무'}" @click="()=>{
          this.selectedDep1 = '온라인노무'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/labor')
        }">온라인노무</button>
      </li>
      <li v-if='menuDisplay.support' class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='지원사업'}" @click="()=>{
          this.selectedDep1 = '지원사업'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/support')
        }">지원사업</button>
      </li>
      <li v-if='menuDisplay.articleOnIncorporation' class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='정관개정'}" @click="()=>{
          this.selectedDep1 = '정관개정'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/articleOfIncorporation/')
        }">정관개정</button>
      </li>
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='내정보'}" @click="()=>{
          this.selectedDep1 = '내정보'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/myInfo')
        }">내정보</button>
      </li>
    </ul>
  </nav>
</template>

<script>
import ComboBox from "~/components/common/popup/ComboBox.vue";
import authMix from "~/mixins/authMix";
export default {
  mixins:[authMix],
  components: {
    ComboBox,
  },
  props: ["classNm"],
  created() {
    this.laborMenuUseAble = this.$store.state.env.MYPAGE_LABOR_USEABLE ? true : false;
    //
    this.fnDisplayMenuSetting();
  },
  data() {
    return {
      selectedDep1: '세금환급',
      compNm: null,
      laborMenuUseAble:false,
      menuDisplay:{
        tax:true,
        insurance:true,
        labor:true,
        loan:true,
        support:true,
        fund:true,
        articleOnIncorporation:true,
      }
    };
  },
  computed: {
    myComp() {
      return this.$store.getters['userStore/getMyComp'];
    },
    compList() {
      if(this.$store.state.userStore.myCompList){
        if(this.$store.state.userStore.myCompList.length>0){
          return this.$store.state.userStore.myCompList
        }
      }
      return []
    },
    compDataList() {
      return this.compList.map(c=>{
        if(c.bizNo === this.myComp.bizNo){
          return {
            name: c.compNm,
            value: c.bizNo,
            tax: c.tax,
            insurance: c.insurance,
            employ: c.employ,
            select: true
          }
        }
        return {
          name: c.compNm,
          value: c.bizNo,
          tax: c.tax,
          insurance: c.insurance,
          employ: c.employ
        }
      })
    }
  },
  watch: {
    $route(to, from) {
      if(to.path != from.path) {
        if(this.$route.path=='/mypage/insured'){
          this.selectedDep1 = '4대보험절감'
        } else if(this.$route.path=='/mypage/grant'){
          this.selectedDep1 = '고용지원금'
        } else if(this.$route.path=='/mypage/tax'){
          this.selectedDep1 = '세금환급'
        } else if (this.$route.path == '/mypage/support') {
          this.selectedDep1 = '지원사업';
        } else if(this.$route.path=='/mypage/labor'){
          this.selectedDep1 = '온라인노무'
        } else if(this.$route.path=='/mypage/myInfo'){
          this.selectedDep1 = '내정보'
        } else if(this.$route.path=='/mypage/loan'){
          this.selectedDep1 = '기업대출'
        }
      }
    }
  },
  methods:{
    fnDisplayMenuSetting(){
      let partnerInfo = this.$getPartnerInfo();
      if ( partnerInfo && partnerInfo.menuInfo && partnerInfo.menuInfo.ignoreMenuDisplay ) {
        ignoreMenuDisplay: ['loan','support','articleOnIncorporation'];
        partnerInfo.menuInfo.ignoreMenuDisplay.forEach(menu => {
          this.menuDisplay[menu]=false;
        })
      }
    },
    fnChange(item){
      this.$nextTick(()=>{
        this.value = item.value
        this.$auth_SaveBizNo(item.value);
        this.$store.commit('servicesStore/UPDATE_BIZNO', item.value)
      })
    },
    fnTogglePop() {
      this.Combobox = !this.Combobox;
    },
    fnMenuOpen(e) {
      const target = e.target.parentNode;
      const onState = target.classList.contains('open') ? true : false;
      if (!onState)
        target.classList.add('open');
      else
        target.classList.remove('open');
    },
    fnCloseNav(){
      this.$store.dispatch('ui/setMenuState', false);
    },
    fnAlert(msg) {
      let data = {
        state: true,
        type: 'alert',
        msg: `${msg}`,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopupAlertData', data);
    },
  },
  async mounted(){
    if(this.$route.query&&this.$route.query.infoyou){
      this.selectedDep1 = '내정보'
    }
    if(this.$route.path=='/mypage/insured'){
      this.selectedDep1 = '4대보험절감'
    } else if(this.$route.path=='/mypage/grant'){
      this.selectedDep1 = '고용지원금'
    } else if(this.$route.path=='/mypage/tax'){
      this.selectedDep1 = '세금환급'
    } else if (this.$route.path == '/mypage/support') {
      this.selectedDep1 = '지원사업';
    } else if(this.$route.path=='/mypage/labor'){
      this.selectedDep1 = '온라인노무'
    } else if(this.$route.path=='/mypage/myInfo'){
      this.selectedDep1 = '내정보'
    } else if(this.$route.path=='/mypage/loan'){
      this.selectedDep1 = '기업대출'
    }
  }
};
</script>
