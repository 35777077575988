// nuxt axios 공통 설정
// nuxt.config.js plugins:[]에 추가시킴
export default function ({ $axios, redirect, req, $getAccessToken, $getRefreshToken, $auth_token_update, $clearToken }) {

  $axios.onRequest((config) => {
    let accessToken = $getAccessToken();
    if( accessToken !== null && accessToken !== undefined ) {
      config.headers.Authorization = 'Bearer '+ accessToken;
    }
  });

  // axios 응답 인터셉트
  $axios.interceptors.response.use(null, (error) => {
    const {config, response:{ status, data }} = error;
    const originalRequest = config;
    // 토큰 만료시간이 지난 경우
    if (config && status === 401) {
      const refreshToken = $getRefreshToken();
      if( refreshToken ){
        return updateToken($axios, $getRefreshToken())
            .then(res => {
              if(res.data.success){
                const newToken = res.data.contents
                if(newToken){
                  $auth_token_update(newToken);
                  return $axios.request(originalRequest);
                }
              }
              return Promise.reject(error);
            })
            .catch(error=>{
              return Promise.reject(error);
            });
      }else{
        //return Promise.reject(error);
        return Promise.resolve(data);
      }
    }else{
      return Promise.reject(error);
    }
  });
}

// 리프레시 토큰으로 억세스토큰을 업데이트 한다.
const updateToken = (axios, refreshToken) => {
  return axios.get(`/apiAuth/token/refresh?refreshToken=${refreshToken}`);
}
