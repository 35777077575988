<template>
  <div class="app ifs">
    <Header style="z-index: 200;"/>
    <Menu class="nav_mobile"/>
    <nuxt/>
    <Footer style="background-color: #202020;"/>
    <QuickMenu />
    <transition name="lending">
      <Alert v-if="$store.state.ui.popupAlertData.state === true" key="Alert"/>
      <BizNum v-if="$store.state.ui.popupBizNum === true" key="BizNum"/>
      <CompanySelect v-if="$store.state.ui.popupCompanySelect === true" key="CompanySelect" />
      <LandingPopup v-if="$store.state.ui.landingPopup"
                    :id="$store.state.ui.landingPopup.id"
                    :src="$store.state.ui.landingPopup.src"
                    key="landingPopup"/>
    </transition>
  </div>
</template>

<script>
import Header from "../components/ifs/layouts/Header2";
import Footer from "../components/ifs/layouts/Footer3";
import Alert from "../components/common/Alert";
import BizNum from "../components/ifs/popup/BizNum";
import CompanySelect from "../components/common/popup/CompanySelect";
import Setting from "../mixins/Setting";
import Menu from "../components/ifs/layouts/Menu";
import QuickMenu from "../components/ifs/layouts/QuickMenu";
import Mainbiz from "../components/common/popup/ifs/Mainbiz";
import LandingPopup from "../components/common/popup/ifs/LandingPopup";
import * as PartnerInfo from '../constants/partnerInfo';
export default {
  name: "partnerLayout",
  mixins:[Setting],
  components: {
    Header,
    Footer,
    BizNum,
    Alert,
    Menu,
    CompanySelect,
    QuickMenu,
    // Mainbiz,
    LandingPopup
  },
  computed:{
    myCompList(){
      return this.$store.state.userStore.myCompList;
    }
  },
  created() {
    //
    this.$savePartnerInfo(this.$route);
    //
    const landing = this.$route.params.landing;
    let allowedLanding = false;
    Object.keys(PartnerInfo).forEach(key => {
      const _path = PartnerInfo[key].path;
      if ( _path ) {
        if ( PartnerInfo[key].path.indexOf(landing) > 0 ) {
          allowedLanding = true;
        }
      }
    })
    if ( !allowedLanding ) {
      this.$router.replace("/d3/");
    }
  },
  mounted() {
    this.$auth_RestoreBizNo();
  },
  methods:{
    checkMultipleMyCompList(compList){
      const selectedMyCompBizNo = this.$auth_MyBizNo();
      if( compList && !selectedMyCompBizNo ){
        // 보유하는 회사가 1개
        if(compList.length == 1){
          const bizNo = compList[0].bizNo;
          this.$auth_SaveBizNo(bizNo);
        }
        // 보유하는 회사가 1개 이상
        else if(compList.length > 1){
          // 회사 선택 팝업을 연다
          if( !this.$store.state.ui.popupCompanySelect ){
            this.$store.commit('ui/UPDATE_STATE', {popupCompanySelect:true})
          }
        }
      }
    }
  },
  watch:{
    myCompList(compList){
      this.checkMultipleMyCompList(compList);
    }
  }
}
</script>

<style scoped>
div.app {
  padding-top: 80px;
}

@media screen and (max-width: 770px){
  div.app {
    padding-top: 60px;
  }
}
</style>

