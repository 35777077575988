<template>
  <aside id="quick_menu">
    <ul>
      <li>
        <button class="cnslt_btn" @click="mixFnGoConsultant">
          컨설턴트<br/><span>전용서비스</span>
        </button>
      </li>
      <li>
        <button class="tax_btn" @click="mixDownTaxGuide">세금환급<br/><span>이용가이드</span></button>
      </li>
      <li>
        <button class="ins_btn" @click="mixDownInsuranceGuide">보험료절감<br/><span>이용가이드</span></button>
      </li>
      <li>
        <button class="labor_btn" @click="mixLaborMng">노무관리<br/><span>전용서비스</span></button>
      </li>
    </ul>
    <button id="top_btn" @click="mixGoTop">TOP</button>
  </aside>
</template>

<script>
import serviceMix from "../../../mixins/partner/serviceMix";
export default {
  name:'QuickMenu',
  mixins:[serviceMix],

}
</script>

<style scoped>
#quick_menu{
  position: fixed;
  right: 63px;
  top: 90px;
  width: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
#quick_menu ul{
  background-color: #FFD943;
  padding: 30px 11px;
  border-radius: 43px;
  margin-bottom: 30px;
  width: 100%;
}
#quick_menu li{
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #00000026;
  text-align: center;
}
#quick_menu li:first-child{
  padding-top: 0px;
}
#quick_menu li:last-child{
  padding-bottom: 0px;
  border-bottom: none;
}
#quick_menu li button{
  padding-top: 42px;
  background-position: top center;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 700;
  color: #211715;
}
#quick_menu li button span{
  font-size: 12px;
}
#quick_menu li .cnslt_btn{
  background-image: url("/img/icon_handshake.png");
}
#quick_menu li .tax_btn{
  background-image: url("/img/icon_book.png");
}
#quick_menu li .ins_btn{
  background-image: url("/img/icon_book.png");
}
#quick_menu li .labor_btn{
  background-image: url("/img/icon_labor.png");
}
#top_btn{
  width: 70px;
  height: 70px;
  padding-top: 25px;
  border-radius: 35px;
  background-color: #262626;
  color: white;
  background-image: url("/img/icon_top_arrow.png");
  background-repeat: no-repeat;
  background-position: 50% 35%;
}

@media screen and (max-width: 1300px){
  #quick_menu{
    top: unset;
    bottom: 100px;
    right: 30px;
    width: 60px;
  }
  #quick_menu ul{
    padding: 20px 5px;
    border-radius: 30px;
  }
  #quick_menu li button{
    font-size: 11px;
  }
  #quick_menu li button span{
    font-size: 9px;
  }
  #quick_menu li button{
    width: 100%;
    background-size: 80%;
    padding-top: 34px;
  }
  #top_btn {
    width: 50px;
    height: 50px;
    padding-top: 18px;
    border-radius: 25px;
    font-size: 12px;
    background-size: 25%;
    box-shadow: 0px 0px 3px;
  }
}
</style>
