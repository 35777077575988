export const state = () => ({
    certList:[],
    cert:null,
    certType:null,
    comwelCert:null,
    hometaxCert:null,
});

export const mutations = {
    INIT_CERT(state){
        state.certList=[];
        state.cert=null;
        state.certType=null;
        state.comwelCert=null;
        state.hometaxCert=null;
    },
    UPDATE_CERT_LIST(state, payload){
        state.certList = payload
    },
    UPDATE_CERT(state, payload){
        if( state.certType ){
            if ( state.certType === 'comwel'){
                state.comwelCert = payload;
            }
            else if ( state.certType === 'hometax') {
                state.hometaxCert = payload;
            }
        }else{
            state.cert = payload;
        }
    },
    UPDATE_CERT_TYPE(state, payload){
        state.certType = payload;
    },
  };