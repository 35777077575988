<template>
  <!-- <div class="app main" oncontextmenu="return false" ondragstart="return false" onselectstart="return false"> -->
  <div class="app none">
    <nuxt/>
    <transition name="fade">
      <Alert v-if="$store.state.ui.popupAlertData.state === true"/>
      <Basic v-if="$store.state.ui.popupBasicData.state === true"/>
      <Loading v-if="$store.state.ui.loadingState === true"/>
    </transition>
    <Footer/>
  </div>
</template>

<script>
import Setting from "~/mixins/Setting";
import Alert from "~/components/common/Alert";
import Basic from "~/components/common/popup/Basic";
import Loading from "~/components/ui/popup/Loading";


export default {
  name: "none_layout",
  components: {
    Alert,
    Basic,
    Loading
  },
  mixins: [Setting],
  // head() {
  //   return {
  //     title: "Main | UI | 다된다",
  //   };
  // },
  methods: {

  }
};
</script>
