export const state = () => ({
  _rate: {
    nationalPensionRate:4.5, // 국민연금보험료 9%
    healthRate:3.545,// 건강보험료 (7.09%)
    nursingRate:12.81, // 장기요양보험료 (12.81%)
    hireRate:0.9,// 고용보험료 0.9
    industRate: 1, // 산재보험료-회사 1
    corpTaxRate:22, // 법인세 절감율
    pensionInsRate:8.90, // 퇴직연금보험료 절감율
  },
  form:null,
  calcResult:{},
});

export const mutations = {
  UPDATE_FORM(state, payload){
    state.form = payload;
  },
  UPDATE_CALC_RESULT(state, payload){
    state.calcResult = payload;
  },
};

export const actions = {
  async calc({state, commit}, form){
    //
    const rate = state._rate;
    // input
    const { numOfEmploys, fundAmount } = form;
    const _totalFund = numOfEmploys * fundAmount;

    // 회사절감분 계산
    const _corpNationalPensionReduce = fundAmount * rate.nationalPensionRate / 100;
    const _corpHealthReduce = fundAmount * rate.healthRate / 100;
    const _corpNursingReduce = _corpHealthReduce * rate.nursingRate / 100;
    const _coprHireReduce = fundAmount * rate.hireRate / 100;
    const _industReduce = fundAmount * rate.industRate / 100;
    let _corpInsTotalReduce = _corpNationalPensionReduce
                                + _corpHealthReduce
                                + _corpNursingReduce
                                + _coprHireReduce
                                + _industReduce; // 4대보험료 연간 절감총액(회사)
    _corpInsTotalReduce = Math.round(_corpInsTotalReduce);

    let corpTaxReduce = _totalFund * rate.corpTaxRate / 100; // 법인세 절감
    let corpInsReduce = numOfEmploys * _corpInsTotalReduce; // 4대보험료(회사)
    let pensionInsReduce = _totalFund * rate.pensionInsRate / 100; // 퇴직연금보험료 절감
    let corpReduceTotal = corpTaxReduce + corpInsReduce + pensionInsReduce; // 회사 절감분 토탚

    // 임직원 절감분 계산
    const _incomeTaxConst = 110000;
    const _empNationalPensionReduce = fundAmount * rate.nationalPensionRate / 100;
    const _empHealthReduce = fundAmount * rate.healthRate / 100;
    const _empNursingReduce = _empHealthReduce * rate.nursingRate / 100;
    const _empHireReduce = fundAmount * rate.hireRate / 100;
    let _empInsTotalReduce = _empNationalPensionReduce
                               + _empHealthReduce
                               + _empNursingReduce
                               + _empHireReduce; // 4대보험료 연간 절감총액(근로자)
    _empInsTotalReduce = Math.round(_empInsTotalReduce);

    let incomeTaxReduce = fundAmount / 1000000 * _incomeTaxConst * numOfEmploys; // 소득세(근로자분)
    let empInsReduce =  _empInsTotalReduce * numOfEmploys; // 4대보험료(근로자분)
    let empReduceTotal = incomeTaxReduce + empInsReduce;

    commit('UPDATE_FORM', form);
    commit('UPDATE_CALC_RESULT', {
      corpTaxReduce, corpInsReduce, pensionInsReduce, corpReduceTotal,
      incomeTaxReduce, empInsReduce, empReduceTotal
    });
    return true;
  }
};
