import employService from "../service/allOkMng/employService";
import taxService from "../service/allOkMng/taxService";
import insuranceService from "../service/allOkMng/insuranceService";

const DEFAULT_FORM = {
  name: null
}

const DEFAULT_TAXDATA = {
  success: false,
  tax: {}
}

export const state = () => ({
  bizNo: null,
  form:{...DEFAULT_FORM},
  userNo: null,
  userNm: null,
  matchingResult: null,
  employSuccess: false,
  certList:null,
  selectedCert:null,
  taxData:{...DEFAULT_TAXDATA},
  insuranceSuccess: false
});

export const mutations = {
  UPDATE_BIZNO(state, payload){
    state.bizNo = payload
  },
  UPDATE_USER_FORM(state,payload){
    state.form = payload
  },
  UPDATE_USER(state, userNo){
    state.userNo = userNo
  },
  UPDATE_USER_NM(state, userNm){
    state.userNm = userNm
  },
  UPDATE_TAXDATA(state, payload){
    state.taxData = payload
  },
  RESET_TAXDATA(state){
    state.taxData = {...DEFAULT_TAXDATA}
  },
  UPDATE_INSURANCE_SUCCESS(state, payload){
    state.insuranceSuccess = payload
  },
  // Deprecated
  UPDATE_MATCHING(state, payload){
    state.matchingResult = payload;
  },
  // Deprecated
  UPDATE_EMPLOY_SUCCESS(state, payload){
    state.employSuccess = payload;
  },
  UPDATE_CERTLIST(state, payload){
    state.certList = payload;
  },
  UPDATE_SELECTED_CERT(state, payload){
    state.selectedCert = payload;
  },
  CLEAR(state){
    state.employAvailTypes=null;
    state.loginPath='confirm';
    state.insuranceTab='null';
    state.form = {...DEFAULT_FORM};
  },
  CLEAR2(state){
    state.employAvailTypes=null;
    state.loginPath='confirm';
    state.insuranceTab='null';
    state.userNo=null;
    state.userNm=null;
    state.form = {...DEFAULT_FORM};
  },
};

export const actions = {
  async fetchTaxCreditList({state,commit}, bizNo){
    const {success, contents} = await taxService.fetchSimpleCalculationResult(this.$axios, bizNo);
    if(success && contents) {
      const taxData = {
        success: success,
        tax: JSON.parse(contents)
      }
      commit('UPDATE_TAXDATA', taxData)
    } else{
      commit('RESET_TAXDATA')
    }
  },


  async fetchInsuranceResult({state, commit}, bizNo) {
    const result = await insuranceService.getResult(this.$axios, bizNo);
    if (result.success) {
      commit('UPDATE_MATCHING', result.contents);
      commit('UPDATE_INSURANCE_SUCCESS', result.success)
    } else {
      commit('UPDATE_MATCHING', null);
      commit('UPDATE_INSURANCE_SUCCESS', false)
    }
    return result;
  },
  // deprecated
  async fetchEmpolyResult({state, commit}, bizNo) {
    const result = await employService.employMatchingData(this.$axios, bizNo);
    if(result.success) {
      commit('UPDATE_MATCHING', result.contents);
      commit('UPDATE_EMPLOY_SUCCESS', result.success);
    }else {
      commit('UPDATE_MATCHING', null);
      commit('UPDATE_EMPLOY_SUCCESS', false)
    }
    return result
  },
  // Deprecated
  async fetchEmpolyResult2({state, commit}, bizNo) {
    const result = await employService.employMatchingData2(this.$axios, bizNo);
    if(result.success) {
      commit('UPDATE_MATCHING', result.contents);
      commit('UPDATE_EMPLOY_SUCCESS', result.success);
    }
    return result
  }
};
