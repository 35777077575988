<template>
  <nav :class="{'open': this.$store.state.ui.menuState == true}" style="padding-top: 45px;">
    <ul class="menu">
      <li class="dep1" v-if="laborMenuUseAble">
        <button class="dep1_menu" :class="{on: selectedDep1 =='온라인노무'}" @click="()=>{
          this.selectedDep1 = '온라인노무'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('../labor/')
        }">온라인노무</button>
      </li>
    </ul>
  </nav>
</template>

<script>
import ComboBox from "~/components/common/popup/ComboBox.vue";
import authMix from "~/mixins/authMix";
export default {
  mixins:[authMix],
  components: {
    ComboBox,
  },
  props: ["classNm"],
  created() {
    this.laborMenuUseAble = this.$store.state.env.MYPAGE_LABOR_USEABLE ? true : false;
  },
  data() {
    return {
      selectedDep1: '온라인노무',
      compNm: null,
      laborMenuUseAble:false,
    };
  },
  watch: {
    $route(to, from) {
      if(to.path != from.path) {
        if(this.$route.path.indexOf('/labor')){
          this.selectedDep1 = '온라인노무'
        }
      }
    }
  },
  methods:{
  },
};
</script>
