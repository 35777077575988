<template>
  <div style="margin: 0; padding: 0" class="ifs">
    <Header2 />
    <Menu class="nav_mobile"/>
    <nuxt />
    <Footer2 v-if="this.$route.path!=='/services/intro/insurance'&&this.$route.path!=='/mainbiz/insurance/intro'" style="background-color: #202020;" />
    <QuickMenu />
    <transition name="lending">
      <Alert v-if="$store.state.ui.popupAlertData.state === true" key="Alert" />
      <BizNum v-if="$store.state.ui.popupBizNum === true" key="BizNum" />
      <BizNum2 v-if="$store.state.ui.popupBizNum2 === true" key="BizNum2" />
      <CompanySelect v-if="$store.state.ui.popupCompanySelect === true" key="CompanySelect" />
      <Mainbiz key="mainBiz" v-if="$store.state.ui.popupMainBiz === true" />
    </transition>
  </div>
</template>

<script>
import Footer2 from '../components/ifs/layouts/Footer3.vue';
import Header2 from '../components/ifs/layouts/Header2.vue';
import Alert from '../components/common/Alert';
import BizNum from '../components/ifs/popup/BizNum';
import BizNum2 from '../components/ifs/popup/BizNum2';
import CompanySelect from "../components/common/popup/CompanySelect";
import QuickMenu from "../components/ifs/layouts/QuickMenu";
import Setting from '../mixins/Setting';
import Menu from "../components/ifs/layouts/Menu";
import Mainbiz from "../components/common/popup/ifs/Mainbiz";
export default {
  components: {
    Header2,
    Footer2,
    BizNum,
    BizNum2,
    Alert,
    CompanySelect,
    QuickMenu,
    Menu,
    Mainbiz,
  },
  mixins: [Setting],
  computed:{
    myCompList(){
      return this.$store.state.userStore.myCompList;
    }
  },
  beforeCreate() {
    //this.$savePartnerInfo(this.$route);
    if(process.client){
      // KTCB에서 넘어온 경우 처리
      if(!this.$nuxt.context.from && document.referrer === 'https://biz.k-tcb.com/'){
        this.$savePartnerInfoByLogin('ADM0001');
      }
      else if ( this.$route.path.indexOf('/mainbiz' ) >=0) {
        this.$savePartnerInfo(this.$route);
      }
      else {
        const partnerInfo = this.$getPartnerInfo();
        if ( ! partnerInfo ) {
          this.$savePartnerInfo(this.$route);
        }
      }
    }
  },
  created() {
    //this.$savePartnerInfo(this.$route);
  },
  beforeMount() {
    this.$auth_RestoreBizNo();
  },
  mounted() {
    document.body.className = 'body1';
  },
  destroyed() {
    document.body.className = '';
  },
  methods:{
    checkMultipleMyCompList(compList){
      const selectedMyCompBizNo = this.$auth_MyBizNo();
      if( compList && !selectedMyCompBizNo ){
        // 보유하는 회사가 1개
        if(compList.length == 1){
          const bizNo = compList[0].bizNo;
          this.$auth_SaveBizNo(bizNo);
        }
        // 보유하는 회사가 1개 이상
        else if(compList.length > 1){
          // 회사 선택 팝업을 연다
          if( !this.$store.state.ui.popupCompanySelect ){
            this.$store.commit('ui/UPDATE_STATE', {popupCompanySelect:true})
          }
        }
      }
    }
  },
  watch:{
    myCompList(compList){
      this.checkMultipleMyCompList(compList);
    }
  }
};
</script>

<style>
@import '~/static/supportBiz/css/sbCommonLayout.css';
@import '~/static/supportBiz/css/sbFont.css';
@import '~/static/supportBiz/css/sbTopMenuLayout.css';
@import '~/static/supportBiz/css/sbListLayout.css';
@import '~/static/supportBiz/css/sbIndex.css';
@import '~/static/supportBiz/css/sbDetailLayout.css';
@import '~/static/supportBiz/css/sbPopularLayout.css';
</style>
