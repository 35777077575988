import {API, ALL_OK_MNG_V3} from './apiPrefix';

const fetchMe = (axios, userId) => {
    return axios.get(`${ALL_OK_MNG_V3}/users/${userId}`)
        .then( res => {
            return res.data
        })
        .catch( err=> {
            return {
                success:false,
            }
        });
}

const save = (axios, payload) => {
    return axios.post(ALL_OK_MNG_V3+'/users?rand='+Math.random(), payload).then( res => res.data);
}

const update = (axios, userId, payload) => {
    return axios.put(ALL_OK_MNG_V3+'/users/'+userId+'?rand='+Math.random(), payload).then( res => res.data);
}

function saveCustomer(axios, userId, payload) {
    return axios.post(`${ALL_OK_MNG_V3}/users/${userId}/customers`, payload)
        .then(res => res.data)
        .catch(res => {success:false})
}

function fetchCustomer(axios, userId, customerId){
    return axios.get(`${ALL_OK_MNG_V3}/users/${userId}/customers/${customerId}`)
        .then(res => res.data)
        .catch(res => {success:false})
}

function updateCustomer(axios, userId, customerId, payload){
    return axios.put(`${ALL_OK_MNG_V3}/users/${userId}/customers/${customerId}`, payload)
        .then(res => res.data)
        .catch(res => {success:false})
}

// function sendRegCompleteMail(axios, payload, partnerInfo){
//     return axios.post(`${API}/email`, payload)
//         .then(res => res.data)
//         .catch(res => {success:false})
// }

function sendRegCompleteMail(axios, accessType, userData, companyInfo, partnerInfo){
    //
    const sendMailInfo = partnerInfo.sendMail;
    if ( !sendMailInfo
        || !sendMailInfo.allowedAccessType
        || !sendMailInfo.templateType ) {
        return false;
    }

    //
    if ( !sendMailInfo.allowedAccessType.includes(accessType) ) {
        return false;
    }

    //
    if ( !userData.email
         || !companyInfo
         || !partnerInfo ) {
        return false;
    }

    //
    const email = userData.email;
    const customer = companyInfo;
    const partner = partnerInfo;
    const payload = {
        toEmailAddress:email,
        partner:{
            companyName:partner.serviceName,
            subPath:partner.path,
            logoImg:partner.logoImgPath,
            templateType: sendMailInfo.templateType,
            conjunctions: sendMailInfo.conjunctions,
        },
        customer:{
            companyName:customer.compNm
        }
    }

    return axios.post(`${API}/email`, payload)
        .then(res => res.data)
        .catch(res => {success:false})
}

export default {
    fetchMe, // 회원정보 가져오기
    save, // 회원정보 추가
    update, // 회원정보 업데이트
    saveCustomer, // 회원의 사업자 추가
    fetchCustomer, // 회원의 사업자 상세정보
    updateCustomer, // 회원의 사압자 정보 수정
    sendRegCompleteMail, // 회원가입 완료 메일
}
