import jsonwebtoken from 'jsonwebtoken';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_NO_TOKEN_KEY = 'user_no';
const USER_NM_TOKEN_KEY = 'user_nm';

const validAccessToken = (isClient) => {
  if(isClient){
    const accessToken = getAccessToken(isClient);
    return accessToken ? true : false;
  }
  return false;
}

const update = ( isClient, token ) => {
  if(isClient){
    const accessToken = token.accessToken;
    const refreshToken = token.refreshToken;
    const userNo = token.userNo
    const userNm = token.userNm
    if(accessToken) sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    if(refreshToken) sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    if(userNo) sessionStorage.setItem(USER_NO_TOKEN_KEY, userNo);
    if(userNm) sessionStorage.setItem(USER_NM_TOKEN_KEY, userNm);
  }
}

const resetToken = (isClient) => {
  if(isClient){
    //sessionStorage.clear();
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY)
    sessionStorage.removeItem('user_no')
    sessionStorage.removeItem('user_nm')
  }
}

const getAccessToken = (isClient) => {
  if(isClient){
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    return isValidToken(accessToken) ? accessToken : null;
  }
  return null;
};

const getRefreshToken = (isClient) => {
  if(isClient) {
    const refreshToken = window.sessionStorage.getItem(REFRESH_TOKEN_KEY);
    return isValidToken(refreshToken) ? refreshToken : null;
  }
  return null;
}

const getUserNo = ( isClient ) => {
  if(isClient){
    return sessionStorage.getItem(USER_NO_TOKEN_KEY);
  }
}

const isValidToken = (token) => {
  if(token){
    const decode = jsonwebtoken.decode(token);
    if(!decode)
      return false;
    const exp = new Date(decode.exp * 1000);
    return exp > new Date();
  }
  return false;
}

const decodeToken = (token) => {
  if(token){
    const decode = jsonwebtoken.decode(token);
    return decode;
  }
  return null;
}


export default {
  update,
  resetToken,
  validAccessToken,
  getAccessToken,
  getRefreshToken,
  getUserNo,
  decodeToken
}

