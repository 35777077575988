import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b185334 = () => interopDefault(import('../pages/chanelTalk.vue' /* webpackChunkName: "pages/chanelTalk" */))
const _6f0e3017 = () => interopDefault(import('../pages/t_ktcb/index.vue' /* webpackChunkName: "pages/t_ktcb/index" */))
const _729de361 = () => interopDefault(import('../pages/t_mainbiz/index.vue' /* webpackChunkName: "pages/t_mainbiz/index" */))
const _4f12fd05 = () => interopDefault(import('../pages/WebToS.vue' /* webpackChunkName: "pages/WebToS" */))
const _d9376606 = () => interopDefault(import('../pages/WebToS2.vue' /* webpackChunkName: "pages/WebToS2" */))
const _d91b3704 = () => interopDefault(import('../pages/WebToS3.vue' /* webpackChunkName: "pages/WebToS3" */))
const _d8ff0802 = () => interopDefault(import('../pages/WebToS4.vue' /* webpackChunkName: "pages/WebToS4" */))
const _26368101 = () => interopDefault(import('../pages/app/confirm.vue' /* webpackChunkName: "pages/app/confirm" */))
const _bd08c422 = () => interopDefault(import('../pages/mng/login.vue' /* webpackChunkName: "pages/mng/login" */))
const _ffd4f048 = () => interopDefault(import('../pages/mypage/articleOfIncorporation/index.vue' /* webpackChunkName: "pages/mypage/articleOfIncorporation/index" */))
const _46b332ec = () => interopDefault(import('../pages/mypage/grant/index.vue' /* webpackChunkName: "pages/mypage/grant/index" */))
const _25396114 = () => interopDefault(import('../pages/mypage/inquiry.vue' /* webpackChunkName: "pages/mypage/inquiry" */))
const _f780700c = () => interopDefault(import('../pages/mypage/insured/index.vue' /* webpackChunkName: "pages/mypage/insured/index" */))
const _56a5ae00 = () => interopDefault(import('../pages/mypage/labor/index.vue' /* webpackChunkName: "pages/mypage/labor/index" */))
const _461c1094 = () => interopDefault(import('../pages/mypage/loan/index.vue' /* webpackChunkName: "pages/mypage/loan/index" */))
const _6e3ac000 = () => interopDefault(import('../pages/mypage/myInfo/index.vue' /* webpackChunkName: "pages/mypage/myInfo/index" */))
const _45bfd49f = () => interopDefault(import('../pages/mypage/support/index.vue' /* webpackChunkName: "pages/mypage/support/index" */))
const _459c73fb = () => interopDefault(import('../pages/mypage/tax/index.vue' /* webpackChunkName: "pages/mypage/tax/index" */))
const _a3b71620 = () => interopDefault(import('../pages/services/confirm.vue' /* webpackChunkName: "pages/services/confirm" */))
const _22bc281e = () => interopDefault(import('../pages/services/contactInfo.vue' /* webpackChunkName: "pages/services/contactInfo" */))
const _1b1fe99f = () => interopDefault(import('../pages/services/contractBizInfo.vue' /* webpackChunkName: "pages/services/contractBizInfo" */))
const _135b2f72 = () => interopDefault(import('../pages/services/inputBizConfirm.vue' /* webpackChunkName: "pages/services/inputBizConfirm" */))
const _671020ec = () => interopDefault(import('../pages/services/inputBizNo.vue' /* webpackChunkName: "pages/services/inputBizNo" */))
const _22c51384 = () => interopDefault(import('../pages/services/serviceComplete.vue' /* webpackChunkName: "pages/services/serviceComplete" */))
const _dbe6907e = () => interopDefault(import('../pages/services/serviceSelect.vue' /* webpackChunkName: "pages/services/serviceSelect" */))
const _d376efa8 = () => interopDefault(import('../pages/services/socialLogin.vue' /* webpackChunkName: "pages/services/socialLogin" */))
const _055e59c2 = () => interopDefault(import('../pages/services/support/index.vue' /* webpackChunkName: "pages/services/support/index" */))
const _bc25ebbe = () => interopDefault(import('../pages/t_mainbiz/support/index.vue' /* webpackChunkName: "pages/t_mainbiz/support/index" */))
const _16809c0b = () => interopDefault(import('../pages/mypage/labor/annual-leave.vue' /* webpackChunkName: "pages/mypage/labor/annual-leave" */))
const _03115758 = () => interopDefault(import('../pages/mypage/labor/contract.vue' /* webpackChunkName: "pages/mypage/labor/contract" */))
const _18c0ce8d = () => interopDefault(import('../pages/mypage/labor/on-duty-pay.vue' /* webpackChunkName: "pages/mypage/labor/on-duty-pay" */))
const _10d92d75 = () => interopDefault(import('../pages/mypage/labor/pay-stub.vue' /* webpackChunkName: "pages/mypage/labor/pay-stub" */))
const _7068a173 = () => interopDefault(import('../pages/mypage/labor/pay-stub-land.vue' /* webpackChunkName: "pages/mypage/labor/pay-stub-land" */))
const _05a0ee53 = () => interopDefault(import('../pages/mypage/labor/payroll.vue' /* webpackChunkName: "pages/mypage/labor/payroll" */))
const _d2acdef2 = () => interopDefault(import('../pages/mypage/loan/docs.vue' /* webpackChunkName: "pages/mypage/loan/docs" */))
const _3950cae4 = () => interopDefault(import('../pages/mypage/loan/skillCredit.vue' /* webpackChunkName: "pages/mypage/loan/skillCredit" */))
const _641a6d24 = () => interopDefault(import('../pages/mypage/myInfo/confirm.vue' /* webpackChunkName: "pages/mypage/myInfo/confirm" */))
const _518aa42a = () => interopDefault(import('../pages/services/articleOfIncorp/print.vue' /* webpackChunkName: "pages/services/articleOfIncorp/print" */))
const _e4cc346c = () => interopDefault(import('../pages/services/employ/complete.vue' /* webpackChunkName: "pages/services/employ/complete" */))
const _a53aca02 = () => interopDefault(import('../pages/services/employ/corpCert.vue' /* webpackChunkName: "pages/services/employ/corpCert" */))
const _8f1a83bc = () => interopDefault(import('../pages/services/employ/indCert.vue' /* webpackChunkName: "pages/services/employ/indCert" */))
const _21dd78a8 = () => interopDefault(import('../pages/services/employ/indInfo.vue' /* webpackChunkName: "pages/services/employ/indInfo" */))
const _6922f72b = () => interopDefault(import('../pages/services/employ/intro.vue' /* webpackChunkName: "pages/services/employ/intro" */))
const _429bc78a = () => interopDefault(import('../pages/services/employ/loading.vue' /* webpackChunkName: "pages/services/employ/loading" */))
const _33ec42f6 = () => interopDefault(import('../pages/services/employ/report.vue' /* webpackChunkName: "pages/services/employ/report" */))
const _b1f115a8 = () => interopDefault(import('../pages/services/employ/research.vue' /* webpackChunkName: "pages/services/employ/research" */))
const _50d5b4ee = () => interopDefault(import('../pages/services/employ/result.vue' /* webpackChunkName: "pages/services/employ/result" */))
const _071040c0 = () => interopDefault(import('../pages/services/insurance/checkresult.vue' /* webpackChunkName: "pages/services/insurance/checkresult" */))
const _31929c64 = () => interopDefault(import('../pages/services/insurance/complete.vue' /* webpackChunkName: "pages/services/insurance/complete" */))
const _06ff6703 = () => interopDefault(import('../pages/services/insurance/corpCert.vue' /* webpackChunkName: "pages/services/insurance/corpCert" */))
const _c5f71da0 = () => interopDefault(import('../pages/services/insurance/failResult.vue' /* webpackChunkName: "pages/services/insurance/failResult" */))
const _ec6b3cc4 = () => interopDefault(import('../pages/services/insurance/indCert.vue' /* webpackChunkName: "pages/services/insurance/indCert" */))
const _7f2e31b0 = () => interopDefault(import('../pages/services/insurance/indInfo.vue' /* webpackChunkName: "pages/services/insurance/indInfo" */))
const _4c756cb2 = () => interopDefault(import('../pages/services/insurance/intro.vue' /* webpackChunkName: "pages/services/insurance/intro" */))
const _3aafab77 = () => interopDefault(import('../pages/services/insurance/methodChoice.vue' /* webpackChunkName: "pages/services/insurance/methodChoice" */))
const _ec9c48ee = () => interopDefault(import('../pages/services/insurance/report.vue' /* webpackChunkName: "pages/services/insurance/report" */))
const _e02584b2 = () => interopDefault(import('../pages/services/intro/insurance.vue' /* webpackChunkName: "pages/services/intro/insurance" */))
const _18fc5e7d = () => interopDefault(import('../pages/services/intro/labor.vue' /* webpackChunkName: "pages/services/intro/labor" */))
const _4c165d5c = () => interopDefault(import('../pages/services/intro/support.vue' /* webpackChunkName: "pages/services/intro/support" */))
const _4a03d1b8 = () => interopDefault(import('../pages/services/intro/tax.vue' /* webpackChunkName: "pages/services/intro/tax" */))
const _386ebba8 = () => interopDefault(import('../pages/services/loan/complete.vue' /* webpackChunkName: "pages/services/loan/complete" */))
const _fdc7dac6 = () => interopDefault(import('../pages/services/loan/loading.vue' /* webpackChunkName: "pages/services/loan/loading" */))
const _51dc4b0a = () => interopDefault(import('../pages/services/loan/research.vue' /* webpackChunkName: "pages/services/loan/research" */))
const _14026a4c = () => interopDefault(import('../pages/services/loan/result.vue' /* webpackChunkName: "pages/services/loan/result" */))
const _7ae55249 = () => interopDefault(import('../pages/services/support/popular.vue' /* webpackChunkName: "pages/services/support/popular" */))
const _6e904871 = () => interopDefault(import('../pages/services/tax/checkresult.vue' /* webpackChunkName: "pages/services/tax/checkresult" */))
const _6fc33ac6 = () => interopDefault(import('../pages/services/tax/complete.vue' /* webpackChunkName: "pages/services/tax/complete" */))
const _3031d05c = () => interopDefault(import('../pages/services/tax/corpCert.vue' /* webpackChunkName: "pages/services/tax/corpCert" */))
const _ac5c4a22 = () => interopDefault(import('../pages/services/tax/indCert.vue' /* webpackChunkName: "pages/services/tax/indCert" */))
const _3f1f3f0e = () => interopDefault(import('../pages/services/tax/indInfo.vue' /* webpackChunkName: "pages/services/tax/indInfo" */))
const _85663990 = () => interopDefault(import('../pages/services/tax/intro.vue' /* webpackChunkName: "pages/services/tax/intro" */))
const _171d7418 = () => interopDefault(import('../pages/services/tax/report.vue' /* webpackChunkName: "pages/services/tax/report" */))
const _66ae8f6c = () => interopDefault(import('../pages/services/tax/report2.vue' /* webpackChunkName: "pages/services/tax/report2" */))
const _e52d4374 = () => interopDefault(import('../pages/t_mainbiz/insurance/intro.vue' /* webpackChunkName: "pages/t_mainbiz/insurance/intro" */))
const _7a61b79c = () => interopDefault(import('../pages/t_mainbiz/labor/intro.vue' /* webpackChunkName: "pages/t_mainbiz/labor/intro" */))
const _0600eabb = () => interopDefault(import('../pages/t_mainbiz/support/intro.vue' /* webpackChunkName: "pages/t_mainbiz/support/intro" */))
const _30489b17 = () => interopDefault(import('../pages/t_mainbiz/tax/intro.vue' /* webpackChunkName: "pages/t_mainbiz/tax/intro" */))
const _7090c534 = () => interopDefault(import('../pages/services/support/_pblancId.vue' /* webpackChunkName: "pages/services/support/_pblancId" */))
const _22a90645 = () => interopDefault(import('../pages/t_mainbiz/support/_pblancId.vue' /* webpackChunkName: "pages/t_mainbiz/support/_pblancId" */))
const _e04c9c24 = () => interopDefault(import('../pages/t_ktcb/_fcCode.vue' /* webpackChunkName: "pages/t_ktcb/_fcCode" */))
const _28f166c4 = () => interopDefault(import('../pages/auth/_socialType/callback.vue' /* webpackChunkName: "pages/auth/_socialType/callback" */))
const _d5798b7e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _83053808 = () => interopDefault(import('../pages/_landing/index.vue' /* webpackChunkName: "pages/_landing/index" */))
const _ee2741c2 = () => interopDefault(import('../pages/_landing/articleOfIncorporation/index.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/index" */))
const _c8888088 = () => interopDefault(import('../pages/_landing/support/index.vue' /* webpackChunkName: "pages/_landing/support/index" */))
const _7ccfab08 = () => interopDefault(import('../pages/_landing/articleOfIncorporation/complete.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/complete" */))
const _a2f2d3f2 = () => interopDefault(import('../pages/_landing/articleOfIncorporation/form.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/form" */))
const _3f410d6e = () => interopDefault(import('../pages/_landing/articleOfIncorporation/loading.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/loading" */))
const _4e611921 = () => interopDefault(import('../pages/_landing/insurance/intro.vue' /* webpackChunkName: "pages/_landing/insurance/intro" */))
const _480717f7 = () => interopDefault(import('../pages/_landing/labor/intro.vue' /* webpackChunkName: "pages/_landing/labor/intro" */))
const _4dc5b534 = () => interopDefault(import('../pages/_landing/mng/labor/index.vue' /* webpackChunkName: "pages/_landing/mng/labor/index" */))
const _2c771bac = () => interopDefault(import('../pages/_landing/mng/login.vue' /* webpackChunkName: "pages/_landing/mng/login" */))
const _0060bf54 = () => interopDefault(import('../pages/_landing/support/intro.vue' /* webpackChunkName: "pages/_landing/support/intro" */))
const _ee1f539c = () => interopDefault(import('../pages/_landing/tax/intro.vue' /* webpackChunkName: "pages/_landing/tax/intro" */))
const _a55b81bc = () => interopDefault(import('../pages/_landing/welfare/complete.vue' /* webpackChunkName: "pages/_landing/welfare/complete" */))
const _5a341b2e = () => interopDefault(import('../pages/_landing/welfare/fund.vue' /* webpackChunkName: "pages/_landing/welfare/fund" */))
const _6c01206b = () => interopDefault(import('../pages/_landing/welfare/fundResult.vue' /* webpackChunkName: "pages/_landing/welfare/fundResult" */))
const _f6c51340 = () => interopDefault(import('../pages/_landing/support/_pblancId.vue' /* webpackChunkName: "pages/_landing/support/_pblancId" */))
const _7f68feda = () => interopDefault(import('../pages/_landing/_fcCode.vue' /* webpackChunkName: "pages/_landing/_fcCode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chanelTalk",
    component: _5b185334,
    name: "chanelTalk"
  }, {
    path: "/t_ktcb",
    component: _6f0e3017,
    name: "t_ktcb"
  }, {
    path: "/t_mainbiz",
    component: _729de361,
    name: "t_mainbiz"
  }, {
    path: "/WebToS",
    component: _4f12fd05,
    name: "WebToS"
  }, {
    path: "/WebToS2",
    component: _d9376606,
    name: "WebToS2"
  }, {
    path: "/WebToS3",
    component: _d91b3704,
    name: "WebToS3"
  }, {
    path: "/WebToS4",
    component: _d8ff0802,
    name: "WebToS4"
  }, {
    path: "/app/confirm",
    component: _26368101,
    name: "app-confirm"
  }, {
    path: "/mng/login",
    component: _bd08c422,
    name: "mng-login"
  }, {
    path: "/mypage/articleOfIncorporation",
    component: _ffd4f048,
    name: "mypage-articleOfIncorporation"
  }, {
    path: "/mypage/grant",
    component: _46b332ec,
    name: "mypage-grant"
  }, {
    path: "/mypage/inquiry",
    component: _25396114,
    name: "mypage-inquiry"
  }, {
    path: "/mypage/insured",
    component: _f780700c,
    name: "mypage-insured"
  }, {
    path: "/mypage/labor",
    component: _56a5ae00,
    name: "mypage-labor"
  }, {
    path: "/mypage/loan",
    component: _461c1094,
    name: "mypage-loan"
  }, {
    path: "/mypage/myInfo",
    component: _6e3ac000,
    name: "mypage-myInfo"
  }, {
    path: "/mypage/support",
    component: _45bfd49f,
    name: "mypage-support"
  }, {
    path: "/mypage/tax",
    component: _459c73fb,
    name: "mypage-tax"
  }, {
    path: "/services/confirm",
    component: _a3b71620,
    name: "services-confirm"
  }, {
    path: "/services/contactInfo",
    component: _22bc281e,
    name: "services-contactInfo"
  }, {
    path: "/services/contractBizInfo",
    component: _1b1fe99f,
    name: "services-contractBizInfo"
  }, {
    path: "/services/inputBizConfirm",
    component: _135b2f72,
    name: "services-inputBizConfirm"
  }, {
    path: "/services/inputBizNo",
    component: _671020ec,
    name: "services-inputBizNo"
  }, {
    path: "/services/serviceComplete",
    component: _22c51384,
    name: "services-serviceComplete"
  }, {
    path: "/services/serviceSelect",
    component: _dbe6907e,
    name: "services-serviceSelect"
  }, {
    path: "/services/socialLogin",
    component: _d376efa8,
    name: "services-socialLogin"
  }, {
    path: "/services/support",
    component: _055e59c2,
    name: "services-support"
  }, {
    path: "/t_mainbiz/support",
    component: _bc25ebbe,
    name: "t_mainbiz-support"
  }, {
    path: "/mypage/labor/annual-leave",
    component: _16809c0b,
    name: "mypage-labor-annual-leave"
  }, {
    path: "/mypage/labor/contract",
    component: _03115758,
    name: "mypage-labor-contract"
  }, {
    path: "/mypage/labor/on-duty-pay",
    component: _18c0ce8d,
    name: "mypage-labor-on-duty-pay"
  }, {
    path: "/mypage/labor/pay-stub",
    component: _10d92d75,
    name: "mypage-labor-pay-stub"
  }, {
    path: "/mypage/labor/pay-stub-land",
    component: _7068a173,
    name: "mypage-labor-pay-stub-land"
  }, {
    path: "/mypage/labor/payroll",
    component: _05a0ee53,
    name: "mypage-labor-payroll"
  }, {
    path: "/mypage/loan/docs",
    component: _d2acdef2,
    name: "mypage-loan-docs"
  }, {
    path: "/mypage/loan/skillCredit",
    component: _3950cae4,
    name: "mypage-loan-skillCredit"
  }, {
    path: "/mypage/myInfo/confirm",
    component: _641a6d24,
    name: "mypage-myInfo-confirm"
  }, {
    path: "/services/articleOfIncorp/print",
    component: _518aa42a,
    name: "services-articleOfIncorp-print"
  }, {
    path: "/services/employ/complete",
    component: _e4cc346c,
    name: "services-employ-complete"
  }, {
    path: "/services/employ/corpCert",
    component: _a53aca02,
    name: "services-employ-corpCert"
  }, {
    path: "/services/employ/indCert",
    component: _8f1a83bc,
    name: "services-employ-indCert"
  }, {
    path: "/services/employ/indInfo",
    component: _21dd78a8,
    name: "services-employ-indInfo"
  }, {
    path: "/services/employ/intro",
    component: _6922f72b,
    name: "services-employ-intro"
  }, {
    path: "/services/employ/loading",
    component: _429bc78a,
    name: "services-employ-loading"
  }, {
    path: "/services/employ/report",
    component: _33ec42f6,
    name: "services-employ-report"
  }, {
    path: "/services/employ/research",
    component: _b1f115a8,
    name: "services-employ-research"
  }, {
    path: "/services/employ/result",
    component: _50d5b4ee,
    name: "services-employ-result"
  }, {
    path: "/services/insurance/checkresult",
    component: _071040c0,
    name: "services-insurance-checkresult"
  }, {
    path: "/services/insurance/complete",
    component: _31929c64,
    name: "services-insurance-complete"
  }, {
    path: "/services/insurance/corpCert",
    component: _06ff6703,
    name: "services-insurance-corpCert"
  }, {
    path: "/services/insurance/failResult",
    component: _c5f71da0,
    name: "services-insurance-failResult"
  }, {
    path: "/services/insurance/indCert",
    component: _ec6b3cc4,
    name: "services-insurance-indCert"
  }, {
    path: "/services/insurance/indInfo",
    component: _7f2e31b0,
    name: "services-insurance-indInfo"
  }, {
    path: "/services/insurance/intro",
    component: _4c756cb2,
    name: "services-insurance-intro"
  }, {
    path: "/services/insurance/methodChoice",
    component: _3aafab77,
    name: "services-insurance-methodChoice"
  }, {
    path: "/services/insurance/report",
    component: _ec9c48ee,
    name: "services-insurance-report"
  }, {
    path: "/services/intro/insurance",
    component: _e02584b2,
    name: "services-intro-insurance"
  }, {
    path: "/services/intro/labor",
    component: _18fc5e7d,
    name: "services-intro-labor"
  }, {
    path: "/services/intro/support",
    component: _4c165d5c,
    name: "services-intro-support"
  }, {
    path: "/services/intro/tax",
    component: _4a03d1b8,
    name: "services-intro-tax"
  }, {
    path: "/services/loan/complete",
    component: _386ebba8,
    name: "services-loan-complete"
  }, {
    path: "/services/loan/loading",
    component: _fdc7dac6,
    name: "services-loan-loading"
  }, {
    path: "/services/loan/research",
    component: _51dc4b0a,
    name: "services-loan-research"
  }, {
    path: "/services/loan/result",
    component: _14026a4c,
    name: "services-loan-result"
  }, {
    path: "/services/support/popular",
    component: _7ae55249,
    name: "services-support-popular"
  }, {
    path: "/services/tax/checkresult",
    component: _6e904871,
    name: "services-tax-checkresult"
  }, {
    path: "/services/tax/complete",
    component: _6fc33ac6,
    name: "services-tax-complete"
  }, {
    path: "/services/tax/corpCert",
    component: _3031d05c,
    name: "services-tax-corpCert"
  }, {
    path: "/services/tax/indCert",
    component: _ac5c4a22,
    name: "services-tax-indCert"
  }, {
    path: "/services/tax/indInfo",
    component: _3f1f3f0e,
    name: "services-tax-indInfo"
  }, {
    path: "/services/tax/intro",
    component: _85663990,
    name: "services-tax-intro"
  }, {
    path: "/services/tax/report",
    component: _171d7418,
    name: "services-tax-report"
  }, {
    path: "/services/tax/report2",
    component: _66ae8f6c,
    name: "services-tax-report2"
  }, {
    path: "/t_mainbiz/insurance/intro",
    component: _e52d4374,
    name: "t_mainbiz-insurance-intro"
  }, {
    path: "/t_mainbiz/labor/intro",
    component: _7a61b79c,
    name: "t_mainbiz-labor-intro"
  }, {
    path: "/t_mainbiz/support/intro",
    component: _0600eabb,
    name: "t_mainbiz-support-intro"
  }, {
    path: "/t_mainbiz/tax/intro",
    component: _30489b17,
    name: "t_mainbiz-tax-intro"
  }, {
    path: "/services/support/:pblancId",
    component: _7090c534,
    name: "services-support-pblancId"
  }, {
    path: "/t_mainbiz/support/:pblancId",
    component: _22a90645,
    name: "t_mainbiz-support-pblancId"
  }, {
    path: "/t_ktcb/:fcCode",
    component: _e04c9c24,
    name: "t_ktcb-fcCode"
  }, {
    path: "/auth/:socialType?/callback",
    component: _28f166c4,
    name: "auth-socialType-callback"
  }, {
    path: "/",
    component: _d5798b7e,
    name: "index"
  }, {
    path: "/:landing",
    component: _83053808,
    name: "landing"
  }, {
    path: "/:landing/articleOfIncorporation",
    component: _ee2741c2,
    name: "landing-articleOfIncorporation"
  }, {
    path: "/:landing/support",
    component: _c8888088,
    name: "landing-support"
  }, {
    path: "/:landing/articleOfIncorporation/complete",
    component: _7ccfab08,
    name: "landing-articleOfIncorporation-complete"
  }, {
    path: "/:landing/articleOfIncorporation/form",
    component: _a2f2d3f2,
    name: "landing-articleOfIncorporation-form"
  }, {
    path: "/:landing/articleOfIncorporation/loading",
    component: _3f410d6e,
    name: "landing-articleOfIncorporation-loading"
  }, {
    path: "/:landing/insurance/intro",
    component: _4e611921,
    name: "landing-insurance-intro"
  }, {
    path: "/:landing/labor/intro",
    component: _480717f7,
    name: "landing-labor-intro"
  }, {
    path: "/:landing/mng/labor",
    component: _4dc5b534,
    name: "landing-mng-labor"
  }, {
    path: "/:landing/mng/login",
    component: _2c771bac,
    name: "landing-mng-login"
  }, {
    path: "/:landing/support/intro",
    component: _0060bf54,
    name: "landing-support-intro"
  }, {
    path: "/:landing/tax/intro",
    component: _ee1f539c,
    name: "landing-tax-intro"
  }, {
    path: "/:landing/welfare/complete",
    component: _a55b81bc,
    name: "landing-welfare-complete"
  }, {
    path: "/:landing/welfare/fund",
    component: _5a341b2e,
    name: "landing-welfare-fund"
  }, {
    path: "/:landing/welfare/fundResult",
    component: _6c01206b,
    name: "landing-welfare-fundResult"
  }, {
    path: "/:landing/support/:pblancId",
    component: _f6c51340,
    name: "landing-support-pblancId"
  }, {
    path: "/:landing/:fcCode",
    component: _7f68feda,
    name: "landing-fcCode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
