import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ app }) => {
  if('production'==process.env.NODE_ENV){
    Vue.use(VueGtag, {
      config: {
        id: 'G-5Y7MTQJSLK',	// 측정 ID
      },
      appName: 'bizAllOk',
    }, app.router)
  }
}
