<template>
  <!-- <div class="app main" oncontextmenu="return false" ondragstart="return false" onselectstart="return false"> -->
  <div class="app main">
    <nuxt/>
    <transition name="fade">
      <Alert v-if="$store.state.ui.popupAlertData.state === true"/>
      <Basic v-if="$store.state.ui.popupBasicData.state === true"/>
      <Loading v-if="$store.state.ui.loadingState === true"/>
    </transition>
    <Footer :pageNm="pageNm"/>
  </div>
</template>

<script>
import Setting from "~/mixins/Setting";
import Footer from "~/components/layouts/Footer";
import Alert from "~/components/common/Alert";
import Basic from "~/components/common/popup/Basic";
import Loading from "~/components/ui/popup/Loading";


export default {
  name: "main_layout",
  components: {
    Footer,
    Alert,
    Basic,
    Loading
  },
  data() {
    return {
      pageNm: null
    }
  },
  mixins: [Setting],
  async beforeMount() {
    this.$savePartnerInfo(this.$route);
    // this.pageNm = this.$route.name
    // const isLogin = this.$isLogin();
    // const hasAccessToken = this.$getAccessToken() ? true : false;
    // if(hasAccessToken && !isLogin){
    //   const accessToken = this.$getAccessToken();
    //   if(!accessToken){
    //     // console.log('유효한 엑세스 토큰이 없다.');
    //     const refreshToken = this.$getRefreshToken();
    //     if(refreshToken) {
    //       // console.log('엑세스 토큰 재발급을 위한 리프레시 토큰이 있다.');
    //       const res = await this.$axios.get(`/apiAuth/token/refresh?refreshToken=${refreshToken}`);
    //       if(res.data.success){
    //         this.$saveToken(res.data.contents);
    //         this.$store.commit('servicesStore/UPDATE_USER', res.data.contents.userNo)
    //       }else{
    //         // console.log('엑세스 토큰 재발급이 실패했고 기존에 있던 토큰을 삭제한다.');
    //         this.$clearToken();
    //       }
    //     }else{
    //       // console.log('리프레시 토큰도 없다.');
    //       this.$clearToken();
    //     }
    //   }
    //   const decode = jsonwebtoken.decode(accessToken)
    //   if(decode){
    //     this.$store.commit('servicesStore/UPDATE_USER', decode.user_no);
    //     this.$store.commit('servicesStore/UPDATE_USER_NM', decode.user_nm);
    //   }
    // }
  },
  // head() {
  //   return {
  //     title: "Main | UI | 다된다",
  //   };
  // },
  methods: {

  }
};
</script>
