export const state = () => ({
  codeList: null,
  fileMap: {
    FA0101: [],
    FA0102: [],
    FA0103: [],
    FA0104: [],
    FA0105: [],
    FA0106: [],
    FA0201: [],
    FA0202: [],
    FA0301: [],
    FA0302: [],
  },
  userInfo: null,
});

export const mutations = {
  INIT_USER_INFO(state, userInfo){
    state.userInfo = userInfo
  },
  UPDATE_USER_INFO(state, userInfo){
    Object.keys(userInfo).forEach(key => {
      state.userInfo[key] = userInfo[key];
    });
  },
  INIT_FILE_MAP(state, fileMap){
    Object.keys(state.fileMap).forEach(key => {
      state.fileMap[key] = fileMap[key] ? fileMap[key].files : [];
    });
  },
  ADD_FILE_MAP(state, {code, data}){
    state.fileMap[code.cd].push(data);
  },
  SUB_FILE_MAP(state, {code, fileId}){
    const foundItem = state.fileMap[code.cd].find(item => item.id === fileId);
    if(foundItem) {
      const idx = state.fileMap[code.cd].indexOf(foundItem);
      state.fileMap[code.cd].splice(idx,1);
    }
  }
};

export const actions = {

};
