import * as ACCESS_TYPE from './accessType'

export const conia = {
    path: '/conia/',
    companyId: 'CPM0006',
    companyName: '코니아',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/conia/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/conia.png',
    logoImgWrapperWidth:150,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/conia_mobile.png',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
}

export const infoyou = {
    path: '/infoyou/',
    companyId: 'CPM0001',
    companyName: '인포유금융서비스',
    // serviceName: '인포유금융서비스',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/infoyou/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/logo.svg?v=02',
    logoImgWrapperWidth:150,
    logoImgMobileWrapperheight:71,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/logo.svg?v=02',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
}

export const unicornranch = {
    path: '/d3/',
    companyId: 'ADM0001',
    companyName: '유니콘랜치',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/d3/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo.svg?v=3',
    logoImgWrapperWidth:140,
    logoImgMobileWrapperheight:75,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo.svg?v=3',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
}

export const mainbiz = {
    path: '/mainbiz/',
    companyId: 'CPM0003',
    companyName: '한국경영혁신중소기업협회',
    serviceName: '메인비즈 경영지원 플랫폼',
    reportName: '메인비즈 경영지원 플랫폼',
    homeUrl: '/mainbiz/',
    // logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/mainbiz_logo.png',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/mainbiz03.png',
    logoImgWrapperWidth:220,
    logoImgMobileWrapperWidth:100,
    logoImgMobileWrapperheight:120,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/mainbiz03_mobile.png',
    logoImgSize: 'contain',
    reportImg: 'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/mainbiz03.png',
    logoMobileImgSize:'contain',
    // footer:{
    //     companyName:'(사)한국경영혁신중소기업협회',
    //     prstNm: '석용찬',
    //     bizNo: '204-82-10125',
    //     tel: '02-2088-1271',
    //     addr: '서울특별시 종로구 종로 413(숭인동, 동보빌딩 2층 208호, 3층 308호) 03111<br/>(지번:서울시 종로구 숭인동 1252 동보빌딩 2층 208호, 3층 308호)',
    //     copyright: "Copyright © UnicornRanch. All Rights Reserved."
    // },
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
    sendMail: {
        allowedAccessType: [ACCESS_TYPE.MOBILE],
        templateType: 'type1',
        conjunctions: '과'
    },
    popup:[{
        id:'mainbiz_p_01',
        src:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/popup/mainbiz231222-2.jpg',
    }]
}

export const ktcb = {
    path: '/ktcb/',
    companyId: 'CPM0005',
    companyName: 'KTCB',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/ktcb/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo.svg?v=3',
    logoImgWrapperWidth:140,
    logoImgMobileWrapperheight:80,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo.svg?v=3',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
    sendMail: {
        allowedAccessType: [ACCESS_TYPE.LANDING, ACCESS_TYPE.MOBILE],
        templateType: 'default',
        conjunctions: '와'
    },
}

export const medieus = {
    path: '/medieus/',
    companyId: 'CPM0010',
    companyName: '메디우스',
    serviceName: '메디우스 경영지원 플랫폼',
    reportName: '메디우스 경영지원 플랫폼',
    homeUrl: '/medieus/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/medieus-01.png',
    logoImgWrapperWidth:220,
    logoImgMobileWrapperWidth:100,
    logoImgMobileWrapperheight:120,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/medieus-01.png',
    logoImgSize: 'contain',
    reportImg: 'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/medieus-01.png',
    logoMobileImgSize:'contain',
    customLandingPage: 'medieus',
    menuInfo: {
      ignoreMenuDisplay: ['loan','support','articleOnIncorporation'],
    },
    bizFieldInfo: {
        compNm: { title: '병의원명', },
        fndDt: { hide: true, defaultValue: '-.-.-', },
        sector: { hide: true, defaultValue:'15', },
        authState: { hide: true, },
        patnCnt: { hide: true, defaultValue:'0', },
        prstBirth: { hide: true },
        prstGender: { hide: true,},
        compTypes: { hide: true },
        empCnt: { hide: true },
        salesAmnt: { hide: true },
        interests: { hide: true },
    },
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
    sendMail: {
        allowedAccessType: [ACCESS_TYPE.MOBILE],
        templateType: 'type1',
        conjunctions: '과'
    }
}

export const giscorea = {
    path: '/giscorea/',
    companyId: 'CPM0007',
    companyName: '한국공간정보산업협동조합',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/giscorea/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/gis.png',
    logoImgSize: '96%',
    logoImgWrapperWidth:250,
    logoImgMobileWrapperWidth:1,
    logoImgMobileWrapperheight:150,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/gis-mobile.png',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
    popup:[{
        id:'giscorea_01',
        src:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/popup/giscorea_01_240303.jpg',
    }]
}

export const venturernd = {
    path: '/venturernd/',
    companyId: 'CPM0008',
    companyName: '벤처R&D센터',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/venturernd/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/venture.png',
    logoImgWrapperWidth:160,
    logoImgMobileWrapperheight:80,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/venture-mobile.png',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    }
}

export const psfine = {
    path: '/psfine/',
    companyId: 'CPM0009',
    companyName: 'PS FINE SERVICE',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/psfine/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/psfine.png',
    logoImgWrapperWidth:160,
    logoImgMobileWrapperheight:110,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/psfine-mobile.png',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    }
}

export const unicornranchMobile = {
    path: '/',
    companyId: 'ADM0001',
    companyName: '유니콘랜치',
    serviceName: '다된다',
    reportName: '다된다',
    homeUrl:'/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo.svg?v=3',
    logoImgWrapperWidth:140,
    logoImgMobileWrapperheight:80,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo.svg?v=3',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    }
}



export const taxIntroFooter = {
    companyName:'정원세무회계',
    prstNm: null,
    bizNo: '875-43-00852',
    tel: null,
    addr: '서울특별시 강남구 강남대로62길 3, 2층',
    copyright: "Copyright © JUNGWON TAX ACCOUNTING Co.Ltd, All Rights Reserved."
}
