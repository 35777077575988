<template>
  <div id="landing_header">
    <client-only>
    <div class="header_container">
      <div class="head_left">
          <button :style="mixBackgroundStyle" style="min-width: 80px;">홈</button>
        <span v-if="$store.state.ui.view!='mobile'" style="white-space: nowrap;">온라인 노무관리</span>
      </div>
      <div class="head_right">
        <template>
          <button v-if="logined" type="button" class="basic" @click="fnLogout">로그아웃</button>
        </template>
        <button class="hamburger" @click="fnOpenNav">메뉴</button>
      </div>
    </div>
    </client-only>
  </div>
</template>


<script>
import serviceMix from '../../../mixins/partner/serviceMix';
import authMix from '../../../mixins/authMix';
import headerMix from '../../../mixins/partner/headerMix';
import CompanyName from "./CompanyName";
import MyIcon from './MyIcon';

export default {
  mixins: [serviceMix, authMix, headerMix],
  components:{CompanyName, MyIcon},
  computed: {
    company() {
      if (this.$store.state.userStore.myCompList[0]) return this.$store.state.userStore.myCompList[0].compNm;
      return null;
    },
    logined(){
      return this.$store.state.mngAuth;
    }
  },
  created() {
    this.allOkAdminUrl = this.$store.state.env.consultantAdminLink;
    this.path = this.$route.path;
    this.isSubPath = (this.subPathList.find( subPath => this.path.indexOf(subPath) >= 0)) ? true : false;
  },
  beforeMount(){
    this.$auth_RestoreBizNo();
  },
  data() {
    return {
      subPathList: ['services'],
      allOkAdminUrl: null,
      path: null,
      isSubPath:false,
      logoutBtn: true,
      backgroundStyle: {
        backgroundImage: 'none',
      },
    };
  },
  methods: {
    async fnLogout() {
      const partnerInfo = this.$getPartnerInfo();
      const path = partnerInfo ? partnerInfo.path : '/d3/';
      this.$store.dispatch('MNG_LOGOUT');
      this.$router.push(`${path}mng/login`);
    },
    fnOpenNav() {
      this.$store.dispatch('ui/setMenuState', !this.$store.state.ui.menuState);
    },
  },
};
</script>

<style scoped>
  @media (min-width: 334px){
    .brClass{
      display: none;
    }
  }
</style>
