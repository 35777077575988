import companyInfoService from '../service/commonAllOk/companyInfoService';
import MainbizService from "../service/allOkMng/mainbizService";
const DEFAULT_COMPANY_INFO = {
  bizNo: null,
  prstNm: null,
  compNm: '',
  compTel: null,
  fndDt: null,
  korAddr: null,
  idscd: '00000',
  sector: null,
  labYn: false,
  deptYn: false,
  ventureYn: false,
  innobizYn: false,
  mainbizYn: false,
  noOwn:false,
  patnCnt: null,
  patnValue: null,
  techRate: null,
};

export const state = () => ({
  bizNo:null,
  companyInfo: {...DEFAULT_COMPANY_INFO},
});

export const mutations = {
  UPDATE_BIZNO(state, bizNo){
    state.bizNo = bizNo;
  },
  UPDATE_COMPANY_INFO(state, payload){
    Object.keys(payload).forEach(key => {
      state.companyInfo[key] = payload[key];
    });
    sessionStorage.setItem('extData', JSON.stringify(payload));
  },
  CLEAR(state){
    state.bizNo = null;
    state.companyInfo= {...DEFAULT_COMPANY_INFO};
  },
};

export const actions = {
  /**
   *
   * 회사 정보 조회
   *
   */
  async fetchCompanyInfo({state, commit}, _bizNo) {
    let bizNo = null;
    // 검색할 사업자번호가 파라미터에
    if(!_bizNo){
      return {
        success:false,
        error : { cd : 'NO_BIZ_DATA', msg : '조회 할 사업자 번호가 없습니다.' },
      }
    }
    else {
      commit('UPDATE_BIZNO', _bizNo);
      bizNo = _bizNo;
    }

    const result = await companyInfoService.fetchCompanyInfo(this.$axios, bizNo);
    if (result.success) {
      const convertedCompanyInfo = convertCompanyInfo(result.contents)
      commit('UPDATE_COMPANY_INFO', convertedCompanyInfo);
      sessionStorage.setItem('bizNo', convertedCompanyInfo.bizNo);
      sessionStorage.setItem('compNm', convertedCompanyInfo.compNm);
    } else{
      const companyInfo = {...DEFAULT_COMPANY_INFO};
      const result2 = await MainbizService.getMemberType(this.$axios, bizNo)
      if( result2.success && result2.contents.member_type.indexOf('일반회원')>=0) {
        companyInfo.mainbizYn = true;
      }
      console.log('update', companyInfo)
      commit('UPDATE_COMPANY_INFO', companyInfo);
      sessionStorage.setItem('bizNo', bizNo);
      sessionStorage.removeItem('compNm')
      sessionStorage.removeItem('extData');
    }
    return result;
  },

  // Deprecated - 사용처가 없음 - 에러가 없으면 삭제할 것
  async fetchCompanyInfo2({state, commit}) {
    const bizNo = state.companyInfo.bizNo;
    if(!bizNo){
      return { result: { error : { cd : 'NO_BIZ_DATA', msg : '조회 할 사업자 번호가 없습니다.' } } }
    }
    const result = await companyInfoService.fetchCompanyInfo(this.$axios, bizNo);
    if (result.success) {
      commit('UPDATE_COMPANY_INFO', convertCompanyInfo(result.contents));
    }
    return result
  },

  // Deprecated
  async fetchMyCompanyInfo({commit}, bizNo) {
    if(!bizNo){
      return { result: { error : { cd : 'NO_BIZ_DATA', msg : '조회 할 사업자 번호가 없습니다.' } } }
    }
    const result = await companyInfoService.fetchCompanyInfo(this.$axios, bizNo);
    if (result.success) {
      return convertCompanyInfo(result.contents);
    }
  }
};

function convertCompanyInfo(raw) {
  let result = {
    bizNo:raw.bizno,
    compNm: raw.korentrnm,
    compTel: raw.tel,
    prstNm: raw.korreprnm,
    tel: raw.tel,
    fndDt: raw.etbDate,
    labYn: raw.labYN === 'N' ? false : true,
    ventureYn: raw.ventureYn === 'N' ? false : true,
    innobizYn: raw.innobizYn === 'N' ? false : true,
    deptYn: raw.deptYn === 'N' ? false : true,
    certYn: raw.crtYN === 'N' ? false : true,
    patnYn: raw.patentYN === 'N' ? false : true,
    mainbizYn: raw.mainbizYn === 'N' ? false : true,
    korAddr: raw.koraddr,
    sector: raw.korIdscdnm,
    idscd: raw.idscd,
  };
  if(raw.companyValue){
    result.patnCnt = raw.companyValue.patentCount ? raw.companyValue.patentCount : 0;
    result.techRate = raw.companyValue.tcg ? raw.companyValue.tcg : '-';
    result.patnValue = raw.companyValue.technicalAsset;
    result.technicalAsset = raw.companyValue.technicalAsset
                              ? raw.companyValue.technicalAsset
                              : 0;
    if(result.technicalAsset !== 0){
      result.technicalAsset = result.technicalAsset.toFixed(1);
    }
  } else {
    result.patnCnt =  0;
    result.techRate = '-';
    result.technicalAsset= 0;
    result.patnValue=null;
  }
  return result;
}
