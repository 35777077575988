import authService from "../service/auth/authService";
import * as loginServiceType from "../constants/loginMixNextServiceType";
import * as storageKey from '../constants/storageKey';

export default {
    methods:{
        fnMixLogin(){
            this.$router.push(`/services/socialLogin?addBizInfo=y&selectedService=${loginServiceType.LANDING_HOME}`);
        },
        fnMixLogout(){
            const userNo = sessionStorage.getItem("user_no");
            authService.logout(userNo);
            sessionStorage.removeItem("user_no");
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("refresh_token");
            sessionStorage.removeItem("bizNo");
            sessionStorage.removeItem("compNm");
            sessionStorage.removeItem("user_nm");
            sessionStorage.removeItem("user_bizNoList");
            sessionStorage.removeItem("extData");
            this.$store.commit('servicesStore/CLEAR2')
            this.$store.commit('userStore/CLEAR');
            //
            sessionStorage.removeItem(storageKey.selectedMyCompBizNo);
            //
            if(this.$store.state.ui.menuState){
                this.$store.dispatch('ui/setMenuState', !this.$store.state.ui.menuState);
            }
            this.$router.push(this.$goHome())
        }
    }
}
