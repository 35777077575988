<template>
  <div class="app my" oncontextmenu="return false" ondragstart="return false" onselectstart="return false">
  <!-- <div class="app my"> -->
    <Header/>
    <div class="wrap">
      <div class="inner">
        <!-- 슬라이드 에니메션 문제로 메뉴 2개 만듬 -->
        <Menu class="nav_pc" v-if="this.$store.state.ui.view == 'pc'"/>
        <Menu class="nav_mobile" v-else/>
        <nuxt/>
      </div>
    </div>
    <Footer v-if="!info" pageNm='my'/>
    <div class="ifs" v-if="info">
      <Footer3 style="background-color: #202020;"/>
    </div>
    <transition name="fade">
      <Alert v-if="$store.state.ui.popupAlertData.state === true"/>
      <!-- <Premium v-if="$store.state.ui.premium === true"/> -->
      <Basic v-if="$store.state.ui.popupBasicData.state === true"/>
      <Loading v-if="$store.state.ui.loadingState === true"/>
    </transition>
    <transition name="lending">
      <BizNum2 class="ifs" v-if="$store.state.ui.popupBizNum2 === true" key="BizNum2"/>
    </transition>
  </div>
</template>

<script>
import Setting from "~/mixins/Setting";
import Alert from "~/components/common/Alert";
// import Header from "~/components/layouts/my/Header";
import Header from "../components/ifs/layouts/Header2";
import Menu from "~/components/layouts/my/Menu";
import Footer from "~/components/layouts/Footer";
import Footer3 from "~/components/ifs/layouts/Footer3";
import Premium from "~/components/common/popup/Premium";
import Basic from "~/components/common/popup/Basic";
import Loading from "~/components/ui/popup/Loading";
import BizNum2 from "../components/ifs/popup/BizNum2";

export default {
  name: "my_Layout",
  components: {
    Alert,
    Header,
    Menu,
    Footer,
    Footer3,
    Premium,
    Basic,
    Loading,
    BizNum2,
  },
  mixins: [Setting],
  data() {
    return {
      info: null
    }
  },
  mounted(){
    let partnerInfo = this.$getPartnerInfo();
    if(partnerInfo){
      this.info = partnerInfo.path;
    }
  },
};
</script>
