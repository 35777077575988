const getFavorite = (axios, bizNo) => {
  return axios.$get(`/apiMngAo/customer/biz-info/${bizNo}/fvrt`);
};

const putFavorite = (axios, bizNo, pblncId) => {
  return axios.$put(`/apiMngAo/customer/biz-info/${bizNo}/fvrt/${pblncId}`);
};

export default {
  getFavorite,
  putFavorite,
};
