/**
 * 인증 후 또는 인증 회원 입력정보 후 처리
 */
import * as loginMixNextServiceTypes from '../constants/loginMixNextServiceType';
import * as outLink from '../constants/outLink';
import * as accessType from '../constants/accessType';
import {addQuery} from '../utils/HttpUtils';
import { ARTICLE_OF_INCORPORATION } from "../constants/loginMixNextServiceType";
export default {
    data(){
        return{
            mixNextServiceMap:{
                [loginMixNextServiceTypes.BIZ_SUPPORT]:this._mixFnGoBizSupport,
                [loginMixNextServiceTypes.EMPLOY]:this._mixFnGoEmploy,
                [loginMixNextServiceTypes.INSURANCE]:this._mixFnGoInsurance,
                [loginMixNextServiceTypes.TAX]:this._mixFnGoTax,
                [loginMixNextServiceTypes.LOAN]:this._mixFnGoLoan,
                [loginMixNextServiceTypes.LABOR]:this._mixFnGoLabor,
                [loginMixNextServiceTypes.MY_PAGE]:this._mixFnGoMypage,
                [loginMixNextServiceTypes.LANDING_HOME]:this._mixFnGoLandingHome,
                [loginMixNextServiceTypes.WELFARE_FUND]:this._mixFnGoWelfareResult,
                [loginMixNextServiceTypes.ARTICLE_OF_INCORPORATION]:this._mixFnGoArticleOfIncorp,
            }
        }
    },
    methods:{
        fnSelectService(selectedService){
            //
            const isAuthorized = this.$isLogin() && this.$store.state.userStore.userData.id;
            const hasCompany = this.$store.getters["userStore/getMyComp"] && this.$store.getters["userStore/getMyComp"].bizNo;
            //
            const processFun = (accessType.LANDING == this.$accessType() || accessType.MOBILE == this.$accessType()) ? this._landingProcess
                                : (accessType.APP == this.$accessType()) ? this._mobileProcess
                                : null;
            //
            if(processFun){
                processFun(isAuthorized, hasCompany, selectedService);
            }else{
                alert('accessType not found');
            }
            console.log('serviceMixEnd');
        },
        _landingProcess(isAuthorized, hasCompany, selectedService){
            if(isAuthorized && hasCompany){
                const myComp = this.$store.getters["userStore/getMyComp"];
                const hasNoBizInfo = !myComp || !myComp.extData || !myComp.extData.compTypes || !myComp.extData.interests;
                if(loginMixNextServiceTypes.BIZ_SUPPORT==selectedService && hasNoBizInfo){
                    // 사업자 추가 정보
                    let path = '/services/contractBizInfo';
                    path = addQuery(path, 'selectedService', selectedService);
                    path = addQuery(path, 'mode', 'addBizInfo');
                    this.$router.push(path)
                }else{
                    this.mixFnGoNextService(null, selectedService);
                }
            }
            else if(isAuthorized && !hasCompany){
                let path = '/service/inputBizNo';
                path = addQuery(path, 'selectedService', selectedService);
                this.$router.push(path)
            }
            else{
                this.__goLogin(selectedService);
            }
            console.log('_landingProcess end');
        },
        _mobileProcess(isAuthorized, hasCompany, selectedService){
            if(isAuthorized){
                this.mixFnGoNextService(null, selectedService);
            }
            else{
                this.__goLogin(selectedService);
            }
        },
        __goLogin(selectedService){
            let path = '/services/socialLogin';
            path = addQuery(path, 'selectedService', selectedService);
            return this.$router.push(path);
        },
        // 대출서비스
        // Depreacterd
        fnGoFund(){
            this.fnSelectService(loginMixNextServiceTypes.LOAN)
        },

        /**
         * 어디로 이동할지 처리
         */
        mixFnGoNextService(data, selectedService){
            if(!selectedService){
                selectedService = this.$route.query.selectedService;
            }
            if(!selectedService){
                alert('선택하신 서비스가 없습니다.')
               this.$router.push(this.$goHome());
            } else {
                const goFunction = this.mixNextServiceMap[selectedService];
                if(!goFunction){
                    this.$fnAlert(
                        '인증 이후 선택하신 서비스에 대한 처리를 할 수 없습니다.<br/>관리자의 확인이 필요합니다. ',
                        {name:'확인', link: '/'},
                    )
                }else{
                    // 멀티 로그인 처리?
                    const compList = this.$store.state.userStore.myCompList;
                    if ( !compList ) {
                        this.$router.push('/services/inputBizNo')
                    }
                    //
                    const selectedMyCompBizNo = this.$auth_MyBizNo();
                    if( !selectedMyCompBizNo ){
                        if(compList.length == 1){
                            const bizNo = compList[0].bizNo;
                            this.$auth_SaveBizNo(bizNo);
                            //
                            const myComp = this.$store.getters["userStore/getMyComp"];
                            sessionStorage.setItem('bizNo', myComp.bizNo);
                            sessionStorage.setItem('compNm', myComp.compNm);
                            this.$store.commit('cmnCompanyStore/UPDATE_BIZNO', myComp.bizNo)
                            this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO',myComp);
                            //
                            goFunction(data);
                        }
                        // 보유하는 회사가 1개 이상
                        else if(compList.length > 1){
                            // 회사 선택 팝업을 연다
                            if( !this.$store.state.ui.popupCompanySelect ){
                                this.$store.commit('ui/UPDATE_STATE', {
                                    popupCompanySelect:true,
                                    popupCompanyFunc:()=>{ goFunction(data) }
                                })
                            }
                        }
                    } else {
                        goFunction(data);
                    }
                }
            }
        },
        //
        _mixFnGoEmploy(){
            let {isApp} = this.$appCheck();
            let idsCd = this.$store.state.cmnCompanyStore.companyInfo.idscd;
            /**
             * 업종코드 처리
             */
            // 나이스 조회되지 않은 개인사업자의 업종코드 처리
            const regex = /[^0-9]/g;
            idsCd = idsCd ? idsCd : '00000';
            // 나이스 조회되지 않은 법인 기업의 업종코드 처리
            idsCd = idsCd.indexOf('IDS_')>=0 ? '00000' : idsCd;
            const replacedIdsCd = idsCd.replace(regex, "");

            /**
             * 고용타입 조회
              */
            this.$store.dispatch('employServiceStore/fetchEmployAvailTypes', {idsCd: replacedIdsCd})
                .then(result => {
                    let employAvail = true;
                    if(this.$store.state.servicesStore.employAvailTypes){
                        employAvail = this.$store.state.servicesStore.employAvailTypes.supportAvail;
                    }
                    if(employAvail) {
                        this.$router.push(`/services/employ/intro`)
                    }
                    else{
                        if(isApp){
                            this.$router.push('/services/serviceSelect')
                        }
                        else{
                            this.$fnAlert('지원대상이 아닙니다.', {name:'확인', link:this.$goHome()})
                        }
                    }
                });
        },
        _mixFnGoLabor(){
            const myCompany = this.$store.getters["userStore/getMyComp"]
            const partnerInfo = this.$getPartnerInfo();
            if(this.$isLogin()&&myCompany){
                this.$router.push('/mypage/labor')
            }
            else if(this.$isLogin()){
                if ( partnerInfo ) {
                    // TODO - 현재는 메인비즈만 적용하나 - 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
                    if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                        this.$router.push(`${partnerInfo.path}labor/intro?compSelect=true`)
                    }else{
                        this.$router.push('/services/intro/labor?compSelect=true')
                    }
                }
            } else {
                if ( partnerInfo ) {
                    // TODO - 현재는 메인비즈만 적용하나 - 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
                    if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                        this.$router.push(`${partnerInfo.path}labor/intro`)
                    }else{
                        this.$router.push("/services/intro/labor");
                    }
                }
            }
        },
        // 4대보험
        _mixFnGoInsurance(){
            // 랜딩페이지에서 넘어온경우
            if(this.$getPartnerInfo()){
                const {bizNo, compNm} = this.$getCompInfo();
                if(!bizNo || !compNm){
                    let myComp = this.$store.state.userStore.myCompList[this.$getMyCompIdx()];
                    sessionStorage.setItem('bizNo', myComp.bizNo);
                    sessionStorage.setItem('compNm', myComp.compNm);
                    this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO',myComp);
                }
            }
            this.$router.push('/services/insurance/intro')
        },
        // 경정청구
        _mixFnGoTax(){
            if('production'==process.env.NODE_ENV) {
                const bizNo = this.$getCompInfo().bizNo;
                this.$gtag.event('tax_click', {bizNo});
            }
            // API CALL
            // CASE - Landing
            if(this.$getPartnerInfo()){
                let myComp = this.$store.getters['userStore/getMyComp'];
                if(myComp) {
                    this.$axios.$post(`/apiMngAo/customer/tax/${myComp.bizNo}/consulting`)
                    sessionStorage.setItem('bizNo', myComp.bizNo);
                    sessionStorage.setItem('compNm', myComp.compNm);
                    this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO',myComp);
                }
            }
            // CASE - APP
            else{
                const bizNo = this.$getCompInfo().bizNo;
                this.$axios.$post(`/apiMngAo/customer/tax/${bizNo}/consulting`)
            }

            //window.open(outLink.solution+"loginkakao")
            window.open(outLink.solution+"new/selectType")
            this.$router.push(this.$goHome());
        },
        // 마이페이지
        _mixFnGoMypage(data){
            const partnerInfo = this.$getPartnerInfo();
            const backLink = partnerInfo ? partnerInfo.homeUrl : '/';

            if(data === null || data === undefined){
                this.$router.push(backLink)
            }else{
                if(!data.contents.compList || data.contents.compList.length==0){
                    const message = '메인화면에서 사업자번호 입력을 통해</br>다양한 진단서비스를 받아보세요.';
                    this.$fnAlert(message, {name:'확인', link:backLink});
                } else if(data.contents.compList.length>0){
                    this.$router.push('/mypage/inquiry')
                }
            }
        },
        // 랜딩
        _mixFnGoLandingHome(){
            const partnerInfo = this.$getPartnerInfo();
            if ( partnerInfo ) {
                this.$router.push(this.$goHome());
            } else {
                this.$fnAlert(
                    '랜딩페이지 정보가 없습니다.',
                    {
                        name:'홈으로',
                        link:'/'
                    }
                );
            }
        },
        // 지원사업
        _mixFnGoBizSupport(){
            const partnerInfo = this.$getPartnerInfo();
            if ( partnerInfo ) {
                // TODO - 현재는 메인비즈만 적용하나 - 차후에는 모든 랜딩이 ./tax/intro를 사용하도록 한다.
                if(partnerInfo.path && partnerInfo.path.indexOf("mainbiz")>=0){
                    this.$router.push(`${partnerInfo.path}support/?code=SR01_A`)
                }else{
                    this.$router.push("/services/support?code=SR01_A");
                }
            } else {
                this.$fnAlert(
                    '랜딩페이지 정보가 없습니다.',
                    {
                        name:'홈으로',
                        link:'/'
                    }
                );
            }
        },
        // 기업대출
        _mixFnGoLoan(){
            if('production'==process.env.NODE_ENV) {
                // this.$gtag.event('loan_click', {bizNo});
            }
            this.$router.push('/services/loan/research')
        },
        // 사내복지기금 - 결과 - 신청
        _mixFnGoWelfareResult(){
            const partnerInfo = this.$getPartnerInfo();
            let path = `${partnerInfo.path}welfare/fundResult`;
            if ( this.$route.query.cmd ) {
                path += '?cmd='+this.$route.query.cmd;
            }
            this.$router.push(path)
        },
        //
        _mixFnGoArticleOfIncorp(){
            const partnerInfo = this.$getPartnerInfo();
            let path = `${partnerInfo.path}articleOfIncorporation/form`;
            this.$router.push(path)
        }
    }
}
