<template>
  <div class="app sub" oncontextmenu="return false" ondragstart="return false" onselectstart="return false">
    <nuxt/>
    <transition name="fade">
      <Alert v-if="$store.state.ui.popupAlertData.state === true"/>
      <Basic v-if="$store.state.ui.popupBasicData.state === true"/>
      <Certification v-if="$store.state.ui.cert === true"/>
      <Loading v-if="$store.state.ui.loadingState === true"/>
    </transition>
  </div>
</template>

<script>
import Setting from "~/mixins/Setting";
import Alert from "~/components/common/Alert";
import Basic from "~/components/common/popup/Basic";
import Certification from "../components/common/popup/CertificationInfotech";
import Loading from "~/components/ui/popup/Loading";

export default {
  name: "appLayout",
  mixins: [Setting],
  components: {
    Alert,
    Basic,
    Certification,
    Loading,
  },
};
</script>
