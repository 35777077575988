const baseUrl = '/api/user';

const checkBizNo = (axios, bizNo) => {
  return axios.get(`/apiAo/customer/meta/${bizNo}`)
    .then( res => {
      return res.data
    });
}

const postPassword = (axios, form ) => {
  const params = form
  const url = `/apiAo/customer/password`;
  return axios.post(url, params)
    .then ( res => res.data)
    .catch( err => {
      console.error(err);
      return null;
    })
}

const fetchCustomerLookup = (axios, bizNo) => {
  return axios.get(`/apiAo/customer/meta/${bizNo}/detail`)
    .then(result => {
      return result.data
    })
}

const fetchCustomer = (axios, customerId) => {
  const url = `/apiAo/customer/${customerId}`
  return axios.get(url)
    .then( res => res.data);
}

const saveCustomerConsultant = async (axios, customerId, consultantCd) => {
  const url = `/apiAo/customer/${customerId}/consultant`
  return axios.post(url, {consultantCd})
    .then( res => res.data);
}

export default {
  checkBizNo,
  postPassword,
  fetchCustomer,
  saveCustomerConsultant,
  fetchCustomerLookup
}
