import { APP_AGENT_AOS, APP_AGENT_IOS  } from '../constants/app';
import * as partnerInfo from "../constants/partnerInfo";

let Setting = {
  data() {
    return {
      device: "pc",
      view: "mobile",
      headTitle:'경영관리',
    };
  },
  head(){
    return {
      title: this.headTitle
    }
  },
  beforeCreate() {
    const accessToken = this.$getAccessToken();
    if(!this.$isLogin()){
      if( accessToken ) {
        this.$store.dispatch('userStore/fetchMe')
      }
      else {
        const refreshToken = this.$getRefreshToken();
        if( refreshToken ) {
          // console.log('setting auth check - 리프레시 토큰 확인');
          this.$axios.get(`/apiAuth/token/refresh?refreshToken=${refreshToken}`)
              .then(res => {
                if(res.data.success){
                  // console.log('setting auth check - 토큰 업데이트');
                  this.$auth_token_update(res.data.contents)
                  this.$store.dispatch('userStore/fetchMe');
                }
                else{
                  // console.log('setting auth check - 업데이트 실패');
                  this.$clearToken();
                }
              })
        }
        else{
          // console.log('setting auth check - 억세스/리프레시 토큰 없음');
          this.$clearToken();
        }
      }
    } else {
      if(!this.$store.state.userStore.success){
        this.$store.dispatch('userStore/fetchMe');
      }
      const decode = this.$decodeToken(accessToken)
      this.$store.commit('servicesStore/UPDATE_USER', decode.user_no);
      this.$store.commit('servicesStore/UPDATE_USER_NM', decode.user_nm);
    }
  },
  created() {
    /**
     * Head Title 설정
     */
    const partnerInfoByRoutePath = this.$checkPartnerInfo_LandingPath(this.$route);
    let serviceName = null
    // Server Side
    if( process.server ) {
      if(partnerInfoByRoutePath && partnerInfoByRoutePath.serviceName){
        serviceName = partnerInfoByRoutePath.serviceName;
      }
    }
    // Client Side
    else {
      const partnerInfoInStorage = this.$getPartnerInfo();
      if(partnerInfoInStorage && partnerInfoInStorage.serviceName){
        serviceName = partnerInfoInStorage.serviceName;
      }
      else if(partnerInfoByRoutePath && partnerInfoByRoutePath.serviceName){
        serviceName = partnerInfoByRoutePath.serviceName;
      }
    }
    this.headTitle = "경영관리 | "+(serviceName ? serviceName : '다된다');
  },
  beforeMount() {
    this.settingDevice();
    window.addEventListener("resize",() => {
      this.settingDevice();
    },true);

  },
  mounted() {
    window.addEventListener("resize",() => {
      this.settingDevice();
    },true);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  },
  update(){
    this.settingDevice();
  },
  methods: {
    settingDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.device = "mobile";
        let userAgent = navigator.userAgent.toLowerCase();
        this.$store.commit('ui/UPDATE_STATE', {
          isAos: userAgent.search(APP_AGENT_AOS) > -1,
          isIos: userAgent.search(APP_AGENT_IOS) > -1
        });
      } else {
        this.device = "pc";
      }
      this.view = window.innerWidth >= 1280? "pc" : window.innerWidth >= 768 ? "tablet" : "mobile";
      if (this.$store.state.ui.view === "pc" && (this.view === "tablet" || this.view === "mobile")) {
        this.$store.dispatch("ui/setView", this.view);
        this.$store.dispatch("ui/setDevice", this.device);
        // $nuxt.$router.go();
        return;
      }
      if (this.$store.state.ui.view !== this.view) {
        this.$store.dispatch("ui/setView", this.view);
      }
      if (this.$store.state.ui.device !== this.device) {
        this.$store.dispatch("ui/setDevice", this.device);
      }
    },
  },
};

export default Setting;
