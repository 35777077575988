export default function ({ route, redirect }) {
  const fullPath = route.fullPath;

  if (fullPath === '/mainbiz') {
    redirect('/mainbiz/');
  } else if (fullPath === '/conia') {
    redirect('/conia/');
  } else if (fullPath === '/infoyou') {
    redirect('/infoyou/');
  } else if (fullPath === '/d3') {
    redirect('/d3/');
  } else if (fullPath === '/ktcb') {
    redirect('/ktcb/');
  } else if (fullPath === '/giscorea') {
    redirect('/giscorea/');
  } else if (fullPath === '/venturernd') {
    redirect('/venturernd/');
  }
}
