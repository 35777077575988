export default (context, inject) => {
    // 보유특허수
    // 예) 5 -> 5개 이상~10개미만
    inject('dataFormat_PatnCnt', (patnCnt)=>{
        const devidedBy = 10;
        if(patnCnt < devidedBy){
            if(patnCnt === 0)
                return '0개'
            else if (patnCnt < 5)
                return '5개 미만'
            else
                return '5개 이상~10개 미만'
        }
        // patnCnt >= devidedBy
        else{
            let quotient = Math.floor(patnCnt / devidedBy );
            return (devidedBy*quotient)+'개 이상~'+(devidedBy*(quotient+1))+'개 미만'
        }
    });

    //
    //
    inject('dataFormat_TechnicalAsset', (technicalAsset)=>{
        const devidedBy = 5;
        if(technicalAsset < devidedBy){
            const base = 1;
            return technicalAsset === 0 ? '0원'
                : technicalAsset < base ? `${base}억원 미만`
                    : `${base}억원 이상~${devidedBy}억원 미만`
        }
        // technicalAccest >= devidedBy
        else{
            let quotient = Math.floor(technicalAsset / devidedBy );
            return (devidedBy*quotient)+'억원 이상~'+(devidedBy*(quotient+1))+'억원 미만'
        }
    });

}
