import * as keys from '../constants/storageKey';
import * as partnerInfo from "../constants/partnerInfo";

const landingPathCheck = (partnerInfo, routePath) => {
  const infoKeys = Object.keys(partnerInfo);
  for(let i=0; i<infoKeys.length; i++){
    const key = infoKeys[i];
    const path = partnerInfo[key].path;
    if(routePath.indexOf(path)>=0){
      return partnerInfo[key];
    }
  }
  return false;
}

export default (context, inject) => {

  inject('checkPartnerInfo_LandingPath', (route) => {
    return landingPathCheck(partnerInfo, route.path);
  });

  /**
   * 파트너(랜딩) 정보 관리
   * 파트너사 컨설턴트 정보 관리
   */
  // savePartnerInfo - 파트너사 정보 등록 (랜딩페이지를 통해서)
  inject('savePartnerInfo', (route) => {
    if (process.client) {
      // GA(Partner Company)정보를 설정한다.
      // let found= landingPathCheck(partnerInfo, route.path);
      // if(found){
      //   localStorage.setItem(keys.partnerInfoKey, JSON.stringify(found));
      // }
      let found = false;
      const infoKeys = Object.keys(partnerInfo);
      for(let i=0; i<infoKeys.length; i++){
        const key = infoKeys[i];
        const path = partnerInfo[key].path;
        if(route.path.indexOf(path)>=0){
          localStorage.setItem(keys.partnerInfoKey, JSON.stringify(partnerInfo[key]));
          found=true;
          break;
        }
      }
      // Default
      if(!found){
        localStorage.setItem(keys.partnerInfoKey, JSON.stringify(partnerInfo.unicornranch));
      }
    }
  });

  // savePartnerInfo - 파트너사 정보 등록 로그인정보를 통해서
  inject('savePartnerInfoByLogin', (userCompanyId) => {
    if(process.client && userCompanyId){
      let found=false;
      const infoKeys = Object.keys(partnerInfo);
      for(let i=0; i<infoKeys.length; i++){
        const key = infoKeys[i];
        if(userCompanyId == partnerInfo[key].companyId){
          localStorage.setItem(keys.partnerInfoKey, JSON.stringify(partnerInfo[key]));
          found=true;
          break;
        }
      }
    }
  });

  // getPartnerInfo - 파트너사 정보 조회
  inject('getPartnerInfo', () => {
    if (process.client) {
      const stringfiedData = localStorage.getItem(keys.partnerInfoKey);
      return stringfiedData ? JSON.parse(stringfiedData) : null;
    }
    return null;
  });

  // clearPartnerInfo - 파트너사 정보 클리어
  inject('clearPartnerInfo', () => {
    if (process.client) {
      localStorage.removeItem(keys.partnerInfoKey);
      localStorage.removeItem(keys.ConsultantInfo);
    }
  });

  // 담당 컨설턴츠 정보 등록
  inject('partner_saveConsultantInfo', ({compId, compNm, orgId, orgNm, fcCd, fcId, fcNm})=>{
    if (process.client) {
      if(!compId || !compNm || !orgId || !orgNm || !fcCd || !fcId || !fcNm){
        return false;
      }
      const payload = {compId, compNm, orgId, orgNm, fcCd, fcId, fcNm};
      localStorage.setItem(keys.ConsultantInfo, JSON.stringify(payload));
      return true;
    }else{
      return false;
    }
  });

  inject('partner_getConsultantInfo', ()=>{
    const obj = localStorage.getItem(keys.ConsultantInfo);
    return (obj) ? JSON.parse(obj) : null;
  });

  inject('partner_clearConsultantInfo', ()=>{
    localStorage.removeItem(keys.ConsultantInfo);
  });


}
