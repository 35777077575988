export default {
    methods:{
        mixFnValidateBizNo(bizNo){
            return this.$fnValidateBizNo(bizNo);
        },
        async mixFnCheckAvailConsulting(bizNo){
            let error = false;
            let errorMessage=null;
            let isMyCompany = false;
            let isAvailConsulting = false;
            const bizMeta =  await this.$axios.get(`/apiMngAo/customers/${bizNo}/meta`).then(res=>res.data);
            if(bizMeta.success){
                const {assigned, uid:assignedUserId} = bizMeta.contents;
                if ( assigned ) {
                    //const meMeta = await userService.fetchMe(this.$axios)
                    const meMeta = await this.$store.dispatch('userStore/fetchMe');
                    // debugger
                    if ( meMeta && meMeta.success ){
                        const myId = meMeta.contents.id;
                        if ( assignedUserId === myId ){
                            isMyCompany = true;
                            isAvailConsulting = true;
                        } else {
                            isAvailConsulting = false;
                        }
                    }
                } else {
                    isAvailConsulting = true;
                }
            }else{
                error=true;
                errorMessage='서버 에러';
            }

            return {
                error,
                errorMessage,
                isMyCompany,
                isAvailConsulting
            }
        }
    }
}
