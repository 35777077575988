<template>
  <div class="popup p_biznum">
    <div class="p_inner">
      <button class="btn_close" @click="closePopup">닫기</button>
      <h3>사업자번호를 입력해주세요.</h3>
      <div class="bx_search">
        <input v-model="bizNo" type="text" class="ipt" placeholder="숫자만 입력해주세요."/>
      </div>
      <button type="button" class="btn" @click="fnSearch">조회</button>
    </div>
  </div>
</template>

<script>
import companyMix from "../../../mixins/companyMix";
import * as searchType from "../../../constants/landing";

export default {
  mixins:[companyMix],
  data(){
    return {
      bizNo:null,
      searchFunctionMap:{}
    }
  },
  beforeMount() {
    this.searchFunctionMap[searchType.BIZ_NO_SEARCH_TYPE_SERVICE] =  this.fnServiceSearch;
    this.searchFunctionMap[searchType.BIZ_NO_SEARCH_TYPE_PATENT_REPORT] = this.fnOpenPatentReport;
  },
  computed:{
    selectedService(){
      return this.$store.state.landingStore.selectedService;
    },
    selectedSearchType(){
      return this.$store.state.ui.popupBizNumSearchType;
    }
  },
  methods: {
    async fnSearch(){
      const func = this.searchFunctionMap[this.selectedSearchType];
      if(func){
        const bizNoValid = this.mixFnValidateBizNo(this.bizNo)
        if(!bizNoValid.success){
          this.$fnAlert(bizNoValid.message)
        }else{
          func();
          this.closePopup();
        }
      }
    },
    async fnOpenPatentReport(){
      window.open(`${process.env.tenbagger}/report/unlistedReport?bizNo=`+this.bizNo,'_blank');
    },
    async fnServiceSearch(){
      const result = await this.mixFnCheckAvailConsulting(this.bizNo);
      if ( !result.error ){
        // 신청가능 기업
        if ( result.isAvailConsulting ) {
          // 이미 조회한 기업
          if ( result.isMyCompany ) {
            // if(this.$route.name==='infoyou'){
            //   this.fnGoToService();
            // } else {
            //   this.fnUserConfirm();
            // }
            this.fnGoToService();
          }
          // 아직 조회하지 않은 기업
          else{
            this.fnGoToService();
          }
        }
        // 신청불가 기업
        else{
          this.$fnAlert('이미 조회가 완료된 기업입니다.<br/> 유선으로 문의해주시거나 다른 기업을 조회해주세요.');
        }
      } else {
        this.$fnAlert(result.errorMessage);
      }
    },
    fnGoToService(){
      this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO', {bizNo:this.bizNo})
      this.$store.dispatch('cmnCompanyStore/fetchCompanyInfo')
        .then( compInfoResult => {
          if (compInfoResult.success) {
            if (this.$isCorporation(this.bizNo)) {
              this.$router.push(`/services/confirm?selectedService=${this.selectedService}`);
            } else {
              this.$router.push(`/services/serviceSelect?selectedService=${this.selectedService}`)
            }
          } else if (compInfoResult.error.cd == '3001') {
            if (this.$isCorporation(this.bizNo)) {
              this.$router.push(`/services/confirm?selectedService=${this.selectedService}`);
            } else {
              this.$router.push(`/services/serviceSelect?selectedService=${this.selectedService}`)
            }
          }
        });
    },
    fnUserConfirm(){
      let data  = {
        state:true,
        type: 'main',
        msg: "이미 조회가 완료된 기업입니다.<br/>기존 결과를 보시려면 <strong>'마이페이지'</strong><br/>추가 진단을 받으시려면 <strong>'추가진단'</strong><br/>버튼을 클릭해주세요.",
        btns: [
          {
            name: '마이페이지',
            func: async ()=>{
              this.$fnClosePopup();
              this.$nextTick();
              this.$router.push('/mypage/inquiry');
            }
          },
          {
            name: '추가 진단',
            func: async ()=>{
              this.$fnClosePopup();
              this.$nextTick();
              this.fnGoToService();
              // this.$store.dispatch('commonAllOkStore/fetchCompanyInfo', this.bizNo);
              // this.$router.push('/services/serviceSelect')
            }
          }
        ]
      };
      this.$store.dispatch('ui/setPopupAlertData', data);
    },
    closePopup(){
      this.$store.dispatch('ui/closeBizNumPopup');
    },
  },
}

</script>
<style scoped v-if="true">
  @import "//s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/css/style.css?v=230808_1";
</style>
