import { generateQueryParameter } from '~/utils/HttpUtils';

function fetchLoadPrdc(axios, form){//사용
  return axios.get('/apiCmnAo/loan-prdc/match'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

function fetchLoanPrdcRcmd(axios, form){//사용
  return axios.get('/apiCmnAo/loan-prdc/rcmd'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

function fetchAnalyze(axios, form) {//사용
  return axios.get('/apiCmnAo/loan-prdc/patn'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

export default {
  fetchLoadPrdc,
  fetchLoanPrdcRcmd,
  fetchAnalyze
}
