
const fetchCodes = async (axios) => {
  const url = `/apiMngAo/common/codes`
  return axios.get(url)
    .then( res => res.data);
}

const fetchLoanCodes = (axios) => {
  return axios.get('/apiLoanAo/common/codes')
      .then(res=>res.data)
}

// 종목코드
const sectorList = (axios) => {
  const url = '/apiCmnAo/loan-prdc/code?pCode=IDS_00';
  return axios.get(url)
    .then( res => res.data);
}

const addr1List = (axios) => {
  /*
  const contents = [
    {code:'1100000000', codeName:'서울특별시'},
    {code:'2600000000', codeName:'부산광역시'},
    {code:'2700000000', codeName:'대구광역시'},
    {code:'2800000000', codeName:'인천광역시'},
    {code:'2900000000', codeName:'광주광역시'},
    {code:'3000000000', codeName:'대전광역시'},
    {code:'3100000000', codeName:'울산광역시'},
    {code:'3611000000', codeName:'세종특별자치시'},
    {code:'4100000000', codeName:'경기도'},
    {code:'4200000000', codeName:'강원도'},
    {code:'4300000000', codeName:'충청북도'},
    {code:'4400000000', codeName:'충청남도'},
    {code:'4500000000', codeName:'전라북도'},
    {code:'4600000000', codeName:'전라남도'},
    {code:'4700000000', codeName:'경상북도'},
    {code:'4800000000', codeName:'경상남도'},
    {code:'5000000000', codeName:'제주특별자치'},
  ]
  return {
    success:true,
    contents
  }
   */
  return axios.get('/apiCmnAo/loan-prdc/code?pCode=0000000000')
              .then( res => res.data );
}

// 군/구 주소
const addr2List = (axios, addr1Code) => {
  const url = `/apiCmnAo/loan-prdc/code?pCode=${addr1Code}`;
  return axios.get(url)
    .then( res => res.data);
}

export default {
  fetchCodes,
  fetchLoanCodes,
  sectorList,
  addr1List,
  addr2List
}
