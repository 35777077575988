<template>
  <div class="app sub" oncontextmenu="return false" ondragstart="return false" onselectstart="return false">
    <client-only>
      <div class="app ifs service" v-if="mixDisplayHead&&$route.path!=='/mypage/inquiry'">
        <Header/>
        <Menu class="nav_mobile"/>
      </div>
    </client-only>
  <!-- <div class="app sub"> -->
    <nuxt/>
    <transition name="cert">
      <Certification v-if="$store.state.ui.cert === true"/>
    </transition>
    <transition name="fade">
      <Alert style="z-index: 300;" v-if="$store.state.ui.popupAlertData.state === true"/>
      <Basic v-if="$store.state.ui.popupBasicData.state === true"/>
      <Loading v-if="$store.state.ui.loadingState === true"/>
      <CompanySelect v-if="$store.state.ui.popupCompanySelect === true" key="CompanySelect" />
    </transition>
  </div>
</template>

<script>
import Header from "../components/ifs/layouts/Header2";
import Setting from "~/mixins/Setting";
import optionalLayout from "../mixins/partner/optionalLayout";
import Alert from "~/components/common/Alert";
import Basic from "~/components/common/popup/Basic";
import Certification from "../components/common/popup/CertificationInfotech";
import Loading from "~/components/ui/popup/Loading";
import Menu from "../components/ifs/layouts/Menu";
import CompanySelect from "../components/common/popup/CompanySelect";

export default {
  name: "layout",
  mixins: [Setting, optionalLayout],
  components: {
    Alert,
    Basic,
    Certification,
    Loading,
    Header,
    Menu,
    CompanySelect
  },
};
</script>

<style>
  .service{
    min-height: 0vh !important;
    padding-top: 0px !important;
  }
  @media screen and (max-width: 770px){
    .service{
      margin-bottom:40px;
    }
  }
</style>
