export const state = () => ({
    tempUserForm:null,
    tempCompanyForm:null,
    tempCommonCompanyInfo:null,
});

export const mutations = {
    UPDATE_TEMP_FORM(state, {userForm, companyForm}){
        state.tempUserForm = userForm;
        state.tempCompanyForm = companyForm;
    },
    UPDATE_COMMON_COMPANY_INFO(state, payload){
        state.tempCommonCompanyInfo = payload
    },
  };
