<template>
  <div class="popup" v-if="popupCompanySelect">
    <div class="company_select">
      <h1>로그인 할 사업자를 선택해 주세요.</h1>
      <ul>
        <li v-for="(comp,idx) in myCompList" :key="idx">
          <a @click="select(comp.bizNo)">{{comp.compNm}}</a>
        </li>
      </ul>
      <button @click="mixFnAddComp">+ 사업자추가</button>
    </div>
  </div>
</template>

<script>
import serviceMix from "../../../mixins/partner/serviceMix";
import userService from "../../../service/userService";
export default {
  mixins:[serviceMix],
  computed: {
    popupCompanySelect(){
      return this.$store.state.ui.popupCompanySelect;
    },
    myCompList(){
      return this.$store.state.userStore.myCompList;
    },
    myComp() {
      return this.$store.getters['userStore/getMyComp'];
    },
    auth(){
      let loggedIn = this.$store.state.userStore.success;
      let hasMatchingData = (this.myComp && this.myComp.extData && this.myComp.extData.interests && this.myComp.extData.compTypes) ? true: false;
      return { loggedIn, hasMatchingData }
    },
  },
  methods:{
    select(bizNo){
      this.$auth_SaveBizNo(bizNo);
      //
      const myComp = this.$store.getters["userStore/getMyComp"];
      sessionStorage.setItem('bizNo', myComp.bizNo);
      sessionStorage.setItem('compNm', myComp.compNm);
      this.$store.commit('cmnCompanyStore/UPDATE_BIZNO', myComp.bizNo)
      this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO',myComp);
      //
      const popupCompanyFunc = this.$store.state.ui.popupCompanyFunc;
      if ( popupCompanyFunc ) {
        popupCompanyFunc();
        this.closePopup();
      }
      else if( this.$route.path==='/services/support' ){
        if(!this.auth.hasMatchingData){
          this.$router.push('/services/contractBizInfo?selectedService=bizSupport&mode=addBizInfo');
        }
      }
      this.closePopup();
    },
    closePopup(){
      this.$store.commit('ui/UPDATE_STATE', {
        popupCompanySelect:false,
        popupCompanyFunc:null,
      })
    }
  }
}
</script>

<style>
.popup .company_select{
  width: 486px;
  /*height: 282px;*/
  background-color: white;
  border: 1px solid #DDDDDD;
}
.company_select h1{
  padding-top: 40px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  color: #262626;
}
.company_select ul{
  padding:19px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: #3C3C3C;
}
.company_select li{
  padding: 15px 0px;
}
.company_select li a{
  cursor: pointer;
}
.company_select li a:hover{
  color:#2E79EB;
}
.company_select button{
  border-top: 1px solid #DDDDDD;
  text-align: center;
  width: 100%;
  color: #2E79EB;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0px;

}
</style>
