import commonService from "../service/common/commonService";
import bizInfoService from "../service/commonAllOk/bizInfoService";
import customerService from "../service/allOkMng/customerService";

export const state = () => ({
  env:{},
  authUser: null,
  id:null,
  codeMap:{},
  codeMapForLoan:{},
  bizInterestCode:[],
  mngAuth:null,
  mngSelectedMyCompBizNo:null,
  mngMyCompList:null,
});

export const mutations = {
  UPDATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
  SET_ENV(state, payload){
    state.env = payload;
  },
  LOGIN(state, payload) {
    state.authUser = payload;
  },
  SET_ID(state, id){
    state.id = id;
  },
};

export const actions = {
  async nuxtServerInit({ dispatch, commit, $axios }, { req, }) {
    // ENV
    if (process.server) {
      // 경영관리 API 코드목록
      const codeResult = await commonService.fetchCodes(this.$axios);
      if(codeResult.success){
        commit('UPDATE', {codeMap: codeResult.contents.codeMap});
      }

      // 기업대출 API 코드
      const loanCodeResult = await commonService.fetchLoanCodes(this.$axios);
      if(loanCodeResult.success){
        commit('UPDATE', {codeMapForLoan: loanCodeResult.contents.codeMap});
      }

      // 기업형태코드
      const bizInterestCodeResult = await bizInfoService.fetchBizInfoCodes(this.$axios);
      if(bizInterestCodeResult.success){
        commit('UPDATE', {bizInterestCode: bizInterestCodeResult.contents});
      }

      commit('SET_ENV', {
        csTel: process.env.CS_TEL,
        csTime: process.env.CS_TIME,
        consultantAdminLink: process.env.CONSULTANT_ADMIN_LINK,
        allOkMngLink: process.env.ALL_OK_MNG_LINK,
        allOkAPPLink: process.env.ALL_OK_APP_LINK,
        BUPINSE_PDF_TYPE:process.env.BUPINSE_PDF_TYPE,
        MYPAGE_LABOR_USEABLE:process.env.MYPAGE_LABOR_USEABLE,
        LOAN_URL:process.env.LOAN_URL,
      })

      // 노무관리 접속을 위한 매니저 로그인 처리
      if ( req.session.mngInfo ) {
        //
        const compList = req.session.mngInfo.compList;
        if ( compList && compList.length>0 ) {
          const bizNo = compList ? compList[0].bizNo : null;
          commit('UPDATE', {
            mngAuth: true,
            mngSelectedMyCompBizNo: bizNo,
            mngMyCompList: compList
          });
        }
      }
    }
  },
  async MNG_LOGIN({commit}, payload) {
    const authResult = await customerService.postAuth(this.$axios, payload);
    if ( authResult.success && !authResult.contents.pwdChgReq ) {
      commit('UPDATE', {mngAuth: true});
    }
    return authResult;
  },
  async MNG_LOGOUT({commit}){
    const result = await customerService.postLogout(this.$axios);
    console.log(result);
    commit('UPDATE', {mngAuth: false});
  }
};


export const getters = {
  isLogin(state){
    return state.authUser && state.id ? true : false
  },
}
