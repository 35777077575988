const genDate = () => {
    const d = new Date();
    return `${d.getFullYear()}-${d.getMonth()}-${d.getDay()}`;
}

export default (context, inject) => {

    inject('popupCtrlP_stopThisSession', (popupKey) => {
        window.sessionStorage.setItem(popupKey, genDate());
    });

    inject('popupCtrlP_stopReq', (popupKey)=>{
        const stopReqDate = genDate();
        window.localStorage.setItem(popupKey, stopReqDate);
    });

    inject('popupCtrlP_didStopReq', (popupKey)=>{
        const stopReq = window.sessionStorage.getItem(popupKey);
        if ( stopReq ) {
            return true;
        }
        const stopReqDate = window.localStorage.getItem(popupKey);
        if ( stopReqDate ) {
            const thisDate = genDate();
            return ( thisDate == stopReqDate ) ? true : false
        }

        return false;
    });
}
