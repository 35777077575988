const securedPathList = [
  /\/services\/contactInfo/,
  /\/services\/employ\/cert/,
  /\/services\/employ\/chart/,
  /\/services\/employ\/employIntro/,
  /\/services\/employ\/research/,
  /\/services\/employ\/result/,
  /\/services\/employ\/result2/,
  /\/services\/insurance\/cert/,
  /\/services\/insurance\/complete/,
  /\/services\/insurance\/processing/,
  /\/services\/insurance\/insuranceIntro/,
  /\/services\/insurance\/result/,
  /\/services\/tax\/cert/,
  /\/services\/tax\/chart/,
  /\/services\/tax\/checkresult/,
  /\/services\/tax\/research/,
  /\/services\/loading/,
  /\/services\/serviceComplete/,
  /\/mypage\/inquiry/,
  /\/mypage\/insured/,
]

const loginPage = "/";

export default async function ({ route, redirect, $isLogin, $axios, $getAccessToken, $getRefreshToken, $auth_token_update, $clearToken }) {
  if(process.client){
    await tokenRefreshWhenExpired($axios, $getAccessToken, $getRefreshToken, $auth_token_update, $clearToken);
    if(!$isLogin()) {
      let isSecuredPath = securedPathList.find( securedPath => securedPath.test(route.path));
      if(isSecuredPath){
        // console.log('인증된 사용자 전용 페이지에 접속하려 한다.');
        const accessToken = $getAccessToken();
        if(!accessToken){
          // console.log('페이지에 접속할 수 있는 유효한 엑세스 토큰이 없다.');
          // console.log('로그인페이지로 리다이렉션한다.');
          redirect(loginPage);
        }
      }
    }
  }
}

const tokenRefreshWhenExpired = async ($axios, $getAccessToken, $getRefreshToken, $auth_token_update, $clearToken) => {
  const accessToken = $getAccessToken();
  if(!accessToken){
    const refreshToken = $getRefreshToken();
    if(refreshToken) {
      const res = await $axios.get(`/apiAuth/token/refresh?refreshToken=${refreshToken}`);
      if(res.data.success){
        $auth_token_update(res.data.contents);
      }else{
        $clearToken();
      }
    }else{
      // console.log('리프레시 토큰도 없다.');
      $clearToken();
    }
  }
}
